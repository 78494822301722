import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import Text_Regular_14 from "./../../atoms/Text_Regular_14";
import checkIsMobile from "../../../utils/checkIsMobile";
import Text_Bold_20 from "./../../atoms/Text_Bold_20";
import styles from "./Carousel_Know_Our_Team.module.css";
import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import { useLanguage } from "../../../utils/translations/LanguageContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  carousel: {
    display: "flex",
    overflow: "hidden",
    justifyContent: "center",
  },
  card: {
    margin: "30px",
    display: "flex",
    marginTop: "40px",
    width: "330px",
    height: "400px",
    transition: "transform 0.3s",
    flexDirection: "column",
    background: "#496061",
    borderRadius: "16px",
    cursor: "pointer",
  },
  navigation: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  arrow: {
    cursor: "pointer",
    fontSize: "36px",
    margin: "0 10px",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "30px",
  },
  socialIcons: {
    color: "#fff",
    marginTop: "30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  bulletContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  bullet: {
    width: 10,
    height: 10,
    borderRadius: "50%",
    backgroundColor: "#888",
    margin: "0 5px",
    cursor: "pointer",
  },
  selectedBullet: {
    backgroundColor: "var(--surface-primary)",
  },
  h2: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Maven Pro',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '40px',
    paddingBottom: "18px",
  },
  workerDescription: {
    justifyContent: "center",
    width: "80%",
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Maven Pro',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    margin: "auto",
    lineHeight: '22px',
    paddingBottom: "1rem"
  }
}));

const Carousel_Know_Our_Team = () => {
  const { selectedLanguage } = useLanguage();
  const items = [
    {
      title: selectedLanguage === "pt" ? "Diretora de Projetos" : "Project Director",
      content: "Renata Spaziante do Val",
      facebook: "https://www.facebook.com/bohocasas",
      instagram: "https://www.instagram.com/renata.bohocasas/",
      linkedin: "https://www.linkedin.com/in/renata-spaziante-do-val-06558645/",
      backgroundImage: "https://bohocasasstorage.blob.core.windows.net/boho-casas/Renata.jpg",
      description: selectedLanguage === "pt" ? "É a nossa Diretora de Projetos, ela cuida de tudo que envolve os projetos de arquitetura e interiores, desde o atendimento ao cliente, desenvolvimento e coordenação das equipas envolvidas nos projetos, compatibilizações, acompanhamento dos licenciamentos e entregas finais para o cliente e para as equipas de obras." :
        "It is our project director, she takes care of everything that involves architectural and interiors projects, from customer service, development and coordination of the teams involved in the projects, compatibilization, licensing and final delivery for the customer and for the customer and Works teams. "
    },
    {
      title: selectedLanguage === "pt" ? "Diretor Executivo" : "Executive Director",
      content: "Guilherme Trindade do Val",
      facebook: "https://www.facebook.com/guilherme.trindadedoval",
      instagram: "https://www.instagram.com/guilhermetrindadedoval/",
      linkedin: "https://www.linkedin.com/in/guilherme-trindade-do-val-7b2a0a60/",
      backgroundImage: "https://bohocasasstorage.blob.core.windows.net/boho-casas/Claudio.jpg",
      description: selectedLanguage === "pt" ? "É o nosso Diretor Executivo, que após receber os projetos, elabora os orçamentos das obras, coordenando as equipas envolvidas nesse processo. É responsável pelas compras de materiais, contratações de serviços e terceiros, controlando todo o sistema para que as obras aconteçam no tempo certo, dentro do custo previsto e com a qualidade esperada." : "It is our Executive Director, who after receiving the projects, prepares the budgets for the works, coordinating the teams involved in this process. He is responsible for purchasing materials, contracting services and third parties, controlling the entire system so that the works happen on time right, within the expected cost and with the expected quality."
    },
    {
      title: selectedLanguage === "pt" ? "Diretor de Operações" : "Operations Director",
      content: "Claudio Polastri",
      facebook: "https://www.facebook.com/claudio.polastri.9",
      instagram: "https://www.instagram.com/polastriclaudio/",
      linkedin: "https://www.linkedin.com/in/claudio-polastri-8a0bba219/?originalSubdomain=pt",
      backgroundImage: "https://bohocasasstorage.blob.core.windows.net/boho-casas/Guilherme.jpg",
      description: selectedLanguage === "pt" ? "É o nosso Diretor de Operações, ele cuida das obras em si, acompanhando a execução dos trabalhos com as equipas de campo, dando suporte a tudo o que precisam para que não haja atrasos, falta de material e outras complicações. É a ponte entre o dono da obra e a nossa equipa do escritório." : "He is our Operations Director, he takes care of the works themselves, monitoring the execution of the work with the field teams, providing support with everything they need so that there are no delays, lack of material and other complications. He is the bridge between the developer and our office team."
    },
  ];
  const classes = useStyles();
  const totalItems = items.length;
  const [activeIndex, setActiveIndex] = useState(1);
  const refFlipping = useRef(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(1);

  const handleNextClick = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % totalItems);
  };

  const handlePrevClick = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? totalItems - 1 : prevIndex - 1
    );
  };

  const handleBulletClick = (index) => {
    setActiveIndex(index);
  };

  const handleCardClick = (index) => {
    setActiveIndex(index);
    refFlipping.current.moveTo(index);
  };

  const renderSocialIcons = (item) => {
    return (
      <>
        {!checkIsMobile() ? (
          <div className={classes.socialIcons} style={{ width: 25 }}>
            <a href={item.facebook} target="_blank" rel="noopener noreferrer">
              <FacebookIcon style={{ color: "#fff", marginBottom: "21px" }} />
            </a>
            <a href={item.instagram} target="_blank" rel="noopener noreferrer">
              <InstagramIcon style={{ color: "#fff", marginBottom: "21px" }} />
            </a>
            <a href={item.linkedin} target="_blank" rel="noopener noreferrer">
              <LinkedInIcon style={{ color: "#fff" }} />
            </a>
          </div>
        ) : (
          <div className={classes.socialIcons}>
            <a href={item.facebook} target="_blank" rel="noopener noreferrer">
              <FacebookIcon style={{ color: "#fff", marginBottom: "21px" }} />
            </a>
            <a href={item.instagram} target="_blank" rel="noopener noreferrer">
              <InstagramIcon style={{ color: "#fff", marginBottom: "21px" }} />
            </a>
            <a href={item.linkedin} target="_blank" rel="noopener noreferrer">
              <LinkedInIcon style={{ color: "#fff" }} />
            </a>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {!checkIsMobile() ? (
        <div className={classes.navigation}>
          <div className={classes.root}>
            <div
              id="sliderProjectsResidential"
              style={{
                textAlign: "start",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Flicking
                ref={refFlipping}
                defaultIndex={1}
                align="center"
                style={{ width: "80vw" }}
                circular={true}
                interruptable={true}
                onChanged={(e) => {
                  setCurrentImageIndex(e.currentTarget.index);
                }}
              >
                {items.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      width: "20vw",
                      height: "25vw",
                      display: "flex",
                      backgroundImage: `url(${item.backgroundImage})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      margin: "2rem",
                      borderRadius: "24px",
                      cursor: "pointer",
                    }}
                    onClick={() => refFlipping.current.moveTo(index)}
                  >
                    <div style={{ padding: "1rem", display: "flex", flexDirection: "column" }}>
                      <div style={{ position: "relative", top: "50px" }}>
                        {renderSocialIcons(item)}
                      </div>
                      <div style={{ marginTop: "auto" }}>
                        <Text_Regular_14
                          value={item.title}
                          isSelected={true}
                        />
                        <br />
                        <Text_Bold_20 value={item.content} isSelected={false} />
                      </div>
                    </div>
                  </div>
                ))}
              </Flicking>
            </div>
            <div className={classes.bulletContainer}>
              {items.map((_, index) => (
                <div
                  key={index}
                  className={`${classes.bullet} ${currentImageIndex === index ? classes.selectedBullet : ""
                    }`}
                  onClick={() => refFlipping.current.moveTo(index)}
                ></div>
              ))}
            </div>
            <div>
              {items
                .filter((_, index) => currentImageIndex === index)
                .map((worker) => (
                  <div key={worker.content} className={classes.workerDescription}>
                    <h2 className={classes.h2}>{worker.content}</h2>
                    <div>{worker.description}</div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      ) : (
        <div
          id="sliderProjectsResidential"
          style={{
            textAlign: "start",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Flicking
            align={"center"}
            autoResize
            defaultIndex={1}
            ref={refFlipping}
            style={{ width: "100vw" }}
            circular={true}
            interruptable={true}
            onChanged={(e) => {
              setCurrentImageIndex(e.currentTarget.index);
            }}
          >
            {items.map((item, index) => (
              <div
                style={{
                  height: 350,
                  width: "70vw",
                  height: "85vw",
                  display: "flex",
                  backgroundImage: `url(${item.backgroundImage})`,
                }}
                className={styles.cardMobile}
              >
                <div className={styles.cardContentMobile}>
                  <div className={styles.cardIconsMobile}>
                    {renderSocialIcons(item)}
                  </div>
                  <div className={styles.cardTextMobile}>
                    <Text_Regular_14 value={item.title} isSelected={true} />
                    <Text_Bold_20 value={item.content} isSelected={false} />
                  </div>
                </div>
              </div>
            ))}
          </Flicking>
          <div
            style={{
              margin: "1rem auto",
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              zIndex: 1,
              gap: "10vw",
            }}
          >
            {items.map((_, index) => (
              <div
                key={index}
                className={
                  currentImageIndex === index
                    ? styles.buttonSliderMobile
                    : styles.buttonSliderMobile2
                }
                onClick={() => refFlipping.current.moveTo(index)}
              >
                <div className={styles.buttonSliderMobile2}></div>
              </div>
            ))}
          </div>
          <div>
            {items.filter((_, index) => currentImageIndex === index).map((worker) => (
              <div key={worker.content} className={styles.workerDescription}>
                <span style={{fontSize:24}}>{worker.content}</span>
                <span style={{fontSize:14}}>{worker.description}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Carousel_Know_Our_Team;
