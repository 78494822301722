import React, { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import { Checkbox } from 'antd';
import { useLanguage } from "./translations/LanguageContext";
export default function CustomizeCookies({checkboxes, setCheckboxes}) {
    const { selectedLanguage } = useLanguage();
    

    const handleCheckboxChange = (key, value) => {
        setCheckboxes({
            ...checkboxes,
            [key]: value,
        });
        console.log(`Checkbox '${key}' is now ${value ? 'checked' : 'unchecked'}`);
    };

    const items = [
        {
            key: '1',
            label: (
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <h4>{selectedLanguage === 'pt' ? "Tecnicamente Necessários" : "Technically Necessary"}</h4>
                    <p><i>{selectedLanguage === 'pt' ? "Sempre Ativos" : "Always Active"}</i></p>
                </div>
            ),
            children: (
                <p>
                    {selectedLanguage === 'pt' ? "Trata-se de cookies e métodos semelhantes sem os quais não pode utilizar os nossos serviços (por exemplo, para apresentar corretamente o nosso sitio Web/funções solicitadas por si, para guardar o seu registo na área de início de sessão, etc.)." : "These are similar cookies and methods without which you cannot use our services (for example, to correctly present our web site/functions requested by you, to store your registration in the session start area, etc.) . "}
                </p>
            ),
        },
        {
            key: '2',
            label: (
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <h4>{selectedLanguage === 'pt' ? "Conforto" : "Comfort"}</h4>
                    <Checkbox
                        checked={checkboxes.comfort}
                        onChange={(e) => handleCheckboxChange('comfort', e.target.checked)}
                    ></Checkbox>
                </div>
            ),
            children: (
                <p>
                    {selectedLanguage === 'pt' ? "Estas técnicas permitem-nos ter em conta as suas preferências reais ou sentidas para uma utilização confortável dos nossos sítios Web. Por exemplo, podemos utilizar as suas preferências para apresentar os nossos sítios Web numa lingua que lhe convenha. Também nos ajuda a evitar mostrar-lhe produtos que podem não estar disponíveis na sua região." : "These techniques allow us to take into account your real or felt preferences for a comfortable use of our web sites. For example, we can use your preferences to present our web sites in a language that agrees to you. It also helps us avoid showing you products that may not be available in your region."}
                </p>
            ),
        },
        {
            key: '3',
            label: (
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <h4>{selectedLanguage === 'pt' ? "Estatísticos" : "Statistics"}</h4>
                    <Checkbox
                        checked={checkboxes.statistics}
                        onChange={(e) => handleCheckboxChange('statistics', e.target.checked)}
                    ></Checkbox>
                </div>
            ),
            children: (
                <p>
                    {selectedLanguage === 'pt' ? "Estas técnicas permitem-nos compilar estatísticas pseudónimas sobre a utilização dos nossos serviços com o objetivo de os adaptar às nossas necessidades. Isto permite-nos, por exemplo, determinar como podemos adaptar ainda melhor os nossos sítios Web aos hábitos dos utilizadores." : "These techniques allow us to compile pseudonymous statistics on the use of our services to adapt them to our needs. This allows us, for example, to determine how we can further adapt our web sites to users' habits."}
                </p>
            ),
        },
        {
            key: '4',
            label: (
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <h4>{selectedLanguage === 'pt' ? "Marketing" : "Marketing"}</h4>
                    <Checkbox
                        checked={checkboxes.marketing}
                        onChange={(e) => handleCheckboxChange('marketing', e.target.checked)}
                    ></Checkbox>
                </div>
            ),
            children: (
                <p>
                    {selectedLanguage === 'pt' ? "Isto permite-nos, a nós e a outras partes responsáveis, mostrar-lhe conteúdos publicitários adequados com base na análise do seu comportamento de utilização pseudónimo. O seu comportamento de utilização também pode ser rastreado em diferentes sítios web, navegadores ou dispositivos finais através de um ID de utilizador (identificador único)." : "This allows us and other responsible parties to show you suitable advertising content based on the analysis of your pseudonymous usage behavior. Your usage behavior may also be tracked on different websites, browsers, or end devices through a user ID (unique identifier)."}
                </p>
            ),
        },
    ];

    return (
        <Collapse accordion items={items} />
    );
}
