import React from "react";
import styles from "./Text_Bold_20.module.css";

export default function Text_Bold_20({ value, isSelected, textColor }) {
	return (
		<>
			<p
				className={styles.text20}
				style={{
					opacity: isSelected ? 0.5 : 1,
					color: textColor ? textColor : "white",
				}}
			>
				{value}
			</p>
		</>
	);
}
