import React, { createContext, useContext, useState } from 'react';
import Cookies from 'js-cookie';

const LanguageContext = createContext();

export function useLanguage() {
    return useContext(LanguageContext);
}

export function LanguageProvider({ children }) {
    const [selectedLanguage, setSelectedLanguage] = useState(Cookies.get('languagePreference') || 'pt');

    return (
        <LanguageContext.Provider value={{ selectedLanguage, setSelectedLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
}
