import React from 'react'
import ProjectsLSFPageTemplate from '../templates/ProjectsLSFPageTemplate'

export default function ProjectsLSFPage() {
    return (
        <div>
            <ProjectsLSFPageTemplate />
        </div>
    )
}
