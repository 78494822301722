import React, { useEffect, useState } from "react";
import "./calculator.css";
import { Button, ConfigProvider, Modal, Select, Slider } from "antd";
import ButtonBasicGreenDefault from "../../atoms/ButtonBasicGreenDefault";
import closeIcon from "./close-circle.svg";
import houseLsfImage from "./LSF Image.svg";
import circleStep from "./circleStep.svg";
import checkbox from "./checkbox.svg";
import { useTranslation } from "react-i18next";
import LanguageToggle from "../../../utils/translations/LanguageToggle";
import { Radio } from "antd";
import checkIsMobile from "../../../utils/checkIsMobile";
import ButtonWhatsAppGreen from "../../atoms/BTNSendMessageGreen";
import RangeForCalculator from "../../molecules/RangeForCalculator/rangeForCalculator";
import { useLanguage } from "../../../utils/translations/LanguageContext";

export default function CalculatorFooter({ type }) {
  const { selectedLanguage, setSelectedLanguage } = useLanguage();
  const [isMobile, setIsMobile] = useState(checkIsMobile());
  const [opened, setOpened] = useState(false);
  const [typographic, setTypographic] = useState("");
  const [dataReceivedFromRange, setDataReceivedFromRange] = useState(0);
  const [dataReceivedFromRadioWC, setDataReceivedFromRadioWC] = useState(0);
  const [dataReceivedFromRadioKitchen, setDataReceivedFromRadioKitchen] =
    useState(0);
  const [dataReceivedFromRadioCloset, setDataReceivedFromRadioCloset] =
    useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const { t } = useTranslation();
  useEffect(() => {
    if (typographic) {
      switch (typographic) {
        case t("modal") + " T0":
          setTotalValue(
            dataReceivedFromRange * 1560 +
            dataReceivedFromRadioWC * 900 +
            dataReceivedFromRadioKitchen * 5000 +
            dataReceivedFromRadioCloset * 900
          );
          break;
        case t("modal") + " T1":
          setTotalValue(
            dataReceivedFromRange * 1540 +
            dataReceivedFromRadioWC * 900 +
            dataReceivedFromRadioKitchen * 5000 +
            dataReceivedFromRadioCloset * 900
          );
          break;
        case t("modal") + " T2":
          setTotalValue(
            dataReceivedFromRange * 1520 +
            dataReceivedFromRadioWC * 900 +
            dataReceivedFromRadioKitchen * 5000 +
            dataReceivedFromRadioCloset * 900
          );
          break;
        case t("modal") + " T3":
          setTotalValue(
            dataReceivedFromRange * 1500 +
            dataReceivedFromRadioWC * 900 +
            dataReceivedFromRadioKitchen * 5000 +
            dataReceivedFromRadioCloset * 900
          );
          break;
        case t("modal") + " T4":
          setTotalValue(
            dataReceivedFromRange * 1490 +
            dataReceivedFromRadioWC * 900 +
            dataReceivedFromRadioKitchen * 5000 +
            dataReceivedFromRadioCloset * 900
          );
          break;
        case t("modal") + " T5":
          setTotalValue(
            dataReceivedFromRange * 1480 +
            dataReceivedFromRadioWC * 900 +
            dataReceivedFromRadioKitchen * 5000 +
            dataReceivedFromRadioCloset * 900
          );
          break;
        case t("modal") + " T6":
          setTotalValue(
            dataReceivedFromRange * 1470 +
            dataReceivedFromRadioWC * 900 +
            dataReceivedFromRadioKitchen * 5000 +
            dataReceivedFromRadioCloset * 900
          );
          break;
        default:
          break;
      }
    }
  }, [
    typographic,
    dataReceivedFromRange,
    dataReceivedFromRadioWC,
    dataReceivedFromRadioKitchen,
    dataReceivedFromRadioCloset,
  ]);

  const handleChangeTypographic = (value) => {
    setTypographic(value);
    console.log(typographic);
  };
  const handleDataReceivedFromRange = (data) => {
    setDataReceivedFromRange(data);
  };

  const handleChangeWC = (data) => {
    setDataReceivedFromRadioWC(data.target.value);
  };
  const handleChangeKitchen = (data) => {
    setDataReceivedFromRadioKitchen(data.target.value);
  };
  const handleChangeCloset = (data) => {
    setDataReceivedFromRadioCloset(data.target.value);
  };

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              titleFontSize: 28,
            },
          },
        }}
      >
        <Modal
          open={opened}
          width="90vw"
          onCancel={() => {
            setOpened(!opened);
            // window.location.reload();
          }}
          title={
            <>
              <div>{isMobile && <img src={houseLsfImage} />}</div>
              <div style={{ fontSize: "24px" }}>{t("Calculator Title")}</div>
            </>
          }
          footer={(_, { OkBtn, CancelBtn }) => (
            <div>
              <LanguageToggle />
            </div>
          )}
          closeIcon={<img src={closeIcon} />}
        >
          <h2 style={{ fontSize: "14px" }}>{t("Calculator Subtitle")}</h2>
          <br />
          <p style={{ fontSize: "14px" }}>{t("Caluclator Description")}</p>
          <div
            className={
              !isMobile ? "CalculatorContainer" : "CalculatorContainerMobile"
            }
          >
            <div
              className={
                !isMobile
                  ? "CalculatorStepsContainer"
                  : "CalculatorStepsContainerMobile"
              }
            >
              <div className={!isMobile ? "step" : "stepMobile"}>
                <div className={!isMobile ? "stepNumber" : "stepNumberMobile"}>
                  {isMobile && <img src={circleStep} />}
                  <div>01</div>
                </div>
                <div>
                  <div>
                    <h3>{t("Calculator Step 1 Title")}</h3>
                    <p>{t("Calculator Step 1 Description")}</p>
                  </div>
                  <Select
                    defaultValue={t("select_your_model")}
                    style={{
                      width: "100%",
                      height: "45px",
                    }}
                    onChange={handleChangeTypographic}
                    options={[
                      {
                        value: t("modal") + " T0",
                        label: t("model") + " T0 (Studio)",
                      },
                      {
                        value: t("modal") + " T1",
                        label: t("model") + " T1 " + t("rooms"),
                      },
                      {
                        value: t("modal") + " T2",
                        label: t("model") + " T2 " + t("rooms"),
                      },
                      {
                        value: t("modal") + " T3",
                        label: t("model") + " T3 " + t("rooms"),
                      },
                      {
                        value: t("modal") + " T4",
                        label: t("model") + " T4 " + t("rooms"),
                      },
                      {
                        value: t("modal") + "T5",
                        label: t("model") + " T5 " + t("rooms"),
                      },
                      {
                        value: t("modal") + " T6",
                        label: t("model") + " T6 " + t("rooms"),
                      },
                    ]}
                  />
                </div>
              </div>
              <div className={!isMobile ? "step" : "stepMobile"}>
                <div className={!isMobile ? "stepNumber" : "stepNumberMobile"}>
                  {isMobile && <img src={circleStep} />}
                  <div>02</div>
                </div>
                <div>
                  <div>
                    <h3>{t("Calculator Step 2 Title")}</h3>
                    <p>{t("Calculator Step 2 Description")}</p>
                  </div>
                  <br></br>
                  <strong>
                    {typographic ? typographic : t("No selected model")} - {dataReceivedFromRange}m²
                  </strong>
                  <RangeForCalculator
                    typographicValue={typographic}
                    onDataReceived={handleDataReceivedFromRange}
                  />
                </div>
              </div>
              <div className={!isMobile ? "step" : "stepMobile"}>
                <div className={!isMobile ? "stepNumber" : "stepNumberMobile"}>
                  {isMobile && <img src={circleStep} />}
                  <div>03</div>
                </div>
                <div>
                  <div>
                    <h3>{t("Calculator Step 3 Title")}</h3>
                    <p>{t("Calculator Step 3 Description")}</p>
                  </div>
                  <div></div>
                  <ConfigProvider
                    theme={{
                      components: {
                        Radio: {
                          buttonSolidCheckedBg: "#648586",
                          buttonSolidCheckedHoverBg: "#648586",
                          buttonSolidCheckedActiveBg: "#648586",
                          buttonColor: "#648586",
                        },
                      },
                      token: {
                        colorPrimaryHover: "#648586",
                        colorPrimaryActive: "#648586",
                        colorPrimaryBorder: "#648586",
                        colorPrimary: "#648586",
                      },
                    }}
                  >
                    <Radio.Group
                      defaultValue={0}
                      buttonStyle="solid"
                      style={{
                        marginTop: 16,
                        display: "flex",
                        gap: "1rem",
                        border: "none",
                        overflow: "auto",
                      }}
                      onChange={handleChangeWC}
                    >
                      <Radio.Button
                        value={0}
                        className="buttonradiocalculator"
                        style={{ borderRadius: "24px" }}
                      >
                        {t("No aditionals")}
                      </Radio.Button>
                      <Radio.Button value={1} className="buttonradiocalculator">
                        {t("01 Additional")}
                      </Radio.Button>
                      <Radio.Button value={2} className="buttonradiocalculator">
                        {t("02 Additional")}
                      </Radio.Button>
                      <Radio.Button
                        value={3}
                        className="buttonradiocalculator"
                        style={{ borderRadius: "24px" }}
                      >
                        {t("03 Additional")}
                      </Radio.Button>
                    </Radio.Group>
                  </ConfigProvider>
                </div>
              </div>
              <div className={!isMobile ? "step" : "stepMobile"}>
                <div className={!isMobile ? "stepNumber" : "stepNumberMobile"}>
                  {isMobile && <img src={circleStep} />}
                  <div>04</div>
                </div>
                <div>
                  <div>
                    <h3>{t("Calculator Step 4 Title")}</h3>
                    <p>{t("Calculator Step 4 Description")}</p>
                  </div>
                  <ConfigProvider
                    theme={{
                      components: {
                        Radio: {
                          buttonSolidCheckedBg: "#648586",
                          buttonSolidCheckedHoverBg: "#648586",
                          buttonSolidCheckedActiveBg: "#648586",
                          buttonColor: "#648586",
                        },
                      },
                      token: {
                        colorPrimaryHover: "#648586",
                        colorPrimaryActive: "#648586",
                        colorPrimaryBorder: "#648586",
                        colorPrimary: "#648586",
                      },
                    }}
                  >
                    <Radio.Group
                      defaultValue={0}
                      buttonStyle="solid"
                      style={{
                        marginTop: 16,
                        display: "flex",
                        gap: "1rem",
                        border: "none",
                      }}
                      className="radioKitchen"
                      onChange={handleChangeKitchen}
                    >
                      <Radio.Button
                        value={0}
                        className="buttonradiocalculator"
                        style={{ borderRadius: "24px" }}
                      >
                        {t("kitchen without amarios")}
                      </Radio.Button>
                      <Radio.Button
                        value={1}
                        className="buttonradiocalculator"
                        style={{ borderRadius: "24px" }}
                      >
                        {t("kitchen with cabinets")}
                      </Radio.Button>
                    </Radio.Group>
                  </ConfigProvider>
                </div>
              </div>
              <div className={!isMobile ? "step" : "stepMobile"}>
                <div className={!isMobile ? "stepNumber" : "stepNumberMobile"}>
                  {isMobile && <img src={circleStep} />}
                  <div>05</div>
                </div>
                <div>
                  <div>
                    <h3>{t("Calculator Step 5 Title")}</h3>
                    <p>{t("Calculator Step 5 Description")}</p>
                  </div>
                  <ConfigProvider
                    theme={{
                      components: {
                        Radio: {
                          buttonSolidCheckedBg: "#648586",
                          buttonSolidCheckedHoverBg: "#648586",
                          buttonSolidCheckedActiveBg: "#648586",
                          buttonColor: "#648586",
                        },
                      },
                      token: {
                        colorPrimaryHover: "#648586",
                        colorPrimaryActive: "#648586",
                        colorPrimaryBorder: "#648586",
                        colorPrimary: "#648586",
                      },
                    }}
                  >
                    <Radio.Group
                      defaultValue={0}
                      buttonStyle="solid"
                      style={{
                        marginTop: 16,
                        display: "flex",
                        gap: "1rem",
                        border: "none",
                        overflow: "auto",
                      }}
                      className="radioUn"
                      onChange={handleChangeCloset}
                    >
                      <Radio.Button
                        value={1}
                        className="buttonradiocalculator"
                        style={{ borderRadius: "24px" }}
                      >
                        01 un
                      </Radio.Button>
                      <Radio.Button value={2} className="buttonradiocalculator">
                        02 un
                      </Radio.Button>
                      <Radio.Button value={3} className="buttonradiocalculator">
                        03 un
                      </Radio.Button>
                      <Radio.Button value={4} className="buttonradiocalculator">
                        04 un
                      </Radio.Button>
                      <Radio.Button value={5} className="buttonradiocalculator">
                        05 un
                      </Radio.Button>
                      <Radio.Button
                        value={6}
                        className="buttonradiocalculator"
                        style={{ borderRadius: "24px" }}
                      >
                        06 un
                      </Radio.Button>
                    </Radio.Group>
                  </ConfigProvider>
                </div>
              </div>
            </div>
            <div
              className={
                !isMobile
                  ? "CalculatorResultsContainer"
                  : "CalculatorResultsContainerMobile"
              }
            >
              <div>{!isMobile && <img src={houseLsfImage} />}</div>
              <br></br>
              <div>
                <strong>{t("Estimated total value")}: </strong>
              </div>
              <div className="calculatorResult">
                {totalValue.toLocaleString()}€
              </div>
              <h4>{t("About Your Budget Title")}</h4>
              <div className="aboutYourBudgetContainer">
                <div className="aboutItem">
                  <div>
                    <img src={checkbox} />
                  </div>
                  <div>{t("About Your Budget Description 1")}</div>
                </div>
                <div className="aboutItem">
                  <div>
                    <img src={checkbox} />
                  </div>
                  <div>{t("About Your Budget Description 2")}</div>
                </div>
                <div className="aboutItem">
                  <div>
                    <img src={checkbox} />
                  </div>
                  <div>{t("About Your Budget Description 3")}</div>
                </div>
              </div>
              <div className="containerDreams">
                <div className="boxDreams">
                  <p>{t("Ready to build your dream")}</p>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </ConfigProvider>
      <div
        style={
          type
            ? {
              display: "flex",
              flexDirection: "column",
              cursor: "pointer",
              marginBottom: "0.5rem",
            }
            : {
              width: "100vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "2rem",
            }
        }
      >
        <div
          style={{
            color: "white",
            textDecoration: "none",
            cursor: "pointer",
          }}
          onClick={() => {
            setOpened(true);
          }}
        >
          {t("Simulate Budget Useful Links")}
        </div>
      </div>
    </>
  );
}
