import GoogleMap from "../../components/organisms/MapContainer";
import Card_Recent_Project from "../../components/organisms/Card_Recent_Project";
import Our_Services_Section from "../../components/organisms/Our_Services_Section";
import Section_Contact_Us from "../../components/organisms/SectionContactUs";
import Section_Commercial_Projects_And_Franchises from "../../components/organisms/Section_Commercial_Projects_And_Franchises";
import Section_Commercial__Projects_And_Constructions_In_LSF from "../../components/organisms/Section_Commercial__Projects_And_Constructions_In_LSF";
import Section_Home from "../../components/organisms/Section_Home";
import Section_Our_Story from "../../components/organisms/Section_Our_Story";
import Section_Remodeling from "../../components/organisms/Section_Remodeling";
import Footer from "../../components/organisms/Footer";
import Section_Projects_Residentials_Architeture from "../../components/organisms/Section_Projects_Residentials";
import Section_Projects_Residentials_Interior from "../../components/organisms/Section_Projects_Residentials_Interior";
import { useEffect } from "react";
export default function HomeMobileTemplate() {
  
  useEffect(() => {
    const sectionId = window.location.hash.substring(1);
    console.log(sectionId);
	if (sectionId) {
		const targetSection = document.getElementById(sectionId);
		if (targetSection) {
		  targetSection.scrollIntoView({ behavior: 'smooth' });
		}
	  }
  }, []);
  return (
    <div style={{ overflow: "hidden" }}>
      <Section_Home />
      <Our_Services_Section id="second-section" />
      <Section_Projects_Residentials_Architeture />
      <Section_Projects_Residentials_Interior />
      <Section_Commercial_Projects_And_Franchises />
      <Section_Commercial__Projects_And_Constructions_In_LSF />
      <Section_Remodeling />
      <Section_Our_Story />
      <Section_Contact_Us />
      <div style={{ height: "100vw", width: "100vw" }}>
        <GoogleMap />
      </div>
      <Footer />
    </div>
  );
}
