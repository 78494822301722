import React, { useEffect, useState } from "react";
import { Modal, message } from "antd";
import './CookiesWeb.css'
import { useLanguage } from "./translations/LanguageContext";
import CustumizeCookies from "./CustumizeCookies";
import { useTranslation } from "react-i18next";
import PrivacyPolicyModal from "../components/molecules/FormSendMessage/PrivacyPolicyModal";
import Cookies from "js-cookie";
const CookiesWeb = () => {
    const { t } = useTranslation();
    const { selectedLanguage } = useLanguage();
    const [privacyModalVisible, setPrivacyModalVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [checkboxes, setCheckboxes] = useState({
        comfort: false,
        statistics: false,
        marketing: false,
    });
    const showModal = () => {
        setIsModalVisible(true);
    };


    const handleAccept = () => {
        setIsModalVisible(false);
        setCheckboxes({
            comfort: true,
            statistics: true,
            marketing: true,
        });
        // Save the user preferences in cookies
        Cookies.set("languagePreference", selectedLanguage);
        Cookies.set("comfort", true);
        Cookies.set("statistics", true);
        Cookies.set("marketing", true);

        console.log(checkboxes);

        window.location.reload()
    }
    const handleReject = () => {
        setIsModalVisible(false);
        setCheckboxes({
            comfort: false,
            statistics: false,
            marketing: false,
        });
        // Save the user preferences in cookies
        Cookies.set("comfort", false);
        Cookies.set("statistics", false);
        Cookies.set("marketing", false);
        console.log(checkboxes);
        window.location.reload()
    }
    const handleCancel = () => {
        setIsModalVisible(false);
        setCheckboxes({
            comfort: false,
            statistics: false,
            marketing: false,
        });
        Cookies.set("comfort", false);
        Cookies.set("statistics", false);
        Cookies.set("marketing", false);

        console.log(checkboxes);
    };

    const handleAcceptAll = () => {
        setCheckboxes({
            comfort: true,
            statistics: true,
            marketing: true,
        });
        Cookies.set("languagePreference", selectedLanguage);
        Cookies.set("comfort", true);
        Cookies.set("statistics", true);
        Cookies.set("marketing", true);
        setIsModalVisible(false);
        window.location.reload()
    };
    const handleAcceptSelection = () => {
        const selections = Object.keys(checkboxes).filter((key) => checkboxes[key]);
        if (selections.length === 0) {
            // Show an Ant Design warning alert
            message.warning('Please make a selection before accepting.');
        }
        else {
            setIsModalVisible(false);
            selections.forEach((selection) => {
                Cookies.set(selection, true);
            });
        }
    };

    return (
        <div className="cookies-web">
            <div className="cookies-ilustration">
                <svg width="96" height="92" viewBox="0 0 96 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M57.5212 47.7311C52.3708 42.1902 47.4748 36.4582 43.3418 30.0892C40.4169 25.5036 37.81 20.3448 34.6944 17.1603C31.8966 14.2306 30.1163 11.1099 29.2261 7.92542C22.9948 9.70871 18.6711 11.2373 13.0756 17.0967C-25.9016 57.3482 30.5614 119.509 73.9259 78.6204C77.1052 75.627 75.9606 71.9967 77.8682 68.7486C71.8277 61.7428 63.8796 54.5459 57.5212 47.7311Z" fill="#5C5C5C" />
                    <path d="M73.9289 78.62C33.7435 111.293 -22.2108 59.3859 13.0786 17.0326C13.6509 16.3957 14.2867 15.6951 14.9225 15.0582C22.9978 7.03338 31.9632 3.27572 40.7378 2.70251C41.628 2.63883 42.3274 3.33941 42.391 4.16737C42.5182 9.38988 45.8882 12.8928 50.8477 13.5934C51.6108 13.7207 52.0559 14.4213 51.8651 15.1856C51.3564 16.8415 51.1657 18.6248 51.1657 20.4718C51.3564 29.8341 59.1137 37.5405 68.4606 37.6042C70.3682 37.6042 72.2121 37.3494 73.9925 36.7762C74.6919 36.5215 75.4549 36.9673 75.5821 37.7315C76.7266 43.9731 82.3856 42.8267 85.0562 41.935C85.8192 41.6803 86.6458 42.1261 86.8365 42.8904C92.0505 61.9334 73.9289 78.62 73.9289 78.62Z" fill="#FFF8EB" />
                    <path d="M46.6484 38.3689C46.6484 43.0819 42.8333 46.2663 38.1281 46.2663C33.4228 46.2663 29.6078 43.0819 29.6078 38.3689C29.6078 33.6559 33.4228 29.8345 38.1281 29.8345C42.8333 29.8345 46.6484 33.5922 46.6484 38.3689Z" fill="#B4875A" />
                    <path d="M73.4178 58.9406C73.4178 62.5709 70.4929 65.5006 66.8686 65.5006C63.2443 65.5006 60.7645 63.3352 60.7645 59.7049C60.7645 56.0746 63.2443 52.317 66.8686 52.317C70.4929 52.317 73.4178 55.3104 73.4178 58.9406Z" fill="#B4875A" />
                    <path d="M24.46 50.9157C24.46 53.718 22.4253 55.3103 19.6276 55.3103C16.8299 55.3103 14.4137 53.718 14.4137 50.9157C14.4137 48.1134 16.6392 45.8843 19.4369 45.8843C22.2346 45.8843 24.46 48.1134 24.46 50.9157Z" fill="#B4875A" />
                    <path d="M39.8443 67.983C39.8443 69.2568 38.827 70.2758 37.5553 70.2758C36.2836 70.2758 35.2662 69.2568 35.2662 67.983C35.2662 66.7092 36.2836 65.6902 37.5553 65.6902C38.827 65.6902 39.8443 66.7092 39.8443 67.983Z" fill="#B4875A" />
                    <path d="M24.3299 23.9753C25.7345 23.9753 26.8733 22.8347 26.8733 21.4278C26.8733 20.0208 25.7345 18.8802 24.3299 18.8802C22.9252 18.8802 21.7865 20.0208 21.7865 21.4278C21.7865 22.8347 22.9252 23.9753 24.3299 23.9753Z" fill="#B4875A" />
                    <path d="M70.2985 6.14166C70.5528 9.19874 67.8822 11.6826 64.3215 11.6826C60.7608 11.6826 58.0267 8.94398 58.2174 5.56846C58.4082 2.19293 61.0787 -0.290944 64.1943 0.0275014C67.31 0.345947 70.0441 3.02089 70.2985 6.14166Z" fill="#FFF8EB" />
                    <path d="M65.7232 10.4723C67.1221 10.4723 68.3937 10.0902 69.4111 9.38961C68.3302 10.7908 66.5498 11.6824 64.3879 11.6824C60.8272 11.6824 58.0931 8.94378 58.2838 5.63194C58.411 3.59389 59.4283 1.87428 60.8908 0.918945C60.1914 1.87428 59.6827 3.08438 59.6191 4.42185C59.4919 7.67 62.2261 10.4086 65.7232 10.4723Z" fill="#5C5C5C" />
                    <path d="M69.9847 23.4642C69.8575 25.5022 68.1408 27.2218 66.1696 27.4129C64.1985 27.5403 62.6089 25.8844 62.6725 23.7189C62.6725 21.5535 64.3893 19.7702 66.4876 19.7702C68.5858 19.7702 70.1119 21.4261 69.9847 23.4642Z" fill="#FFF8EB" />
                    <path d="M66.9299 26.521C67.693 26.4573 68.456 26.1389 69.0918 25.6931C68.3924 26.6484 67.3114 27.2853 66.1033 27.4127C64.1322 27.5401 62.6062 25.8841 62.6062 23.7187C62.6062 22.4449 63.242 21.2348 64.1958 20.5342C63.7507 21.1711 63.4328 21.9991 63.4328 22.827C63.3692 24.9925 64.9588 26.6484 66.9299 26.521Z" fill="#5C5C5C" />
                    <path d="M95.9865 25.0578C95.7957 27.478 93.8246 29.516 91.472 29.6434C89.1829 29.7708 87.339 27.8601 87.339 25.3125C87.4026 22.765 89.3737 20.7269 91.8535 20.7269C94.3333 20.7269 96.1772 22.7013 95.9865 25.0578Z" fill="#FFF8EB" />
                    <path d="M92.4257 28.6884C93.3795 28.6247 94.2697 28.2426 94.9691 27.6694C94.1425 28.8158 92.8708 29.5801 91.472 29.6438C89.1829 29.7711 87.339 27.8605 87.339 25.3129C87.339 23.7844 88.102 22.4469 89.1829 21.5552C88.6107 22.3195 88.2928 23.2748 88.2928 24.2939C88.2928 26.9051 90.1367 28.8158 92.4257 28.6884Z" fill="#5C5C5C" />
                </svg>
            </div>
            <div style={{ display: 'flex', flexDirection: "column", width: "70%" }}>
                <h2 className="textH2">
                    {t("Before you start: Learn more about the processing of your data")}
                </h2>
                <p className="cookiesparagraph">
                    {t(`We treat your data on our sites using different technologies (including cookies). Some of these technologies are technically necessary, while others are used by us or by third parties, for a comfortable configuration of the websites, to generate personalized (advertising) statistics or (advertising) actions. This also covers data transfers to countries outside the EU without a proper level of data protection. If you click reject, you will only authorize the use of the necessary techniques. Click on customizing you can allow specific uses. For more information, including your right to withdraw consent at any time, see our data protection policy. You can consult our legal information here.`)}
                    <span
                        onClick={() => {
                            setPrivacyModalVisible(!privacyModalVisible);
                        }}
                        style={{
                            paddingBottom: "10px",
                            cursor: "pointer",
                            fontWeight: "bold",
                            textDecoration: "none",
                            transition: "text-decoration 0.3s",
                        }}
                        onMouseEnter={(e) => {
                            e.target.style.textDecoration = "underline";
                        }}
                        onMouseLeave={(e) => {
                            e.target.style.textDecoration = "none";
                        }}
                    >
                        &nbsp;{t("Here")}
                        <PrivacyPolicyModal privacyModalVisible={privacyModalVisible} setPrivacyModalVisible={setPrivacyModalVisible} selectedLanguage={selectedLanguage} />
                    </span>
                </p>
            </div>
            <div style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "16px", width: "10%", minWidth: "100px" }}>
                {/* <button className="ButtonCustomize" onClick={handleCustomize}>
                    {selectedLanguage === "pt" ? "PERSONALIZAR" : "CUSTOMIZE"}
                </button> */}
                <button className="ButtonAccept" onClick={handleAccept} style={{ flex: 1 }}>
                    {t("ACCEPT")}
                </button>
                <button className="ButtonReject" onClick={handleReject} style={{ flex: 1 }}>
                    {t("REJECT")}
                </button>

            </div>
            {/* <Modal
                title={selectedLanguage === "pt" ? "Política de Cookies" : "Cookies Policy"}
                open={isModalVisible}
                width={700}
                onCancel={handleCancel}
                footer={[
                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <button className="ButtonAcceptAllModal" onClick={handleAcceptSelection}>
                            {selectedLanguage === "pt" ? "Aceitar Seleção" : "Accept Selection"}
                            {t("Accept Selection")}
                        </button>
                        <button className="ButtonRejectAllModal" onClick={handleCancel}>
                            {selectedLanguage === "pt" ? "Rejeitar Todos" : "Reject All"}
                        </button>
                    </div>
                ]}
            > 
            <div style={{ height: "500px", overflow: "auto" }}>
                <h2>{selectedLanguage === "pt" ? "Definir o âmbito do consentimento" : "Define the scope of consent"}</h2>
                <p>
                    {selectedLanguage === "pt" ? "Pode dar o seu consentimento às operações de tratamento de dados, de forma individual e de acordo com as finalidades e funções abaixo indicadas. Se alargar o respetivo botão, encontrará informações pormenorizadas sobre as técnicas e o tratamento de dados abrangidos em cada caso. Para mais informações, incluindo sobre o seu direito de retirada do consentimento em qualquer altura, consulte a nossa política de proteção de dados." : "It can consent to data processing operations individually and in accordance with the purposes and functions below. If you widen your button, you will find detailed information about the techniques and data processing covered in each case. For more information, including your right to withdraw consent at any time, see our data protection policy."}
                </p>
                <div>
                    <button className="ButtonAcceptAllModal" onClick={handleAcceptAll}>
                        {(selectedLanguage === "pt" ? "ACEITAR TODOS" : "ACCEPT ALL")}
                    </button>
                </div>

                <h2 style={{ marginTop: "16px" }}>{selectedLanguage === "pt" ? "Gerir preferências de cookies" : "Manage cookie preferences"}</h2>
                {
                    handleAccept
                }
                <CustumizeCookies checkboxes={checkboxes} setCheckboxes={setCheckboxes} />
            </div>

        </Modal>
        */}
        </div >
    );
};

export default CookiesWeb;
