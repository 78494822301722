import React from 'react';
import { Modal, Button } from 'antd';

const PrivacyPolicyModal = ({ privacyModalVisible, setPrivacyModalVisible, selectedLanguage }) => {
    return (
        <Modal
            width={'90%'}
            title={selectedLanguage === 'pt' ? 'Política de Privacidade' : 'Privacy Policy'}
            open={privacyModalVisible}
            onOk={() => setPrivacyModalVisible(false)}
            onCancel={() => {setPrivacyModalVisible(!privacyModalVisible)}}
            footer={[
                <Button key="ok" type="primary" onClick={() => setPrivacyModalVisible(false)}>
                    {selectedLanguage === 'pt' ? 'Fechar' : 'Close'}
                </Button>
            ]}
        >
            <h2>{selectedLanguage === 'pt' ? 'INTRODUÇÃO' : 'INTRODUCTION'}</h2>
            <p style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'pt' ? (
                    <span>
                        A <b>BOHOCASAS</b> acredita que é fundamental que seu direito à privacidade seja
                        respeitado, e temos o compromisso de proteger os seus dados pessoais, nos
                        termos do Regulamento Geral de Proteção de Dados e demais leis sobre o
                        tema, bem como através de nosso princípio orientador de “profissionalismo e
                        confiança” com transparência, ética, honra, integridade e respeito.
                    </span>
                ) : (
                    <span>
                        <b>BOHOCASAS</b> believes that it is fundamental that your right to privacy is
                        respected, and we are committed to protecting your personal data, in
                        accordance with the General Data Protection Regulation and other laws on
                        the subject, as well as through our guiding principle of "professionalism and
                        trust" with transparency, ethics, honor, integrity, and respect.
                    </span>
                )}
                <br /><br />
                <b>{selectedLanguage === 'pt' ? 'Nosso objetivo' : 'Our goal'}:</b>{' '}
                {selectedLanguage === 'pt' ? (
                    'Garantir o direito à privacidade e proteção dos dados pessoais, por meio de práticas transparentes e seguras, sem afetar o nosso modelo de negócios!'
                ) : (
                    'To ensure the right to privacy and protection of personal data, through transparent and secure practices, without affecting our business model!'
                )}
                <br /><br />
                <b>{selectedLanguage === 'pt' ? 'Esta filosofia inclui os dados pessoais' : 'This philosophy includes personal data'}:</b>{' '}
                {selectedLanguage === 'pt' ? (
                    'recolhidos ou recepcionados e seu tratamento até seu descarte, de forma física ou digital.'
                ) : (
                    'collected or received and their processing until their disposal, physically or digitally.'
                )}
                <br />
                {selectedLanguage === 'pt' ? (
                    'Isto só não se aplica às páginas contidas em sites de outras organizações/instituições que eventualmente possam ter links ou referências em nosso site.'
                ) : (
                    'This does not apply to pages contained on websites of other organizations/institutions that may have links or references on our site.'
                )}
                <br />
                {selectedLanguage === 'pt' ? (
                    'Também faz parte desta filosofia, todos os dados pessoais de nossos colaboradores internos, parceiros, clientes e fornecedores que estejam porventura aos nossos cuidados.'
                ) : (
                    'Also part of this philosophy are all personal data of our internal collaborators, partners, clients, and suppliers that are under our care.'
                )}
            </p>
            <h2 style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? 'COMO E POR QUE RECOLHEMOS SEUS DADOS' : 'HOW AND WHY WE COLLECT YOUR DATA'}</h2>
            <h3 style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? 'Sabemos da nossa responsabilidade. Por isso cuidamos dos seus dados pessoais!' : 'We know of our responsibility. Therefore, we care about your personal data!'}</h3>
            <p style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'pt' ? (`
                            Nenhum dado pessoal é recolhido sem que seja observado os princípios da licitude, lealdade e transparência. Esses dados pessoais são recolhidos
                            para viabilizar a prestação de serviço aos nossos clientes e a manter o
                            negócio da BOHOCASAS. Os dados pessoais utilizadas estão de acordo com as finalidades de cada tratamento de forma lícita e prevista, descritas na tabela abaixo,
                            conforme regras do Regulamento Geral de Proteção de Dados - RGPD. Para os colaboradores, os dados recolhidos são exclusivamente aqueles
                            para atender diligências pré-contratuais relacionadas a contrato de
                            trabalho, para execução do contrato, bem como para atender os direitos
                            e deveres advindos desse contrato e outras obrigações jurídicas oriundas
                            de normas regulatórias, inclusive categorias especiais de dados pessoais.`

                ) : (
                    `
                            No personal data is collected without observing the principles of lawfulness, loyalty, and transparency. This personal data is collected
                            to enable the provision of services to our clients and to maintain the
                            business of BOHOCASAS. The personal data used is in accordance with the purposes of each treatment in a lawful and foreseen manner, as described in the table below,
                            in accordance with the rules of the General Data Protection Regulation - GDPR. For employees, the collected data is exclusively those
                            to meet pre-contractual diligence related to the employment contract,
                            for the execution of the contract, as well as to meet the rights
                            and duties arising from that contract and other legal obligations arising
                            from regulatory standards, including special categories of personal data.`
                )}
            </p>
            <h3>{selectedLanguage === 'pt' ? 'DADOS PESSOAIS RECOLHIDOS' : 'PERSONAL DATA COLLECTED'}</h3>
            <table border="1" cellSpacing="0" cellPadding="0" width="100%" align="center">
                <tr>
                    <th>{selectedLanguage === 'pt' ? 'Dados Pessoais' : 'Personal Data'}</th>
                    <th>{selectedLanguage === 'pt' ? 'Finalidade' : 'Purpose'}</th>
                    <th>{selectedLanguage === 'pt' ? 'Licitude' : 'Lawfulness'}</th>
                </tr>
                <tr>
                    <td>
                        {selectedLanguage === 'pt' ? 'Nome, telefone, e-mail, morada, historico profissional NIF, NISS, cartão cidadão ou passaporte ou visto, senha das finanças e dados bancários.' : 'Name, phone, email, address, professional history NIF, NISS, ID card or passport or passport, financial password and bank data.'}
                    </td>
                    <td>{selectedLanguage === 'pt' ? 'Para Seleção e, Admissão de colaboradores' : 'For Selection and Admission of Collaborators'}</td>
                    <td>{selectedLanguage === 'pt' ? 'Diligências pré contratuais e Execução de contrato' : 'Pre-contractual diligences and Contract Execution'}</td>
                </tr>
                <tr>
                    <td> {selectedLanguage === 'pt' ? 'Nome, telefone, e-mail, morada, historico profissional NIF, NISS, cartão cidadão ou passaporte ou visto, senha das finanças e dados bancários.' : "Name, telephone, email, address, professional history NIF, NISS, Citizen or Passport Card or visa, finance password and bank data."}
                    </td>
                    <td>{selectedLanguage === 'pt' ? 'Para atender direitos e deveres trabalhistas advindos do contrato de trabalho e normas aplicáveis' : 'To meet labor rights and duties arising from the employment contract and applicable rules'}</td>
                    <td>{selectedLanguage === 'pt' ? 'Obrigação Jurídica' : 'Legal Obligation'}</td>
                </tr>
                <tr>
                    <td>
                        {selectedLanguage === 'pt' ? "Nome, telefone, whatsapp, e-mail, NIF, morada, assinatura digital." : "Name, phone, WhatsApp, Email, NIF, address, signature digital."}
                    </td>
                    <td>{selectedLanguage === 'pt' ? 'Cumprimento de contrato com cliente' : 'Fulfillment of contract with customer'}</td>
                    <td>{selectedLanguage === 'pt' ? 'Diligências pré contratuais e Execução de contrato; Obrigação Jurídica' : 'Pre-contractual diligences and Contract Execution; Legal Obligation'}</td>
                </tr>
                <tr>
                    <td>
                        {selectedLanguage === 'pt' ? "Nome, morada, NIF,dados bancários,telefone" : "Name, address, NIF, bank data, phone"}
                    </td>
                    <td>{selectedLanguage === 'pt' ? 'Cumprimento de contrato com fornecedores' : 'Fulfillment of contract with suppliers'}</td>
                    <td>{selectedLanguage === 'pt' ? 'Diligências pré contratuais e Execução de contrato; Obrigação Jurídica' : 'Pre-contractual diligences and Contract Execution; Legal Obligation'}</td>
                </tr>
            </table>
            <h2 style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? 'COMO COMPARTILHAMOS E ARMAZENAMOS SEUS DADOS' : 'HOW WE SHARE AND STORE YOUR DATA'}</h2>
            <p style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? `A BOHOCASAS, ora responsável pelo tratamento, ora subcontratante de dados pessoais, para realizar suas atividades e fornecer serviços com qualidade e segurança, COMPARTILHA com alguns fornecedores e Órgãos Públicos, como subcontratantes, os dados pessoais apenas com a finalidade de executar os contratos no limite da atividade contratada, seja dos clientes ou colaboradores.` : `
            Bohocasas, now responsible for the processing, now subcontractor of personal data, to perform its activities and provide services with quality and security, shares with some suppliers and public agencies, such as subcontractors, personal data only for the purpose of executing contracts on the limit of the contracted activity, whether of customers or employees.`}</p>
            <p style={{ textAlign: 'justify' }}> {selectedLanguage === 'pt' ? `Para os dados pessoais recolhidos e tratados em formato digital, são armazenados em servidor externo e são realizados backups periódicos e HD externo. Login e senha com renovação periódica. Dados criptografados na versão HTTPs.` : `For the personal data collected and treated in digital format, it is stored on external server and periodic backups and external hard drive are performed. Login and password with periodic renewal. Encryled data in the https version.`} </p>
            <p style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? `O armazenamento temporário dos dados pessoais em formato físico, são feitos em armários com acesso restrito e limitado aos responsáveis pelo tratamento daqueles dados específicos.` : `Temporary storage of personal data in physical format, are stored in restricted access and limited to the responsible for the processing of those specific data.`}
            </p>
            <p style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? `Os dados pessoais armazenados e tratados, serão mantidos durante o prazo do contrato ou relação comercial firmado, seja de trabalho, parceria, com clientes ou fornecedores e/ou conforme seja necessário para consecução de seus serviços. Esses dados poderão ser mantidos, mesmo após o término do prazo do contrato, relação comercial ou quando receber seu pedido de exclusão, caso seja necessário para cumprimento de obrigações jurídicas, resolver disputas extra judiciais ou judiciais, manter a segurança, evitar fraudes e abuso e garantir o cumprimento de contratos, conforme nossa Política de Descarte de Dados Pessoais.` : `
            The stored and treated personal data will be maintained during the term of the contract or commercial relationship signed, whether work, partnership, with customers or suppliers and/or as needed to achieve their services. These data may be maintained, even after the term of the contract, commercial relationship or when it receives its request for exclusion, if necessary to fulfill legal obligations, resolve extra judicial or judicial disputes, maintain security, avoid fraud and abuse and Ensure compliance with contracts, according to our personal data disposal policy.`}</p>
            <h2 style={{ textAlign: 'left', wordBreak: 'break-word', wordWrap: 'break-word' }}>{selectedLanguage === 'pt' ? 'REALIZAMOS TRANSFERÊNCIA INTERNACIONAL DE DADOS' : 'WE PERFORM INTERNATIONAL DATA TRANSFER'}</h2>
            <p style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? `Alguns dados pessoais como, nome, e-mail e telefone são compartilhados com outros países, uma vez que temos fornecedores nessas localidades, atendendo o art. 49º da RGPD.` : `Some personal data such as name, email and phone are shared with other countries, once we have suppliers in these locations, according to article 49 of the RGPD.`}</p>
            <p style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? `Esse compartilhamento tem a finalidade de compra de material para obras ou execução de serviço e são utilizados para emissão dos pedidos de compras.` : `The sharing has the purpose of providing material for writing or execution and are used to issue purchase orders.`}</p>
            <p style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? `Os países que a BOHOCASAS possui relação comercial são: Reino Unido, Espanha, França, Itália, Alemanha.` : `The BOHOCASAS has relationships with: United Kingdom, Spain, France, Italy, Germany.`}</p>
            <h2 style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? 'SEGURANÇA DOS DADOS PESSOAIS' : 'SECURITY OF PERSONAL DATA'}</h2>
            <p style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? `Estamos empenhados em proteger os dados pessoais dos nossos colaboradores, clientes, fornecedores e prestadores de serviço, implementando medidas técnicas e administrativas apropriadas para ajudar a proteger a segurança dos seus dados pessoais.` : `We are committed to protecting the personal data of our employees, customers, suppliers and service providers, implementing appropriate technical and administrative measures to help protect security from your personal data.`}</p>
            <p style={{ textAlign: 'justify' }}> {selectedLanguage === 'pt' ? `Não coletamos dados pessoais desnecessários e em hipótese alguma realizamos tratamento diverso daquele pactuado em contrato, e não vendemos a quaisquer outras instituições, empresas ou pessoas. Somente colaboradores autorizados têm acesso aos mesmos.` : `We do not collect personal data unnecessary or in case of a matter of contract, and do not sell to any other institution, company or person. Only authorized employees have access to them.`}</p>
            <p style={{ textAlign: 'justify' }}> {selectedLanguage === 'pt' ? `O site está hospedado no Azure Cloud, com certificado ssl de 2048 bits` : `The site is hosted on Azure Cloud, with ssl certificate of 2048 bits`}</p>
            <p style={{ textAlign: 'justify' }}> {selectedLanguage === 'pt' ? `O site recolhe dados pessoais como Nome, Email e Telefone e são enviados para a API criptografados e toda comunicação entre o site e a API é feita em uma comunicação segura (HTTPS).` : `The site collects personal data as Name, Email and Telephone and are sent to an encrypted API and all communication between the site and the API is secured (HTTPS).`}</p>
            <p style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? `A proteção de dados em trânsito inclui comunicação através de Redes Privadas Virtuais (utilizando encriptação IPsec/IKE), Transport Layer Security (TLS) 1.2 ou posterior (através de componentes do Azure, como Gateway de Aplicação ou Azure Front Door).` : `Transit data protection includes communication through virtual private networks (using IPSEC/IKE encryption), Transport Layer Security (TLS) 1.2 or later (through Azure components such as application gateway or Azure Front Door).`}</p>
            <p style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? `Além disso, a 'encriptação por predefinição' com o MACsec (uma norma IEEE na camada de ligação de dados) está ativada para todo o tráfego do Azure que viaja entre datacenters do Azure para garantir a confidencialidade e integridade dos dados dos clientes.` : `In addition, "predefate encryption" with MacSEC (an IEEE standard in the data connection layer) is activated for all Azure traffic that travels between Azure Datacers to ensure the confidentiality and integrity of customer data.`}</p>
            <p style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? `Todos os softwares utilizados são licenciados, com suas renovações avaliadas anualmente para atender as demandas operacionais.` : `All used software is licensed, with their annual renewals to meet operational demands.`}</p>
            <p style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? `Em relação aos dados pessoais em formato físico, tratados e armazenados na BOHOCASAS, possuem restrição e limitação de acesso, a fim de preservar as informações. ` : `In connection with personal data in physical format, stored and processed in BOHOCASAS, there is a restriction and limitation of access, in order to preserve data. `}</p>
            <h2 style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? 'POLÍTICA DE COOKIES' : 'COOKIE POLICY'}</h2>
            <h3 style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? 'O QUE SÃO COOKIES?' : 'WHAT ARE COOKIES?'}</h3>
            <p style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? `Cookies são pequenos arquivos de texto que armazenam por um período o que o usuário está fazendo. Cookies armazenam seu histórico de navegação, bem como logins e senhas. Os cookies podem ser próprios ou primários e Cookies de terceiros. Podem ser necessários ou não necessários, ou ainda, temporários ou persistentes.` : `Cookies are small text files that store for a period what the user is doing. Cookies store your navigation history as well as logins and passwords. Cookies can be own or primary and cookies of third parties. They may or may not be necessary or not necessary, temporary or persistent.`}</p>
            <h3 style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? 'QUAIS COOKIES UTILIZAMOS?' : 'WHAT COOKIES DO WE USE?'}</h3>
            <p style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? `Conforme os tipos de cookies e finalidades, seguem os que utilizamos:` : `As types of cookies and purposes, we use:`}</p>
            <p style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? `Cookies analíticos ou de desempenho: Utilizamos o cookie usado pelo Google Analytics, o "_ga", permite que um serviço diferencie um visitante dos outros e tem validade de dois anos. Todo site que implementa o Google Analytics, incluindo os Serviços do Google, usa esse cookie` : `Analytical or Performance Cookies: We use the cookie used by Google Analytics, "_GA", allows a service to differentiate a visitor from others and is valid for two years. Every site that implements Google Analytics, including Google services, uses this cookie`}</p>
            <p style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? `Cookies necessários Cookies essenciais para o funcionamento do site e permitem que os usuários naveguem e usem os recursos do site, como acessar áreas seguras do site` : `Essential Cookies: We use essential cookies for the site's functioning and allow users to navigate and use the site's resources, such as accessing secure sites`}</p>
            <h3 style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? 'COMO GERIR COOKIES' : 'HOW TO MANAGE COOKIES'}</h3>
            <p style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? `Caso o Usuário não deseje que os cookies mencionados sejam utilizados quando da sua navegação no nosso website, é possível optar por recusar, desabilitar, ou apagar os registros de cookies através das configurações do navegador utilizado. Chamamos a atenção para o fato de que, ao fazer isso, algumas áreas, ferramentas e funcionalidades poderão ser comprometidas, afetando, dessa forma, o correto funcionamento do website.` : `If you do not want the mentioned cookies to be used when you navigate our website, you can choose to refuse, disable, or delete cookies records through the browser settings used. We draw attention to the fact that by doing so, some areas, tools and functionality can be compromised, thus affecting the correct functioning of the website.`}</p>
            <h3 style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? 'DIREITO DOS TITULARES' : 'RIGHTS OF DATA SUBJECTS'}</h3>
            <p style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? ` É importante para a BOHOCASAS ser transparente, respeitar e observar os direitos de todos os titulares de dados pessoais, atendendo aos direitos abaixo, desde que não haja nenhum impedimento legal que nos faça manter os dados pelos prazos estabelecidos em lei.` : `
It is important for Bohocasas to be transparent, respect and observe the rights of all personal data holders, meeting the rights below, provided that there is no legal impediment that makes us keep the data for the deadlines established by law.`}</p>
            <ul style={{ textAlign: 'justify' }}>
                <li style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? 'Direito de acesso' : 'Right of access'}</li>
                <li style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? 'Direito de retificação' : 'Right to rectification'}</li>
                <li style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? 'Direito de apagamento' : 'Right to erasure'}</li>
                <li style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? 'Direito à limitação do tratamento' : 'Right to restriction of processing'}</li>
                <li style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? 'Obrigação de notificação da retificação e apagamento dos dados, ou limitação do tratamento' : 'Obligation to notify rectification and erasure of data, or restriction of processing'}</li>
                <li style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? 'Direito de portabilidade dos dados' : 'Right to data portability'}</li>
                <li style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? 'Direito de oposição' : 'Right to object'}</li>
                <li style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? 'Direito de não se sujeitar a Decisões individuais automatizadas' : 'Right not to be subject to automated individual decision-making'}</li>
            </ul>
            <p style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? ` No caso de apresentação de solicitação do titular para exercer os direitos de acesso, retificação, apagamento, limitação de tratamento, notificação da retificação ou apagamento e portabilidade, será atendida no prazo de um mês a contar da data de receção do pedido. Esse prazo pode ser prorrogado até dois meses, quando for necessário.` : `In the case of requesting the request of the holder to exercise the rights of access, rectification, deletion, limitation of treatment, notification of rectification or deletion and portability, will be met within one month from the date of receipt of the order. This period can be extended up to two months when necessary.`} </p>
            <h2 style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? 'CANAIS DE COMUNICAÇÃO' : 'COMMUNICATION CHANNELS'}</h2>
            <p style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? `A BOHOCASAS utiliza vários canais de comunicação com os titulares. Agregando a esses canais de comunicação, o titular pode também utilizar o email indicado no final dessa Política, para todos os assuntos referentes ao Regulamento Geral de Proteção de Dados Pessoais – RGPD.` : `Bohocasas uses various communication channels with the holders. Adding to these communication channels, the holder can also use the email indicated at the end of this policy, for all matters regarding the General Personal Data Protection Regulation - RGPD.`}</p>
            <p style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? ` Para a efetiva comunicação por e-mail, é indispensável que o titular sempre forneça os dados corretos e precisos, e os mantenha atualizados.` : `For communication via email, it is necessary that the holder always provides the correct and up-to-date data.`}</p>
            <p style={{ textAlign: 'justify' }}> {selectedLanguage === 'pt' ? `É igualmente de sua responsabilidade, deixar seus sistemas de anti-spam configurados de modo que não interfiram no recebimento das informações da BOHOCASAS não sendo aceitável nenhuma escusa caso não tenha tido acesso a algum e-mail em virtude desse bloqueio ou filtro similar ` : `It is also your responsibility, to leave your anti-spam systems configured so that they do not interfere with the receipt of Bochocasas information and no excitement is not acceptable if you have not accessed any email due to this lock or similar filter:`}</p>
            <p style={{ textAlign: 'justify' }}><b> {selectedLanguage === 'pt' ? ` A BOHOCASAS reserva-se o direito de modificar a presente política para adaptá-la a alterações legislativas ou jurisprudências, ou aquelas relativas às práticas comerciais. Em qualquer caso, dará publicidade das mudanças introduzidas. ` : `Bohocasas reserves the right to modify this policy to adapt it to legislative changes or jurisprudence, or those related to business practices. In any case, it will advertise the changes introduced.`}</b></p>
            <h2 style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? 'AINDA COM DÚVIDAS?' : 'STILL HAVE QUESTIONS?'}</h2>
            <h3 style={{ textAlign: 'justify' }}>{selectedLanguage === 'pt' ? 'Caso você tenha alguma dúvida, sugestão ou problema no que diz respeito a esta Política de Privacidade da BOHOCASAS como um todo, entre em contato com nosso Responsável, através do nosso e-mail: contato@bohocasas.com' : 'If you have any questions, suggestions, or issues regarding this Privacy Policy of BOHOCASAS as a whole, please contact our Data Protection Officer via email: contato@bohocasas.com'}</h3>
        </Modal>
    );
};

export default PrivacyPolicyModal;
