import React, { useState, useRef } from "react";
import styles from "./Slider_Projects.module.css";
import { useTranslation } from "react-i18next";
import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import Text_Regular_32 from "../../atoms/Text_Regular_32";
import Text_Bold_14 from "../../atoms/Text_Bold_14";
import checkIsMobile from "../../../utils/checkIsMobile";
import BoldH6 from "../../atoms/TextStyleBold/BoldH6";

const InfiniteScrollComponent = ({ listOfProjects, backgroundColor }) => {
  const { t } = useTranslation();

  const refFlipping = useRef(null);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  return (
    <>
      {!checkIsMobile() ? (
        <div
          id="sliderProjectsResidential"
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",

          }}
        >
          <Flicking
            ref={refFlipping}
            align="center"
            style={{ width: "100vw", height: "600px" }}
            circular={true}
            interruptable={true}
            onChanged={(e) => {
              setCurrentImageIndex(e.currentTarget.index);
            }}
          >
            {listOfProjects?.map((item, index) => {
              return (
                <div
                  style={{
                    height: 550,
                    width: "50vw",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      height: "98%",
                      width: "98%",
                      backgroundColor: backgroundColor,
                    }}
                  >
                    <img
                      draggable={false}
                      src={item?.images[0]}
                      alt={item.name}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "24px",
                        objectFit: "cover",
                        objectPosition: "center"
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </Flicking>

          <div
            style={{
              minHeight: "80px",
              textAlign: "center",
              backgroundColor: backgroundColor,
              width: "42vw",
              alignSelf: "center",
              marginTop: "-40px",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
              zIndex: 1,
              display: "flex",
              flexDirection: "column",
              paddingTop: "10px",
            }}
          >
            <Text_Bold_14
              value={listOfProjects[currentImageIndex]?.description}
              textColor={"#51787D"}
            />
            <BoldH6
              value={listOfProjects[currentImageIndex]?.name}
              isSelected={true}
              textColor={"#545454"}
            />
          </div>
          <ul className={styles.bulletContainer}>
            {listOfProjects.map((_, index) => (
              <li
                key={index}
                className={
                  currentImageIndex === index
                    ? styles.activeBullet
                    : styles.bullet
                }
                onClick={() => refFlipping.current.moveTo(index)}
              >
                <div className={styles.circle}></div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div
          id="sliderProjectsResidential"
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",

          }}
        >
          <Flicking
            ref={refFlipping}
            align="center"
            style={{ width: "100vw" }}
            circular={true}
            interruptable={true}
            onChanged={(e) => {
              setCurrentImageIndex(e.currentTarget.index);
            }}
          >
            {listOfProjects?.map((item, index) => {
              return (
                <div
                  style={{
                    height: 350,
                    width: "60vw",
                    height: "85vw",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: backgroundColor,
                    }}
                  >
                    <img
                      draggable={false}
                      src={item?.images[0]}
                      alt={item.name}
                      style={{
                        width: "60vw",
                        height: "85vw",
                        borderRadius: "24px",
                        objectFit: "cover",
                        padding: "0.3rem"
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </Flicking>
          <div
            style={{
              textAlign: "center",
              width: "90vw",
              alignSelf: "center",
              borderRadius: "5px",
              zIndex: 1,
              display: "flex",
              flexDirection: "column",
            }}
            className={styles.descriptionSliderMobile}
          >
            <Text_Bold_14
              value={listOfProjects[currentImageIndex]?.description}
              textColor={"#51787D"}

            />
            <Text_Regular_32
              value={listOfProjects[currentImageIndex]?.name}
              textColor={"#545454"}
              isSelected={false}
              FontSize={19}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default InfiniteScrollComponent;
