import React from "react";
import {
  Container,
  ContainerPart1,
  ContainerPart2,
  ContainerPart1Column1,
  ContainerPart1Column2,
  ContainerPart2Column1,
  ContainerPart2Column2,
  Label,
  LabelTitle,
  LabelSubTitle,
  Paragraphs,
  Paragraph,
  Label2,
  LabelTitle2,
  LabelSubTitle2,
  Paragraphs2,
  Paragraph2,
  ContainerMobile,
  ContainerPart1Mobile,
  ContainerPart2Mobile,
  ContainerPart1Column1Mobile,
  ContainerPart1Column2Mobile,
  ContainerPart2Column1Mobile,
  ContainerPart2Column2Mobile,
  LabelMobile,
  LabelTitleMobile,
  LabelSubTitleMobile,
  Label2Mobile,
  LabelTitle2Mobile,
  LabelSubTitle2Mobile,
  ParagraphsMobile,
  ParagraphMobile,
  Paragraphs2Mobile,
  Paragraph2Mobile,
} from "./AboutUs.styled";
import { useTranslation } from "react-i18next";
import checkIsMobile from "../../../utils/checkIsMobile";

const Image = {
  height: "600px",
  width: "100%",
  borderRadius: "24px",
  objectFit: "cover",
  objectPosition: "center",
};

const ImageMobile = {
	height: "85vw",
	width: "100%",
	borderRadius: "24px",
	objectFit: "cover",
  };

export default function AboutUs() {
  const { t } = useTranslation();
  return (
    <>
      {!checkIsMobile() ? (
        <Container id="aboutUs">
          <ContainerPart1>
            <ContainerPart1Column1>
              <Label>
                <LabelTitle>
                  <svg
                    width="64"
                    height="1"
                    viewBox="0 0 64 1"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="64" height="1" fill="#556F70" />
                  </svg>
                  &nbsp; &nbsp;&nbsp; &nbsp;
                  {"BOHOCASAS"}
                </LabelTitle>
                <LabelSubTitle>{t("Our Story")}</LabelSubTitle>
                <Paragraphs>
                  <Paragraph>
                    {t(
                      "Our company has been operating in Portugal since 2019, developing architectural and interior projects. Over time, we were faced with a shortage of good companies to carry out the works, which left us quite worried, as we were unable to get our projects executed with the perfection we expected."
                    )}
                  </Paragraph>
                  <Paragraph>
                    {t(
                      "Furthermore, we brought from Brazil the knowledge and desire to work with LSF (Light Steel Framing) constructions, as we have always believed that it is the most efficient construction system today."
                    )}
                  </Paragraph>
                </Paragraphs>
              </Label>
            </ContainerPart1Column1>
            <ContainerPart1Column2>
              <img
                src="/images/about-one.jpeg"
                alt=""
                style={Image}
              ></img>
            </ContainerPart1Column2>
          </ContainerPart1>
          <ContainerPart2>
            <ContainerPart2Column1>
              <img
                src="/images/about-two.jpeg"
                alt=""
                style={Image}
              ></img>
            </ContainerPart2Column1>
            <ContainerPart2Column2>
              <Label2>
                <LabelTitle2>
                  <svg
                    width="64"
                    height="1"
                    viewBox="0 0 64 1"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="64" height="1" fill="#556F70" />
                  </svg>
                  &nbsp; &nbsp;&nbsp;
                  {"BOHOCASAS"}
                </LabelTitle2>
                <LabelSubTitle2>{t("And so BohoCasa was born")}</LabelSubTitle2>
              </Label2>
              <Paragraphs2>
                <Paragraph2>
                  {t(
                    "And given our needs, knowledge and desires, we make BohoCasas® a complete company, ready to serve our customers from start to finish."
                  )}
                </Paragraph2>
                <Paragraph2>
                  {t(
                    "BohoCasas® is made up of a multidisciplinary team prepared to develop architectural projects, specialties, take care of licensing, prepare interior and exterior projects, carry out renovation works and new constructions in LSF, with efficient management. ​We have the complete solution to make your dream of building or transforming your home come true! Bringing all services together in one place!"
                  )}
                </Paragraph2>
              </Paragraphs2>
            </ContainerPart2Column2>
          </ContainerPart2>
        </Container>
      ) : (
        <ContainerMobile id="aboutUs">
          <ContainerPart1Mobile>
            <ContainerPart1Column2Mobile>
              <img
                src="/images/about-one.jpeg"
                alt=""
                style={ImageMobile}
              ></img>
            </ContainerPart1Column2Mobile>
            <ContainerPart1Column1Mobile>
              <LabelMobile>
                <LabelTitleMobile>
                  <svg
                    width="64"
                    height="1"
                    viewBox="0 0 64 1"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="64" height="1" fill="#556F70" />
                  </svg>
                  {"BOHOCASAS"}
                </LabelTitleMobile>
                <LabelSubTitleMobile>{t("Our Story")}</LabelSubTitleMobile>
                <ParagraphsMobile>
                  <ParagraphMobile>
                    {t(
                      "Our company has been operating in Portugal since 2019, developing architectural and interior projects. Over time, we were faced with a shortage of good companies to carry out the works, which left us quite worried, as we were unable to get our projects executed with the perfection we expected."
                    )}
                  </ParagraphMobile>
                  <ParagraphMobile>
                    {t(
                      "Furthermore, we brought from Brazil the knowledge and desire to work with LSF (Light Steel Framing) constructions, as we have always believed that it is the most efficient construction system today."
                    )}
                  </ParagraphMobile>
                </ParagraphsMobile>
              </LabelMobile>
            </ContainerPart1Column1Mobile>
          </ContainerPart1Mobile>
          <ContainerPart2Mobile>
            <ContainerPart2Column1Mobile>
              <img
                src="/images/about-two.jpeg"
                alt=""
                style={ImageMobile}
              ></img>
            </ContainerPart2Column1Mobile>
            <ContainerPart2Column2Mobile>
              <Label2Mobile>
                <LabelTitle2Mobile>
                  <svg
                    width="64"
                    height="1"
                    viewBox="0 0 64 1"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="64" height="1" fill="#556F70" />
                  </svg>
                  &nbsp; &nbsp;&nbsp;
                  {"BOHOCASAS"}
                </LabelTitle2Mobile>
                <LabelSubTitle2Mobile>
                  {t("And so BohoCasa was born")}
                </LabelSubTitle2Mobile>
              </Label2Mobile>
              <Paragraphs2Mobile>
                <Paragraph2Mobile>
                  {t(
                    "And given our needs, knowledge and desires, we make BohoCasas® a complete company, ready to serve our customers from start to finish."
                  )}
                </Paragraph2Mobile>
                <Paragraph2Mobile>
                  {t(
                    "BohoCasas® is made up of a multidisciplinary team prepared to develop architectural projects, specialties, take care of licensing, prepare interior and exterior projects, carry out renovation works and new constructions in LSF, with efficient management. ​We have the complete solution to make your dream of building or transforming your home come true! Bringing all services together in one place!"
                  )}
                </Paragraph2Mobile>
              </Paragraphs2Mobile>
            </ContainerPart2Column2Mobile>
          </ContainerPart2Mobile>
        </ContainerMobile>
      )}
    </>
  );
}
