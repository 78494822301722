import React from "react";
import "./ProjectsResidentialInterior.css";
import { PageHead } from "../../atoms/PageHead";
import { useTranslation } from "react-i18next";
import SemiBoldH4 from "../../atoms/TextStyleSemiBold/SemiBoldH4";
import { useLanguage } from '../../../utils/translations/LanguageContext';
import { Button } from "../../atoms/Button";
import BoxWithIconsInterior from "../../molecules/BoxWithIconsInterior";
import checkIsMobile from "../../../utils/checkIsMobile";
import { Container, Typography } from "@mui/material";
import Text_Regular_14 from "../../atoms/Text_Regular_14";
export default function ProjectsResidentialInterior() {
  const { selectedLanguage } = useLanguage();
  const { t } = useTranslation();
  return (
    <>
      {!checkIsMobile() ? (
        <div>
          <div className="containerResidential1" id="#services2">
            <div className="box">
              <div className="group">
                <div className="info">
                  <div className="overlap-group-Residential">
                    <div className="ImageResidentialInterior1" />
                    <div className="ImageResidentialInterior2" />
                  </div>
                </div>
                <div className="overlap-group-wrapper">
                  <PageHead
                    text={t("services")}
                    text1={selectedLanguage === "pt" ? "Projetos Residenciais De Interiores" : "Projects Residential Interior"}
                  />
                  <p className="lorem-ipsum-dolor" style={{ textAlign: "justify", whiteSpace: "break-spaces" }}>
                    {selectedLanguage === "pt" ? "Nossos projetos de interiores são detalhados e completos, para que a execução das obras, montagens e carpintaria fiquem perfeitos no seu espaço! Trabalhamos desde o layout dos ambientes, passando pelas especificações de acabamentos, louças e metais e mobílias soltas; pontos elétricos e hidráulicos, luminoténico, detalhamento de carpintaria, imagens fotorealistas e quantitativos para orçamentos e obras." : "Our interior projects are detailed and complete, so that the execution of works, assemblies and carpentry are perfect in your space! We have worked from the layout of the environments, going through the specifications of finishes, dishes and metals and loose furniture; Electrical and hydraulic points, luminotheric, carpentry detail, photoreal and quantitative images for budgets and works."}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container3">
            <SemiBoldH4 value={selectedLanguage === "pt" ? "Projetos Residenciais De Interiores" : "Residential Interior Projects"} isSelected={true} textColor={"#51787d"} />
            <br></br>
            <SemiBoldH4 value={t("Step by step process")} isSelected={true} textColor={"#545454"} />
            <br></br>
            <BoxWithIconsInterior />
          </div>
          <div className="BoxButton">
          <a
              style={{
                display: "flex",
                width: "427px",
                height: "50px",
                padding: "8px 16px",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
                flexShrink: "0px",
                borderRadius: "12px",
                border: "none",
                transition: "1s ease",
                textDecoration: "none",
                background:
                  "var(--token-colors-status-interaction-elements, #51787d)",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor =
                  "var(--slot-colors-brand-colors-base-slot-1, #84A8A9)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor =
                  "var(--token-colors-status-interaction-elements, #51787d)"; // Voltando à cor original no mouseleave
              }}
              href="/blog/ResidentialsInterior"
            >
              <Text_Regular_14 value={selectedLanguage === "pt" ? "Conheça Nossos Projetos" : "Get to know our projects"}
              isSelected={true} />
            </a>
          </div>
        </div>
      ) : (
        (
          <div  id="#services2" style={
            {
              padding: "1rem"
            }}>
            <Typography fontSize={24} textAlign={"center"} color={"#51787D"}>
              {selectedLanguage === "pt" ? "Projetos Residenciais De Interiores" : "Projects Residential Interior"}
            </Typography>
            <div style={
              {
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
              }
            }>
              <img src="https://bohocasasstorage.blob.core.windows.net/boho-casas/SintraCozinha.png" style={{
                width: "80vw",
                height: "40vh",
                objectFit: "cover",
                borderRadius: 18,
              }} />
              <img src="https://bohocasasstorage.blob.core.windows.net/boho-casas/Lisboa2_SALA1.png" style={{
                width: "80vw",
                height: "40vh",
                objectFit: "cover",
                borderRadius: 18
              }} />
            </div>
            <Container sx={{
              minWidth: 300,
              width: "95%",
              maxWidth: 900,
              padding: "1rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "0.5rem",
            }}>

              <div style={{ width: 100, height: 1, background: "#51787D" }} />
              <Typography fontSize={18} textAlign={"center"} color={"#51787D"}>
                {t("services")}
              </Typography>
              <Typography fontSize={24} textAlign={"center"} color={"#51787D"}>
                {selectedLanguage === "pt" ? "Projetos Residenciais De Interiores" : "Projects Residential Interior"}
              </Typography>
              <Typography sx={{ textAlign: "justify", color: "#545454" }}>
                {selectedLanguage === "pt" ? "Nossos projetos de interiores são detalhados e completos, para que a execução das obras, montagens e carpintaria fiquem perfeitos no seu espaço! Trabalhamos desde o layout dos ambientes, passando pelas especificações de acabamentos, louças e metais e mobílias soltas; pontos elétricos e hidráulicos, luminoténico, detalhamento de carpintaria, imagens fotorealistas e quantitativos para orçamentos e obras." : "Our interior projects are detailed and complete, so that the execution of works, assemblies and carpentry are perfect in your space! We have worked from the layout of the environments, going through the specifications of finishes, dishes and metals and loose furniture; Electrical and hydraulic points, luminotheric, carpentry detail, photoreal and quantitative images for budgets and works."}
              </Typography>
              <Container sx={{
                minWidth: 300,
                width: "95%",
                maxWidth: 900,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                alignSelf: "center",
                justifyContent: "center",
                gap: "0.5rem",
              }}>
                {/* <Typography fontSize={24} textAlign={"center"} color={"#51787D"}>
                  {selectedLanguage === "pt" ? "Projetos Residenciais De Interiores" : "Projects Residential Interior"}
                </Typography> */}
                <br></br>
                <br></br>
                <Typography fontSize={18} textAlign={"center"} color={"#545454"}>
                  {t("Step by step process")}
                </Typography>
                <BoxWithIconsInterior />
              </Container>
              <div className="BoxButton">
              <a
                  style={{
                    display: "flex",
                    width: "300px",
                    height: "50px",
                    padding: "8px 16px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "8px",
                    flexShrink: "0px",
                    borderRadius: "12px",
                    border: "none",
                    transition: "1s ease",
                    textDecoration: "none",
                    background:
                      "var(--token-colors-status-interaction-elements, #51787d)",
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor =
                      "var(--slot-colors-brand-colors-base-slot-1, #84A8A9)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor =
                      "var(--token-colors-status-interaction-elements, #51787d)";
                  }}
                  href="/blog/ResidentialsInterior"
                >
                  <Text_Regular_14 value={selectedLanguage === "pt" ? "Conheça Nossos Projetos" : "Get to know our projects"} isSelected={true} />
                </a>
              </div>
            </Container>
          </div>
        )
      )}
    </>
  );
}
