import React from "react";
import AboutUsMobileTemplate from "../templates/AboutUsMobileTemplate";
const AboutUsPageMobile = () => {
  return (
    <div style={{ overflow: "hidden" }}>
      <AboutUsMobileTemplate />
    </div>
  );
};

export default AboutUsPageMobile;
