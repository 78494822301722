import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
import { v4 as uuid } from 'uuid';
const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
const UploadImages = ({ onDataReceived, setImagesList, setWord }) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);

    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
            console.log('Converted image to base64:', file.preview);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const handleChange = ({ fileList: newFileList }) => {
        var listAdd = {
            uid: uuid,
            name: "",
            status: '',
            url: ''
        }
        setFileList([...newFileList], listAdd);
        onDataReceived(fileList);
    };

    useEffect(() => {
        console.log(fileList);
        var uris = []
        fileList.forEach(element => {
            console.log(element?.response?.files[0]?.url);
            uris.push(element?.response?.files[0]?.url)
        });
        setImagesList(uris);
    }, [fileList]);

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Carregar 
            </div>
        </div>
    );
    return (
        <>
            <Upload
                action="https://boho-casas-api.azurewebsites.net/project/add-image"
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                multiple

            >
                {uploadButton}
                
            </Upload>

            <Modal

                open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img

                    style={{
                        width: '10%',
                    }}
                    src={previewImage}
                />

            </Modal>
        </>
    );
};
export default UploadImages;