import React from "react";
import { Button, ConfigProvider, Modal, Select, Slider } from "antd";
import { useTranslation } from "react-i18next";
export default function RangeForCalculator({
  typographicValue,
  onDataReceived,
}) {
  const { t } = useTranslation();
  const marksForT0 = {
    35: {
      style: {
        color: "#556F70",
      },
      label: (
        <div
          style={{
            width: "120px",
            background: "#EDFDF8",
            fontSize: "12px",
            marginTop: "5px",
          }}
        >
          <strong>35 m² {t("lowest")}</strong>
        </div>
      ),
    },
    43: " ",
    51: " ",
    59: " ",
    67: " ",
    75: " ",
    83: " ",
    91: {
      style: {
        color: "#556F70",
      },
      label: (
        <div
          style={{
            width: "120px",
            background: "#EDFDF8",
            fontSize: "12px",
            marginTop: "5px",
          }}
        >
          <strong>91 m² pretendido</strong>
        </div>
      ),
    },
  };
  const marksForT1 = {
    52: {
      style: {
        color: "#556F70",
      },
      label: (
        <div
          style={{
            width: "120px",
            background: "#EDFDF8",
            fontSize: "12px",
            marginTop: "5px",
          }}
        >
          <strong>52 m² {t("lowest")}</strong>
        </div>
      ),
    },
    60: " ",
    68: " ",
    76: " ",
    84: " ",
    92: " ",
    100: " ",
    108: {
      style: {
        color: "#556F70",
      },
      label: (
        <div
          style={{
            width: "120px",
            background: "#EDFDF8",
            fontSize: "12px",
            marginTop: "5px",
          }}
        >
          <strong>108 m² pretendido</strong>
        </div>
      ),
    },
  };
  const marksForT2 = {
    72: {
      style: {
        color: "#556F70",
      },
      label: (
        <div
          style={{
            width: "120px",
            background: "#EDFDF8",
            fontSize: "12px",
            marginTop: "5px",
          }}
        >
          <strong>72 m² {t("lowest")}</strong>
        </div>
      ),
    },
    80: " ",
    88: " ",
    96: " ",
    104: " ",
    112: " ",
    120: " ",
    128: {
      style: {
        color: "#556F70",
      },
      label: (
        <div
          style={{
            width: "120px",
            background: "#EDFDF8",
            fontSize: "12px",
            marginTop: "5px",
          }}
        >
          <strong>128 m² pretendido</strong>
        </div>
      ),
    },
  };
  const marksForT3 = {
    91: {
      style: {
        color: "#556F70",
      },
      label: (
        <div
          style={{
            width: "120px",
            background: "#EDFDF8",
            fontSize: "12px",
            marginTop: "5px",
          }}
        >
          <strong>91 m² {t("lowest")}</strong>
        </div>
      ),
    },
    99: " ",
    107: " ",
    115: " ",
    123: " ",
    131: " ",
    139: " ",
    147: {
      style: {
        color: "#556F70",
      },
      label: (
        <div
          style={{
            width: "120px",
            background: "#EDFDF8",
            fontSize: "12px",
            marginTop: "5px",
          }}
        >
          <strong>147 m² pretendido</strong>
        </div>
      ),
    },
  };
  const marksForT4 = {
    105: {
      style: {
        color: "#556F70",
      },
      label: (
        <div
          style={{
            width: "120px",
            background: "#EDFDF8",
            fontSize: "12px",
            marginTop: "5px",
          }}
        >
          <strong>105 m² {t("lowest")}</strong>
        </div>
      ),
    },
    113: " ",
    121: " ",
    129: " ",
    137: " ",
    145: " ",
    153: " ",
    161: {
      style: {
        color: "#556F70",
      },
      label: (
        <div
          style={{
            width: "120px",
            background: "#EDFDF8",
            fontSize: "12px",
            marginTop: "5px",
          }}
        >
          <strong>161 m² pretendido</strong>
        </div>
      ),
    },
  };
  const marksForT5 = {
    122: {
      style: {
        color: "#556F70",
      },
      label: (
        <div
          style={{
            width: "120px",
            background: "#EDFDF8",
            fontSize: "12px",
            marginTop: "5px",
          }}
        >
          <strong>122 m² {t("lowest")}</strong>
        </div>
      ),
    },
    130: " ",
    138: " ",
    146: " ",
    154: " ",
    162: " ",
    170: " ",
    178: {
      style: {
        color: "#556F70",
      },
      label: (
        <div
          style={{
            width: "120px",
            background: "#EDFDF8",
            fontSize: "12px",
            marginTop: "5px",
          }}
        >
          <strong>178 m² pretendido</strong>
        </div>
      ),
    },
  };
  const marksForT6 = {
    134: {
      style: {
        color: "#556F70",
      },
      label: (
        <div
          style={{
            width: "120px",
            background: "#EDFDF8",
            fontSize: "12px",
            marginTop: "5px",
          }}
        >
          <strong>134 m² {t("lowest")}</strong>
        </div>
      ),
    },
    142: " ",
    150: " ",
    158: " ",
    166: " ",
    174: " ",
    182: " ",
    190: {
      style: {
        color: "#556F70",
      },
      label: (
        <div
          style={{
            width: "120px",
            background: "#EDFDF8",
            fontSize: "12px",
            marginTop: "5px",
          }}
        >
          <strong>190 m² pretendido</strong>
        </div>
      ),
    },
  };
  switch (typographicValue) {
    case t("modal") + " T0":
      return (
        <>
          <ConfigProvider
            theme={{
              components: {
                Slider: {
                  dotActiveBorderColor: "#648586",
                  dotBorderColor: "#EDFDF8",
                  handleActiveColor: "#648586",
                  handleColor: "#648586",
                  railBg: "#EDFDF8",
                  trackBg: "#EDFDF8",
                  trackHoverBg: "#648586",
                  trackBgDisabled: "#648586",
                },
              },
              token: {
                colorBgContainer: "#648586",
                colorPrimary: "#648586",
              },
            }}
          >
            <Slider
              marks={marksForT0}
              max={91}
              min={35}
              step={8}
              onChange={onDataReceived}
              tooltip={{ open: false }}
              trackStyle={{ background: "#648586" }}
            />
          </ConfigProvider>
        </>
      );
      break;
    case t("modal") + " T1":
      return (
        <>
          <ConfigProvider
            theme={{
              components: {
                Slider: {
                  dotActiveBorderColor: "#648586",
                  dotBorderColor: "#EDFDF8",
                  handleActiveColor: "#648586",
                  handleColor: "#648586",
                  railBg: "#EDFDF8",
                  trackBg: "#EDFDF8",
                  trackHoverBg: "#648586",
                  trackBgDisabled: "#648586",
                },
              },
              token: {
                colorBgContainer: "#648586",
                colorPrimary: "#648586",
              },
            }}
          >
            <Slider
              marks={marksForT1}
              max={108}
              min={52}
              step={8}
              onChange={onDataReceived}
              tooltip={{ open: false }}
              trackStyle={{ background: "#648586" }}
            />
          </ConfigProvider>
        </>
      );
      break;
    case t("modal") + " T2":
      return (
        <>
          <ConfigProvider
            theme={{
              components: {
                Slider: {
                  dotActiveBorderColor: "#648586",
                  dotBorderColor: "#EDFDF8",
                  handleActiveColor: "#648586",
                  handleColor: "#648586",
                  railBg: "#EDFDF8",
                  trackBg: "#EDFDF8",
                  trackHoverBg: "#648586",
                  trackBgDisabled: "#648586",
                },
              },
              token: {
                colorBgContainer: "#648586",
                colorPrimary: "#648586",
              },
            }}
          >
            <Slider
              marks={marksForT2}
              max={128}
              min={72}
              step={8}
              onChange={onDataReceived}
              tooltip={{ open: false }}
              trackStyle={{ background: "#648586" }}
            />
          </ConfigProvider>
        </>
      );
      break;
    case t("modal") + " T3":
      return (
        <>
          <ConfigProvider
            theme={{
              components: {
                Slider: {
                  dotActiveBorderColor: "#648586",
                  dotBorderColor: "#EDFDF8",
                  handleActiveColor: "#648586",
                  handleColor: "#648586",
                  railBg: "#EDFDF8",
                  trackBg: "#EDFDF8",
                  trackHoverBg: "#648586",
                  trackBgDisabled: "#648586",
                },
              },
              token: {
                colorBgContainer: "#648586",
                colorPrimary: "#648586",
              },
            }}
          >
            <Slider
              marks={marksForT3}
              max={147}
              min={91}
              step={8}
              onChange={onDataReceived}
              tooltip={{ open: false }}
              trackStyle={{ background: "#648586" }}
            />
          </ConfigProvider>
        </>
      );
      break;
    case t("modal") + " T4":
      return (
        <>
          <ConfigProvider
            theme={{
              components: {
                Slider: {
                  dotActiveBorderColor: "#648586",
                  dotBorderColor: "#EDFDF8",
                  handleActiveColor: "#648586",
                  handleColor: "#648586",
                  railBg: "#EDFDF8",
                  trackBg: "#EDFDF8",
                  trackHoverBg: "#648586",
                  trackBgDisabled: "#648586",
                },
              },
              token: {
                colorBgContainer: "#648586",
                colorPrimary: "#648586",
              },
            }}
          >
            <Slider
              marks={marksForT4}
              max={161}
              min={105}
              step={8}
              onChange={onDataReceived}
              tooltip={{ open: false }}
              trackStyle={{ background: "#648586" }}
            />
          </ConfigProvider>
        </>
      );
      break;
    case t("modal") + " T5":
      return (
        <>
          <ConfigProvider
            theme={{
              components: {
                Slider: {
                  dotActiveBorderColor: "#648586",
                  dotBorderColor: "#EDFDF8",
                  handleActiveColor: "#648586",
                  handleColor: "#648586",
                  railBg: "#EDFDF8",
                  trackBg: "#EDFDF8",
                  trackHoverBg: "#648586",
                  trackBgDisabled: "#648586",
                },
              },
              token: {
                colorBgContainer: "#648586",
                colorPrimary: "#648586",
              },
            }}
          >
            <Slider
              marks={marksForT5}
              max={178}
              min={122}
              step={8}
              onChange={onDataReceived}
              tooltip={{ open: false }}
              trackStyle={{ background: "#648586" }}
            />
          </ConfigProvider>
        </>
      );
      break;
    case t("modal") + " T6":
      return (
        <>
          <ConfigProvider
            theme={{
              components: {
                Slider: {
                  dotActiveBorderColor: "#648586",
                  dotBorderColor: "#EDFDF8",
                  handleActiveColor: "#648586",
                  handleColor: "#648586",
                  railBg: "#EDFDF8",
                  trackBg: "#EDFDF8",
                  trackHoverBg: "#648586",
                  trackBgDisabled: "#648586",
                },
              },
              token: {
                colorBgContainer: "#648586",
                colorPrimary: "#648586",
              },
            }}
          >
            <Slider
              marks={marksForT6}
              max={190}
              min={134}
              step={8}
              onChange={onDataReceived}
              tooltip={{ open: false }}
              trackStyle={{ background: "#648586" }}
            />
          </ConfigProvider>
        </>
      );
      break;
    default:
      break;
  }
}
