import React from "react";
import Section_Contact_Us from "../../components/organisms/SectionContactUs";
import GoogleMap from "../../components/organisms/MapContainer";
import Footer from "../../components/organisms/Footer";
import SliderModelCommercialProjectsAndFranchisesMobile from "../../components/organisms/SliderModelCommercialProjectsAndFranchisesMobile";
import SectionHeroCommercialProjectsAndFranchisesMobile from "../../components/organisms/SectionHeroCommercialProjectsAndFranchisesMobile";
import CardsRelatedProjectsCommercialAndFranchieses from "../../components/molecules/CardsRelatedProjectsCommercialAndFranchieses";

export default function CommercialProjectsAndFranchisesPageMobileTemplate() {
  return (
    <div style={{ overflow: "hidden" }}>
      <SectionHeroCommercialProjectsAndFranchisesMobile />
      <SliderModelCommercialProjectsAndFranchisesMobile customColor="#F2F2F2" />
      <CardsRelatedProjectsCommercialAndFranchieses backgroundColor={"#F2F2F2"} />
      <Section_Contact_Us />
      <div style={{ height: "100vw", width: "100vw" }}>
        <GoogleMap />
      </div>
      <Footer />
    </div>
  );
}
