import AboutUs from "../../components/organisms/AboutUs";
import Section_Contact_Us from "../../components/organisms/SectionContactUs";
import SectionHeroAboutUs from "../../components/organisms/SectionHeroAboutUs";
import Section_Our_Story from "../../components/organisms/Section_Our_Story";
import GoogleMap from "../../components/organisms/MapContainer";
import Footer from "../../components/organisms/Footer";
export default function AboutUsMobileTemplate() {
  return (
    <div style={{ overflow: "hidden" }}>
      <SectionHeroAboutUs />
      <AboutUs />
      <Section_Our_Story />
      <Section_Contact_Us />
      <div style={{ height: "100vw", width: "100vw" }}>
        <GoogleMap />
      </div>
      <Footer />
    </div>
  );
}
