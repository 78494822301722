import React from "react";
import styles from "./Section_Commercial_Projects_And_Franchises.module.css";
import InfiniteScrollComponent from "../../molecules/Slider_Projects";
import Header_Description_Type_Project from "../../molecules/HeaderDescriptionTypeProject";
import { useTranslation } from "react-i18next";
import { PageHead } from "./../../atoms/PageHead/index";
import AccessAreaMenuBig from "../../atoms/AccessAreaMenuBig";
import { useLanguage } from "../../../utils/translations/LanguageContext";
import checkIsMobile from "../../../utils/checkIsMobile";
export default function Section_Commercial_Projects_And_Franchises() {
  const { selectedLanguage } = useLanguage();

  const { t } = useTranslation();

  const [projects] = React.useState([
    {
      _id: "5f9d5f1b9d6f6e0017f7e3a2",
      name:
        selectedLanguage === "pt"
          ? "Projetos de Interiores e fachadas das lojas da Franquia de Açaí OAKBERRY"
          : "Interior projects and store facades of the OAKBERRY Açaí Franchise",
      description:
        selectedLanguage === "pt" ? "FRANQUIA OAKBERRY" : "OAKBERRY FRANCHISE",
      images: [
        "https://bohocasasstorage.blob.core.windows.net/boho-casas/DESTAQUE2.png",
      ],
    },
    {
      _id: "5f9d5f1b9d6f6e0017f7e3a1",
      name:
        selectedLanguage === "pt"
          ? "Projeto de interiores para uma loja de doces diversos."
          : "Interior design for a various sweets store.",
      description:
        selectedLanguage === "pt" ? "DOCERIA CHOCOARTE" : "CHOCOARTE SWEETSHIP",
      images: [
        "https://bohocasasstorage.blob.core.windows.net/boho-casas/DESTAQUE1.png",
      ],
    },
    {
      _id: "5f9d5f1b9d6f6e0017f7e3a3",
      name:
        selectedLanguage === "pt"
          ? "Projetos de Interiores e fachadas dos restaurantes OUTBECO"
          : "Interior projects and facades of OUTBECO restaurants",
      description:
        selectedLanguage === "pt" ? "FRANQUIA OUTBECO" : "OUTBECO FRANCHISE",
      images: [
        "https://bohocasasstorage.blob.core.windows.net/boho-casas/DESTAQUE3.png",
      ],
    },
  ]);
  return (
    <>
      {!checkIsMobile() ? (
        <div
          className={styles.ContainerSection}
          id="Commercial_Projects_And_Franchises"
        >
          <div className={styles.Pagehead}>
            <div>
              <PageHead
                text={t("WHAT WE'VE ALREADY DONE")}
                text1={t("Commercial Projects and Franchises")}
              ></PageHead>
            </div>
            <div>
              <AccessAreaMenuBig
                className="access-area-menu-big-instance"
                frameClassName="design-component-instance-node"
                text={t("See Projects")}
                goTo={"Commercial_Projects_And_Franchises"}
              />
            </div>
          </div>
          <InfiniteScrollComponent
            listOfProjects={projects}
            backgroundColor={"#F2F2F2"}
          />
        </div>
      ) : (
        <div
          className={styles.ContainerSectionMobile}
          id="Commercial_Projects_And_Franchises"
        >
          <Header_Description_Type_Project
            title={t("WHAT WE'VE ALREADY DONE")}
            subtitle={t("Commercial Projects and Franchises")}
          />
          <InfiniteScrollComponent
            listOfProjects={projects}
            backgroundColor={"#F2F2F2"}
          />
          <div>
            <AccessAreaMenuBig
              className="access-area-menu-big-instance"
              frameClassName="design-component-instance-node"
              text={t("See Projects")}
              goTo={"Commercial_Projects_And_Franchises"}
            />
          </div>
        </div>
      )}
    </>
  );
}
