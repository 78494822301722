import React from 'react'
import Section_Contact_Us from '../../components/organisms/SectionContactUs'
import GoogleMap from '../../components/organisms/MapContainer'
import FooterWeb from '../../components/organisms/FooterWeb/FooterWeb'
import SectionHeroRemodeling from '../../components/organisms/SectionHeroRemodeling'
import CardsRelatedProjectsRemodeling from '../../components/molecules/CardsRelatedProjectsRemodeling'
import SectionHeroRemodelingMobile from '../../components/organisms/SectionHeroRemodelingMobile'
import Footer from '../../components/organisms/Footer'
import SliderModelRenovationsMobile from '../../components/organisms/SliderModelRenovationsMobile'
/**
 * Renders the Projects Remodeling Page template.
 *
 * @returns {JSX.Element} The rendered component.
 */
export default function ProjectsRemodelingPageMobileTemplate() {
    return (
        <div style={{overflow:"hidden"}}>
            <SectionHeroRemodelingMobile />
            <SliderModelRenovationsMobile customColor={"#FFFFFF"} />
            <CardsRelatedProjectsRemodeling backgroundColor={"#FFFFFF"} />
            <Section_Contact_Us />
            <div style={{ height: "100vw", width: "100vw" }}>
                <GoogleMap />
            </div>
            <Footer />
        </div>
    )
}
