import React from "react";
import FaceSvg from "./Button_Social_Access.svg";

const BTNFacebookGreen4Mobile = () => {
  return (
    <a
      href="https://www.facebook.com/bohocasas"
      style={{ paddingBottom: "30px" }}
    >
      <img src={FaceSvg} />
    </a>
  );
};

export default BTNFacebookGreen4Mobile;
