import React from 'react'
import styles from './Text_Regular_14.module.css'

export default function Text_Regular_14({
    value,
    isSelected,
    textColor
}) {
    return (
        <>
            <p
                className={styles.text14}
                style={{
                    opacity: isSelected ? 1 : .5,
                    color: textColor ? textColor : 'white'                  
                }}>
                {value}
            </p>
        </>

    )
}