import styled from "styled-components";

/////////////////////////////////////////////////////////MOBILE ^//////////////////////////
export const ContainerMobile = styled.div`
  padding: 25px;
  background-color: #F2F2F2;
`;

export const ContainerPart1Mobile = styled.div`
  margin-top: 4rem;
`;
export const ContainerPart2Mobile = styled.div``;

export const ContainerPart1Column1Mobile = styled.div``;

export const ContainerPart1Column2Mobile = styled.div``;

export const ContainerPart2Column1Mobile = styled.div``;
export const ContainerPart2Column2Mobile = styled.div``;

export const LabelMobile = styled.label`
  display: flex;
  flex-direction: column;
`;

export const LabelTitleMobile = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: var(--token-colors-status-interaction-elements, #648586);
  font-family: Maven Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  gap: 1rem;
`;
export const LabelSubTitleMobile = styled.label`
  font-size: 10vw;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 20px;
`;

export const Label2Mobile = styled.label``;

export const LabelTitle2Mobile = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: var(--token-colors-status-interaction-elements, #648586);
  font-family: Maven Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  gap: 1rem;
`;
export const LabelSubTitle2Mobile = styled.label`
  font-size: 10vw;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 20px;
`;

export const ParagraphsMobile = styled.div`
  margin-bottom: 60px;
`;

export const ParagraphMobile = styled.h6`
  margin-top: 20px;
  font-size: 20px;
  font-weight: 100;
`;

export const Paragraphs2Mobile = styled.h6`
  margin-bottom: 60px;
`;

export const Paragraph2Mobile = styled.h6`
  margin-top: 20px;
  font-size: 20px;
  font-weight: 100;
`;

/////////////////////////////////////////////////////////MOBILE ^//////////////////////////
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding-top: 90px;
  padding-bottom: 137px;
  height: auto;
  background-color: #F2F2F2;
`;

export const ContainerPart1 = styled.div`
  display: flex;
  width: 90%;
  height: 800px;
`;
export const ContainerPart2 = styled.div`
  display: flex;
  width: 90%;
  height: 600px;
`;

export const ContainerPart1Column1 = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
`;

export const ContainerPart1Column2 = styled.div`
  display: flex;
  width: 50%;
  display: flex;
  height: 749.673px;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
`;

export const ContainerPart2Column1 = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
`;
export const ContainerPart2Column2 = styled.div`
  display: flex;
  margin-top: 50px;
  margin-left: 150px;
  flex-direction: column;
  width: 50%;
  height: 100%;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  width: 456px;
  height: 80px;
  flex-shrink: 0;
`;

export const LabelTitle = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  color: var(--token-colors-status-interaction-elements, #648586);
  font-family: Maven Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
  text-transform: uppercase;
`;
export const LabelSubTitle = styled.label`
  color: var(--token-colors-text-plain-text-light, #545454);
  padding-left: 20px;
  font-family: Maven Pro;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 120% */
`;

export const Label2 = styled.label`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80px;
  flex-shrink: 0;
`;

export const LabelTitle2 = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--token-colors-status-interaction-elements, #648586);
  font-family: Maven Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
  text-transform: uppercase;
`;
export const LabelSubTitle2 = styled.label`
  color: var(--token-colors-text-plain-text-light, #545454);
  width: 100%;
  font-family: Maven Pro;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
`;

export const Paragraphs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-top: 40px;
  gap: 50px;
`;

export const Paragraph = styled.h6`
  color: var(--token-colors-text-plain-text-light, #545454);
  font-family: Maven Pro;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`;

export const Paragraphs2 = styled.h6`
  display: flex;
  padding-top: 50px;
  flex-direction: column;
  color: var(--token-colors-text-plain-text-light, #545454);
  font-family: Maven Pro;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  gap: 50px;
`;

export const Paragraph2 = styled.h6`
  color: var(--token-colors-text-plain-text-light, #545454);
  font-family: Maven Pro;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`;
