import React, { useEffect, useState } from "react";
import GoogleMap from "../../components/organisms/MapContainer";
import FooterWeb from "../../components/organisms/FooterWeb/FooterWeb";
import Section_Contact_Us from "../../components/organisms/SectionContactUs";
import CardBlogsAllProjects from "../../components/organisms/CardBlogsAllProjects/CardBlogsAllProjects";
import SectionHeroBlog from "../../components/organisms/SectionHeroBlog";
import NavBarWeb from "../../components/molecules/Navbar/NavBarWeb";
import Header from "../../components/organisms/Header";
import Footer from "../../components/organisms/Footer";
import checkIsMobile from "../../utils/checkIsMobile";
export default function BlogPageTemplate() {
  const [isMobile, setIsMobile] = useState();
  useEffect(() => {
    var detectIsMobile = checkIsMobile();
    setIsMobile(detectIsMobile);
  }, []);
  return (
    <div
      style={{
        margin: 0,
        padding: 0,
        display: "flex",
        flexDirection: "column",
        maxWidth: "100vw",
        width: "100vw",
        overflow: "hidden"
      }}
    >
      <div style={{ backgroundColor: "#51787d", margin: 0 }}>
        <Header />
      </div>

      <CardBlogsAllProjects id="#Blog" />
      <Section_Contact_Us />
      <div style={{ height: isMobile ? "100vw" : "627px", width: "100vw" }}>
        <GoogleMap />
      </div>
      {isMobile ? <Footer /> : <FooterWeb />}

    </div>
  );
}
