import Section_Home_Mobile from "../../components/organisms/HeroSectionServicesMobile";
import GoogleMap from "../../components/organisms/MapContainer";
import Footer from "../../components/organisms/Footer";
import ProjectsResidentialArchitectureSpecialties from "../../components/organisms/ProjectsResidentialArchitectureSpecialties";
import ProjectsResidentialInterior from "../../components/organisms/ProjectsResidentialInterior";
import SectionDifferentialsCommercialProjectsAndFranchises from "../../components/organisms/SectionDifferentialsCommercialProjectsAndFranchises";
import SectionDifferentialsLSF from "../../components/organisms/SectionDifferentialsLSF";
import SectionDifferentialsRemodeling from "../../components/organisms/SectionDifferentialsRemodeling";
import Section_Contact_Us from "../../components/organisms/SectionContactUs";

export default function ServicesMobileTemplate() {
  return (
    <div style={{ overflow: "hidden" }}>
      <Section_Home_Mobile />
      <ProjectsResidentialArchitectureSpecialties />
      <ProjectsResidentialInterior />
      <SectionDifferentialsCommercialProjectsAndFranchises />
			<SectionDifferentialsLSF />
      <SectionDifferentialsRemodeling />
      <Section_Contact_Us />
      <div style={{ height: "100vw", width: "100vw" }}>
        <GoogleMap />
      </div>
      <Footer />
    </div>
  );
}
