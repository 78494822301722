import React from "react";
import Header from "../Header";
import SemiBoldLabel from "../../atoms/TextStyleSemiBold/SemiBoldLabel";
import RegularH1 from "../../atoms/TextStyleRegular/RegularH1";
import Aside_Bar_Social_Media from "../../molecules/Aside_Bar_Social_Media";
import { useTranslation } from "react-i18next";
import "./SectionHeroProjectsLSFPage.css";

export default function SectionHeroProjectsLSFPageMobile() {
  const { t } = useTranslation();

  return (
    <div className="ContainerSectionHeroMobile">
      <Header />
      <div className="filter"></div>
      <div className="SectionHeroBodyMobile">
        <div className="TextSectionHeroBodyMobile">
          <SemiBoldLabel value={"BohoCasas"} isSelected={true} />
          <RegularH1
            value={t("lsfProjects")}
            isSelected={true}
            textColor={"var(--token-colors-text-plain-text-dark, #FFF)"}
          />
          <p>
            {t(
              "Explore our category of architectural projects at Bohocasas, which features a wide diversity of work built using LSF - Light Steel Framing. Our expert team has experience in this modern and innovative constructive method, which uses light steel to create durable, efficient spaces and sustainable"
            )}
          </p>
        </div>
      </div>
    </div>
  );
}
