import React from "react";
import InstSvg from "./Button_Social_Access.svg"
import { Link } from "react-router-dom";

const BTNInstagramGreen4Mobile = () => {
	return (
		<Link
			to="https://www.instagram.com/bohocasas/"
			style={{ paddingBottom: "30px" }}
		>
			<img src={InstSvg} />
		</Link>
	);
};

export default BTNInstagramGreen4Mobile;
