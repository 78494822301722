import React from "react";
import NavBarWeb from "../../molecules/Navbar/NavBarWeb.js";
import Navbar from "../../molecules/Navbar/";
import Logo from "../../atoms/Logo/index";
import BTNSendMessage from "../../atoms/BTNSendMessage/index";
import LanguageToggle from "../../../utils/translations/LanguageToggle";
import styles from "./Header.module.css";
import checkIsMobile from "../../../utils/checkIsMobile";
import ButtonWhatsApp from "../../atoms/BTNSendMessage";

/**
 * Header Component
 *
 * This component represents the header section of the application.
 * It includes the logo, navigation bar, a button to send messages,
 * and a language toggle for internationalization.
 *
 * @component
 *
 * @returns {JSX.Element} The rendered Header component.
 *
 * @example
 * // Usage
 * <Header />
 *
 * @author Bruno Paulon
 */
export default function Header() {
  /**
   * Renders the header section with the logo, navigation bar, message button, and language toggle.
   *
   * @returns {JSX.Element} The rendered header section.
   */
  return (
    <>
      {!checkIsMobile() ? (
        <>
          <header className={styles.header}>
            <div className={styles.firstColm}>
              <Logo /> {/* Render the application logo */}
            </div>
            <div className={styles.secondColm}>
              <NavBarWeb /> {/* Render the navigation bar */}
            </div>
            <div className={styles.thirdColm}>
              <BTNSendMessage />
              <LanguageToggle />
            </div>
          </header>
          <div className={styles.lineHorizontal}></div>
        </>
      ) : (
        <>
          <header className={styles.headerMobile}>
            <div className={styles.headerItem1Mobile}>
              <Logo />
            </div>
            <div className={styles.headerItem2Mobile}>
              <ButtonWhatsApp />
            </div>
            <div className={styles.headerItem3Mobile}>
              <Navbar />
            </div>
          </header>
        </>
      )}
    </>
  );
}
