import React, { useEffect, useState } from 'react';
import Logo from '../../atoms/Logo'
import { Menu, ConfigProvider, Button } from 'antd';
import {
    PlusOutlined,
    ReadOutlined,
} from '@ant-design/icons';

import CreateProject from '../../atoms/CreateProject';
import ReadProjects from '../../atoms/ReadProjects';
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { LeaderboardOutlined, LogoutOutlined } from '@mui/icons-material';
import LeaderBoard from '../../atoms/Leaderboard/Leaderboard';

export default function BlogDashboard() {
    const [selectedKey, setSelectedKey] = useState('create');
    const userData = JSON.parse(localStorage.getItem("userData"));
    const userDataCookie = Cookies.get("userData");
    const navigate = useNavigate();
    useEffect(() => {
        if (!userData && !userDataCookie) {
            navigate("/login")
        } else if (!userData || !userDataCookie) {
            navigate("/login")
        }
    }, [userData, userDataCookie])

    const renderComponent = () => {
        switch (selectedKey) {
            case 'create':
                return <CreateProject />;

            case 'read':
                return <ReadProjects />;

            case 'leads':
                return <LeaderBoard />

            default:
                return <LeaderBoard />
        }
    };

    return (
        <>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100vw',
                backgroundColor: '#51787d',
                padding: '20px'
            }}>
                <Logo />
                <ConfigProvider
                    theme={{
                        components: {
                            Menu: {
                                horizontalItemSelectedColor: '#fff'
                            },
                        },
                    }}
                >

                    <Menu mode="horizontal" selectedKeys={selectedKey} onSelect={({ key }) => setSelectedKey(key)} style={{ backgroundColor: 'transparent', }}>
                        <Menu.Item key="create" icon={<PlusOutlined />} style={{ marginRight: '20px', color: '#fff' }}>
                            <span style={{
                                fontFamily: 'var(--text-style-bold-label-font-family)',
                                fontSize: '24px',
                                color: '#fff'
                            }}>
                                Crie um novo projeto
                            </span>
                        </Menu.Item>
                        <Menu.Item key="read" icon={<ReadOutlined />} style={{ marginRight: '20px', color: '#fff' }}>
                            <span style={{
                                fontFamily: 'var(--text-style-bold-label-font-family)',
                                fontSize: '24px',
                                color: '#fff'
                            }}>
                                Veja todos os projetos
                            </span>
                        </Menu.Item>
                        <Menu.Item key="leads" icon={<LeaderboardOutlined />} style={{ marginRight: '20px', color: '#fff' }}>
                            <span style={{
                                fontFamily: 'var(--text-style-bold-label-font-family)',
                                fontSize: '24px',
                                color: '#fff'
                            }}>
                                Leads
                            </span>
                        </Menu.Item>
                        <Menu.Item key="logout" icon={<LogoutOutlined />} style={{ marginRight: '20px', color: '#fff' }}>
                            <span style={{
                                fontFamily: 'var(--text-style-bold-label-font-family)',
                                fontSize: '24px',
                                color: '#fff'
                            }}
                                onClick={() => {
                                    Cookies.remove("userData");
                                    localStorage.removeItem("userData");
                                    navigate("/login")
                                }}
                            >
                                Sair
                            </span>
                        </Menu.Item>
                    </Menu>
                </ConfigProvider>

            </div>
            <div style={{
                paddingTop: '50px',
                background: '#F2F2F2',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                width: "100vw",
                height: "100vh",
            }}>
                {renderComponent()}
            </div >
        </>
    );
}