import PropTypes from "prop-types";
import React from "react";
import "./PageHead.css";

export const PageHead = ({
	text = "O QUE JÁ FIZEMOS",
	divClassName,
	text1 = "Projetos Residenciais",
	styles,
}) => {
	return (
		<div className="page-head" style={styles}>
			{
				text != "" && (
					<div className="box1">
						<div className="rectangle" />
						<div className="o-QUE-j-FIZEMOS">{text}</div>
					</div>
				)
			}
			<div className="box2">
				<div className={`projetos ${divClassName}`}>{text1}</div>
			</div>
		</div>
	);
};

PageHead.propTypes = {
	text: PropTypes.string,
	text1: PropTypes.string,
	styles: PropTypes.object,
};