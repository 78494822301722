
import React from "react";
import styles from "./RegularH1.module.css";

export default function RegularH1({ value, isSelected, textColor, fontSize }) {
	const textColorStyle = textColor || styles.defaultTextColor;

	return (
		<>
			<p
				className={styles.RegularH1}
				style={{
					opacity: isSelected ? 1 : 0.5,
					color: textColorStyle,
					fontSize: fontSize ? fontSize : "1.5rem"
				}}
			>
				{value}
			</p>
		</>
	);
}
