import React from 'react'
import DetailsProjectsPageTemplate from '../templates/DetailsProjectsPageTemplate'
const DetailsProjectsPage = () => {
    return (
        <div>
            <DetailsProjectsPageTemplate />
        </div>
    )
}

export default DetailsProjectsPage
