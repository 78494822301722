import React from "react";
import styles from "./Our_Services_Section.module.css";
import Card_services from "../../molecules/Card_services";
import { useTranslation } from "react-i18next";
import checkIsMobile from "../../../utils/checkIsMobile";
const Our_Services_Section = () => {
  const { t } = useTranslation();
  return (
    <>
      {!checkIsMobile() ? (
        <div className={styles.services} id="second-section">
          <span className={styles.offertext}>{t("offerText")}</span>
          <span className={styles.textOurServices}>{t("textOurServices")}</span>
          <span className={styles.textDescription}>{t("textDescription")}</span>
          <Card_services />
        </div>
      ) : (
        <div className={styles.servicesMobile} id="second-section">
          <span className={styles.offertextMobile}>
            <strong>{t("offerText")}</strong>
          </span>

          <div className={styles.ourServicesContainer}>
            <span className={styles.textOurServicesMobile}>
              {t("textOurServices")}
            </span>
            <span className={styles.textDescriptionMobile}>
              {t("textDescription")}
            </span>
          </div>
          <Card_services />
        </div>
      )}
    </>
  );
};

export default Our_Services_Section;
