import React, { useRef, useState, useEffect } from "react";
import styles from "./CardsTagsRelatedProjects.module.css";
import HeaderDescriptionTypeProject from "../../molecules/HeaderDescriptionTypeProject";
import { useTranslation } from "react-i18next";
import ProjectButton from "../../atoms/ButtonSeeDetailsProjects";
import checkIsMobile from "../../../utils/checkIsMobile";
import Flicking from "@egjs/react-flicking";
import AccessAreaMenuBig from "../../atoms/AccessAreaMenuBig";
import { ProjectService } from "boho-casas-client-module/client-web";
import { useLanguage } from "../../../utils/translations/LanguageContext";
import { useNavigate } from "react-router-dom";


const CardsTagsRelatedProjects = ({ backgroundColor, newTags }) => {
    const navigate = useNavigate();
    const { selectedLanguage } = useLanguage();
    const [item, setItem] = useState([]);
    const { t } = useTranslation();
    const itemsPerPage = 3;
    const [currentPage, setCurrentPage] = useState(1);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = item.slice(startIndex, endIndex);
    const totalPages = Math.ceil(item.length / itemsPerPage);
    const generatePageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i < totalPages; i++) {
            pageNumbers.push(i);
        }
        return pageNumbers;
    };
    const pageNumbers = generatePageNumbers();
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const refFlipping = useRef(null);
    const shouldShowElement = currentPage > 4;

    useEffect(() => {
        ProjectService.returnAllProject().then((response) => {
            console.log(response.data)
            console.log(newTags)
            var filteredData = response.data.filter((itemProject) => {
                return newTags?.every((tag) => itemProject.tags.includes(tag));

            })
            console.log(filteredData)
            setItem(filteredData)
        })
    }, [newTags])
    return (
        <>
            {!checkIsMobile() ? (
                <div className={styles.rootContainer} >
                    <HeaderDescriptionTypeProject
                        title={t("textLabelSectionProjects")}
                        subtitle={t("textSectionProjectsRecentsLabel")}
                        backgroundColor={backgroundColor}
                        buttonTitle={t("textSeeAll")}
                        svg={true}
                        buttonAction={() => {
                            navigate("/projects-and-works");
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth'
                            });
                        }}
                    />
                    <div
                        className={styles.container}
                        style={{ backgroundColor: backgroundColor }}
                    >
                        {currentItems.map((project, index) => (
                            <div className={styles.Card} key={index}>
                                {/* <div className={styles.label}>
                                    <span className={styles.textLabel}>{project.service}</span>
                                </div> */}
                                <img
                                    className={styles.img}
                                    src={project.images[0]}
                                    alt={`Project ${index + 1}`}
                                />
                                <div className={styles.datePostAndLocation}>
                                    {/* <div>{new Date(project.created_project).toLocaleDateString()}</div> */}
                                    {selectedLanguage === 'pt' ? project.title_pt : project.title_en}
                                    <div>{project.location}</div>
                                </div>
                                {/* <div className={styles.titlePost}>
                                    {selectedLanguage === 'pt' ? project.title_pt : project.title_en}
                                </div> */}
                                <ProjectButton projectId={project._id.$oid} />
                            </div>
                        ))}
                    </div>
                    {totalPages > 1 && (
                        <div className={styles.pagination}>
                            <button
                                style={{
                                    background: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                }}
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="49"
                                    height="35"
                                    viewBox="0 0 49 35"
                                    fill="none"
                                >
                                    <rect
                                        x="0.5"
                                        y="0.5"
                                        width="34"
                                        height="34"
                                        rx="17"
                                        stroke={currentPage === 1 ? "#E1E6EF" : "#648586"}
                                    />
                                    <path
                                        d="M49 17.0551H23.8011V13.1988L19 18.0001L23.8011 22.8012V18.945H49V17.0551Z"
                                        fill={currentPage === 1 ? "#E1E6EF" : "#648586"}
                                    />
                                </svg>
                            </button>
                            {pageNumbers.map((pageNumber) => (
                                <button
                                    style={{
                                        background: "transparent",
                                        border: "none",
                                        cursor: "pointer",
                                        color: pageNumber === currentPage ? "black" : "#648586",
                                        transition: "0.5s all",
                                        fontFamily: "Maven Pro",
                                        fontSize: pageNumber === currentPage ? "20px" : "16px",
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        lineHeight: "24px",
                                    }}
                                    key={pageNumber}
                                    onClick={() => handlePageChange(pageNumber)}
                                >
                                    {pageNumber}
                                </button>
                            ))}
                            <div>
                                <span
                                    style={{
                                        background: "transparent",
                                        border: "none",
                                        cursor: "pointer",
                                        color: "#648586",
                                        fontFamily: "Maven Pro",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        lineHeight: "24px",
                                    }}
                                >
                                    . . .
                                </span>
                            </div>
                            <button
                                style={{
                                    background: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                    color: "#648586",
                                    fontFamily: "Maven Pro",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    lineHeight: "24px",
                                }}
                                onClick={() => handlePageChange(totalPages)}
                                disabled={currentPage === totalPages}
                            >
                                {totalPages}
                            </button>
                            <button
                                style={{
                                    background: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                }}
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                <svg
                                    width="49"
                                    height="35"
                                    viewBox="0 0 49 35"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        x="14.5"
                                        y="0.5"
                                        width="34"
                                        height="34"
                                        rx="17"
                                        stroke={currentPage === totalPages ? "#E1E6EF" : "#648586"}
                                    />
                                    <path
                                        d="M30 17.9999L25.1988 13.1987V17.055H0V18.9449H25.1988V22.8012L30 17.9999Z"
                                        fill={currentPage === totalPages ? "#E1E6EF" : "#648586"}
                                    />
                                </svg>
                            </button>
                        </div>
                    )}
                </div>
            ) : (
                <div
                    style={{
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: backgroundColor,
                    }}
                >
                    <HeaderDescriptionTypeProject
                        title={t("textLabelSectionProjects")}
                        subtitle={t("textSectionProjectsRecentsLabel")}
                    />

                    <Flicking
                        ref={refFlipping}
                        align="center"
                        style={{ width: "100vw" }}
                        circular={true}
                        interruptable={true}
                        onChanged={(e) => {
                            setCurrentImageIndex(e.currentTarget.index);
                        }}
                    >
                        {currentItems?.map((project, index) => (
                            <div className={styles.CardMobile} key={index}>

                                <div className={styles.labelMobile}>
                                    <span className={styles.textLabelMobile}>
                                        {project.label}
                                    </span>
                                </div>
                                <img
                                    className={styles.imgMobile}
                                    src={project.image}
                                    alt={`Project ${index + 1}`}
                                />
                                <div className={styles.datePostAndLocationMobile}>
                                    <div>{project.datePost}</div>
                                    <div>{project.location}</div>
                                </div>
                                <div className={styles.titlePostMobile}>
                                    {selectedLanguage === 'pt' ? project.title_pt : project.title_en}
                                </div>
                                <ProjectButton projectId={project._id.$oid} />
                            </div>
                        ))}
                    </Flicking>
                    <AccessAreaMenuBig text={selectedLanguage === 'pt' ? "VER TODOS" : "SEE ALL"} />
                    <div style={{ height: "100px" }}></div>
                    {totalPages > 1 && (
                        <div className={styles.paginationMobile}>
                            <button
                                style={{
                                    background: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                }}
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="49"
                                    height="35"
                                    viewBox="0 0 49 35"
                                    fill="none"
                                >
                                    <rect
                                        x="0.5"
                                        y="0.5"
                                        width="34"
                                        height="34"
                                        rx="17"
                                        stroke={currentPage === 1 ? "#E1E6EF" : "#648586"}
                                    />
                                    <path
                                        d="M49 17.0551H23.8011V13.1988L19 18.0001L23.8011 22.8012V18.945H49V17.0551Z"
                                        fill={currentPage === 1 ? "#E1E6EF" : "#648586"}
                                    />
                                </svg>
                            </button>
                            {pageNumbers.map((pageNumber) => (
                                <button
                                    style={{
                                        background: "transparent",
                                        border: "none",
                                        cursor: "pointer",
                                        color: pageNumber === currentPage ? "black" : "#648586",
                                        transition: "0.5s all",
                                        fontFamily: "Maven Pro",
                                        fontSize: pageNumber === currentPage ? "20px" : "16px",
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        lineHeight: "24px",
                                    }}
                                    key={pageNumber}
                                    onClick={() => handlePageChange(pageNumber)}
                                >
                                    {pageNumber}
                                </button>
                            ))}
                            <div>
                                <span
                                    style={{
                                        background: "transparent",
                                        border: "none",
                                        cursor: "pointer",
                                        color: "#648586",
                                        fontFamily: "Maven Pro",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        lineHeight: "24px",
                                    }}
                                >
                                    . . .
                                </span>
                            </div>
                            <button
                                style={{
                                    background: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                    color: "#648586",
                                    fontFamily: "Maven Pro",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    lineHeight: "24px",
                                }}
                                onClick={() => handlePageChange(totalPages)}
                                disabled={currentPage === totalPages}
                            >
                                {totalPages}
                            </button>
                            <button
                                style={{
                                    background: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                }}
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                <svg
                                    width="49"
                                    height="35"
                                    viewBox="0 0 49 35"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        x="14.5"
                                        y="0.5"
                                        width="34"
                                        height="34"
                                        rx="17"
                                        stroke={currentPage === totalPages ? "#E1E6EF" : "#648586"}
                                    />
                                    <path
                                        d="M30 17.9999L25.1988 13.1987V17.055H0V18.9449H25.1988V22.8012L30 17.9999Z"
                                        fill={currentPage === totalPages ? "#E1E6EF" : "#648586"}
                                    />
                                </svg>
                            </button>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default CardsTagsRelatedProjects;
