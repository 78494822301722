/**
 * Renders a SemiBoldH4 component.
 *
 * @param {object} props - The properties passed to the component.
 * @param {string} props.value - The value to be displayed in the H4.
 * @param {boolean} props.isSelected - Indicates whether the H4 is selected.
 * @param {string} props.textColor - The color of the H4 text.
 * @returns {JSX.Element} - The rendered SemiBoldH4 component.
 */
import React from "react";

const SemiBoldH4Styles = {
	color: "var(--token-colors - text - plain - text - light, #545454)",
	textAlign: "center",
	fontFamily: "Maven Pro",
	fontSize: "32px",
	fontStyle: "normal",
	fontWeight: "600",
	lineHeight: "40px",
};

export default function SemiBoldH4({ value, isSelected, textColor }) {
	const textColorStyle = textColor || SemiBoldH4Styles.color;

	return (
		<>
			<p
				style={{
					...SemiBoldH4Styles,
					opacity: isSelected ? 1 : 0.5,
					color: textColorStyle,
				}}
			>
				{value}
			</p>
		</>
	);
}
