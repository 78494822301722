import React, { useState, useRef } from "react";
import Flicking from "@egjs/react-flicking";
import styled from "styled-components";
import { PageHead } from "../../atoms/PageHead";
import AccessAreaMenuBig from "../../atoms/AccessAreaMenuBig";
import { useTranslation } from "react-i18next";
import Header_Description_Type_Project from "../../molecules/HeaderDescriptionTypeProject";
import { useLanguage } from "../../../utils/translations/LanguageContext";
const CarouselContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  height: auto;
  background-color: ${(props) => props.customColor};
  padding-bottom: 108px;
  text-align: center;
`;

const Label = styled.div`
  width: 95%;
  margin: 0 auto;
  height: auto;
  justify-content: center;
  padding-bottom: 48px;
`;
const Slide = styled.div`
  margin: 1rem;
  width: 70vw;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 1rem;
`;

const Image = styled.img`
  width: 70vw;
  height: 90vw;
  border-radius: 24px;
`;
const Title = styled.p`
  color: #648586;
  font-family: "Maven Pro-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
`;

const Description = styled.p`
  color: #545454;
  font-family: "Maven Pro-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  white-space: wrap;
`;

function SliderModelCommercialProjectsAndFranchisesMobile(props) {
  const { t } = useTranslation();
  const { selectedLanguage } = useLanguage();
  const flicking = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeBulletIndex, setActiveBulletIndex] = useState(0);
  const [slides, setSlides] = useState([
    {
      image:
        "https://bohocasasstorage.blob.core.windows.net/boho-casas/DESTAQUE1.png",
      title: selectedLanguage === "pt" ? "DOCERIA CHOCOARTE" : "CHOCOARTE SWEETSHIP",
      description: selectedLanguage === "pt" ? "Projeto de Interiores e fachadas para uma loja de doces diversos." : "Interior design and facades for a various sweets store.",
    },
    {
      image:
        "https://bohocasasstorage.blob.core.windows.net/boho-casas/DESTAQUE2.png",
      title: selectedLanguage === "pt" ? "FRANQUIA OAKBERRY" : "OAKBERRY FRANCHISE",
      description: selectedLanguage === "pt" ? "Projetos de Interiores e fachadas das lojas da Franquia de Açaí OAKBERRY" : "Interior projects and store facades of the OAKBERRY Açaí Franchise",
    },
    {
      image:
        "https://bohocasasstorage.blob.core.windows.net/boho-casas/DESTAQUE3.png",
      title: selectedLanguage === "pt" ? "FRANQUIA OUTBECO" : "OUTBECO FRANCHISE",
      description: selectedLanguage === "pt" ? "Projetos de Interiores e fachadas dos restaurantes OUTBECO" : "Interior projects and facades of OUTBECO restaurants",
    },
  ]);

  const handleIndexChanged = (e) => {
    setCurrentIndex(e.index);
  };

  return (
    <CarouselContainer customColor={props.customColor}>
      <div style={{ paddingTop: "3rem" }}>
        <Header_Description_Type_Project
          title={t("Projects and Works")}
          subtitle={t("Commercial Projects and Franchises")}
        />
      </div>

      <Label>
        <p className="descubra-a-categoria">
          {t(
            "Discover the Residential Projects category at BohoCasas, offering diversity in interior and exterior work. Our talented team will transform your residence into an inspiring and functional home. Trust us to create custom spaces that reflect your style."
          )}
        </p>
      </Label>
      <Flicking
        ref={flicking}
        align="center"
        circular
        onIndexChanged={handleIndexChanged}
      >
        {slides.map((slide, index) => (
          <Slide key={index}>
            <Image src={slide.image} alt={`Slide ${index + 1}`} />
            <Title>{slide.title}</Title>
            <Description>{slide.description}</Description>
          </Slide>
        ))}
      </Flicking>
      <AccessAreaMenuBig
        className="access-area-menu-big-instance"
        frameClassName="design-component-instance-node"
        text={t("See Projects")}
        goTo="Commercial"
      />
    </CarouselContainer>
  );
}

export default SliderModelCommercialProjectsAndFranchisesMobile;
