import React from "react";
import styles from "./Section_Projects_Residentials.module.css";
import InfiniteScrollComponent from "../../molecules/Slider_Projects";
import Header_Description_Type_Project from "../../molecules/HeaderDescriptionTypeProject";
import { PageHead } from "./../../atoms/PageHead/index";
import AccessAreaMenuBig from "../../atoms/AccessAreaMenuBig";
import checkIsMobile from "../../../utils/checkIsMobile";
import { useLanguage } from "../../../utils/translations/LanguageContext";
import { useTranslation } from "react-i18next";
export default function Section_Projects_Residentials_Architeture() {
	const { selectedLanguage } = useLanguage();
	const { t } = useTranslation();

	const [projects] = React.useState([
		{
			_id: "5f9d5f1b9d6f6e0017f7e3a1",
			name:
				selectedLanguage === "pt"
					? "Projeto personalizado - Farmhouse"
					: "Personalized Project - Farmhouse",
			description:
				selectedLanguage === "pt"
					? "Projeto residencial para construção em LSF."
					: "Residential Project for Construction in LSF.",
			images: [
				"https://bohocasasstorage.blob.core.windows.net/boho-casas/Sintra1_A.png",
			],
		},
		{
			_id: "5f9d5f1b9d6f6e0017f7e3a2",
			name:
				selectedLanguage === "pt"
					? "Projeto personalizado - Hotel Açores"
					: "Personalized Project - Hotel Azores",
			description:
				selectedLanguage === "pt"
					? "Projeto para construção de um hotel com 50 quartos, restaurante, ginásio, spa e área de lazer."
					: "Project to build a 50 bedroom hotel, restaurant, gym, spa and leisure area.",
			images: [
				"https://bohocasasstorage.blob.core.windows.net/boho-casas/Hotel.png",
			],
		},
		{
			_id: "5f9d5f1b9d6f6e0017f7e3a3",
			name:
				selectedLanguage === "pt"
					? "PROJETO PERSONALIZADO – CASA SÃO MIGUEL"
					: "Personalized Project - House Saint Miguel",
			description:
				selectedLanguage === "pt"
					? "Projeto para construção raiz residencial."
					: "Project for residential root construction.",
			images: [
				"https://bohocasasstorage.blob.core.windows.net/boho-casas/CasaSaoMiguel.png",
			],
		},
	]);

	return (
		<>
			{!checkIsMobile() ? (
				<div className={styles.ContainerSection} id="ResidentialsArchiteture">
					<div className={styles.Pagehead}>
						<div>
							<PageHead
								text={t("WHAT WE'VE ALREADY DONE")}
								text1={t("textSectionResidentialProjects")}
							></PageHead>
						</div>
						<div>
							<AccessAreaMenuBig
								className="access-area-menu-big-instance"
								frameClassName="design-component-instance-node"
								text= {t("See Projects")}
								goTo={"ResidentialsArchiteture"}
							/>
						</div>
					</div>
					<InfiniteScrollComponent
						listOfProjects={projects}
						backgroundColor={"#F2F2F2"}
					/>
				</div>
			) : (
				<div
					className={styles.ContainerSectionMobile}
					id="ResidentialsArchiteture"
				>
					<Header_Description_Type_Project
						title={t("WHAT WE'VE ALREADY DONE")}
						subtitle={t("textSectionResidentialProjects")}
					/>
					<InfiniteScrollComponent
						listOfProjects={projects}
						backgroundColor={"#F2F2F2"}
					/>
					<div>
						<AccessAreaMenuBig
							className="access-area-menu-big-instance"
							frameClassName="design-component-instance-node"
							text={t("See Projects")}
							goTo={"ResidentialsArchiteture"}
						/>
					</div>
				</div>
			)}
		</>
	);
}
