import React from "react";
import Header from "../Header";
import SemiBoldLabel from "../../atoms/TextStyleSemiBold/SemiBoldLabel";
import RegularH1 from "../../atoms/TextStyleRegular/RegularH1";
import Aside_Bar_Social_Media from "../../molecules/Aside_Bar_Social_Media";
import { useTranslation } from "react-i18next";
import "./SectionHeroRemodelingMobile.css";

export default function SectionHeroRemodelingMobile() {
  const { t } = useTranslation();

  return (
    <div className="ContainerSectionHeroRemodelingMobile">
      <Header />
      <div className="filter"></div>
      <div className="TextSectionHeroBodyMobile">
        <SemiBoldLabel value={"BohoCasas"} isSelected={true} />
        <RegularH1
          value={t("Remodeling")}
          isSelected={true}
          textColor={"var(--token-colors-text-plain-text-dark, #FFF)"}
        />
        <p>
          {t(
            "Explore our category of architectural projects at Bohocasas, which features a wide diversity of work focused work. Our expert team has experience in this modern and innovative constructive method, which uses light steel to create durable, efficient and sustainable spaces"
          )}
        </p>
      </div>
    </div>
  );
}
