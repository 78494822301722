import React from "react";
import ResidentialProjectsPageMobileTemplate from "../templates/ResidentialProjectsPageMobileTemplate";


export default function ResidentialProjectsPageMobile() {
  return (
    <div>
      <ResidentialProjectsPageMobileTemplate />
    </div>
  );
}
