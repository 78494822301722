import React from "react";
import styles from "../Logo/Logo.module.css";
import { useTranslation } from "react-i18next";
import checkIsMobile from "../../../utils/checkIsMobile";


import { Link } from "react-router-dom";
/**
 * Logo Component
 *
 * This component renders the logo of "Boho Casas" along with a text logo.
 * It uses the Next.js Image component for optimized image loading and the
 * react-i18next library for internationalization.
 *
 * @component
 *
 * @returns {JSX.Element} The rendered Logo component.
 *
 * @example
 * // Usage
 * <Logo />
 *
 * @author Bruno Paulon
 */
export default function Logo() {
  const { t } = useTranslation(); // Destructuring the t function for translation

  /**
   * Renders the logo along with the translated text logo.
   *
   * @returns {JSX.Element} The rendered logo and text.
   */
  return (
    <>
      {!checkIsMobile() ? (
        <>
          <Link to={'/'} className={styles.containerLogo}>
            <div className={styles.image}>
              <img
                src={
                  "https://bohocasasstorage.blob.core.windows.net/boho-casas/logo.png"
                }
                width={48}
                height={59}
                alt="logo of boho casas"
              />
            </div>
            <h1 className={styles.textLogo}>{t("textLogo")}</h1>
          </Link>
        </>
      ) : (
        <>
          <Link to={'/'} className={styles.containerLogoMobile}>
            <div className={styles.imageMobile}>
              <img
                src={
                  "https://bohocasasstorage.blob.core.windows.net/boho-casas/logo.png"
                }
                width={58}
                height={69}
                alt="logo of boho casas"
              />
            </div>
            <h1 className={styles.textLogoMobile}>{t("textLogo")}</h1>
          </Link>
        </>
      )}
    </>
  );
}



/* MANTER ESSE CODIGO AO FAZER MERGE JA FOI ALTERADO DESKTOP E MOBILE */