import React, { useState, useEffect } from "react";
import { Modal, message } from "antd";
import './CookiesMobile.css';
import { useLanguage } from "./translations/LanguageContext";
import CustumizeCookies from "./CustumizeCookies";
import PrivacyPolicyModal from "../components/molecules/FormSendMessage/PrivacyPolicyModal";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
const CookiesMobile = () => {
    const { t } = useTranslation();
    const { selectedLanguage } = useLanguage();
    const [privacyModalVisible, setPrivacyModalVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [checkboxes, setCheckboxes] = useState({
        comfort: false,
        statistics: false,
        marketing: false,
    });
    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleAccept = () => {
        setIsModalVisible(false);
        setCheckboxes({
            comfort: true,
            statistics: true,
            marketing: true,
        });
        // Save the user preferences in cookies
        Cookies.set("languagePreference", selectedLanguage);
        Cookies.set("comfort", true);
        Cookies.set("statistics", true);
        Cookies.set("marketing", true);
        console.log(checkboxes);
        window.location.reload()
    }
    const handleReject = () => {
        setIsModalVisible(false);
        setCheckboxes({
            comfort: false,
            statistics: false,
            marketing: false,
        });
        // Save the user preferences in cookies
        Cookies.set("comfort", false);
        Cookies.set("statistics", false);
        Cookies.set("marketing", false);
        console.log(checkboxes);
        window.location.reload()
    }
    const handleCancel = () => {
        setIsModalVisible(false);
        setCheckboxes({
            comfort: false,
            statistics: false,
            marketing: false,
        });
        Cookies.set("comfort", false);
        Cookies.set("statistics", false);
        Cookies.set("marketing", false);
        console.log(checkboxes);
    };
    const handleAcceptAll = () => {
        setCheckboxes({
            comfort: true,
            statistics: true,
            marketing: true,
        });
        Cookies.set("languagePreference", selectedLanguage);
        Cookies.set("comfort", true);
        Cookies.set("statistics", true);
        Cookies.set("marketing", true);
        setIsModalVisible(false);
        window.location.reload()
    };
    const handleAcceptSelection = () => {
        const selections = Object.keys(checkboxes).filter((key) => checkboxes[key]);
        if (selections.length === 0) {
            // Show an Ant Design warning alert
            message.warning('Please make a selection before accepting.');
        }
        else {
            setIsModalVisible(false);
            selections.forEach((selection) => {
                Cookies.set(selection, true);
            });
        }
    };
    return (
        <div className="cookies-mobile">
            <h2 className="textH2Mobile"> {t("Before you start: Learn more about the processing of your data")}</h2>
            <p className="cookiesParagraphMobile">
                {t(`We treat your data on our sites using different technologies (including cookies). Some of these technologies are technically necessary, while others are used by us or by third parties, for a comfortable configuration of the websites, to generate personalized (advertising) statistics or (advertising) actions. This also covers data transfers to countries outside the EU without a proper level of data protection. If you click reject, you will only authorize the use of the necessary techniques. Click on customizing you can allow specific uses. For more information, including your right to withdraw consent at any time, see our data protection policy. You can consult our legal information here.`)}
                <span
                    onClick={() => {
                        setPrivacyModalVisible(!privacyModalVisible);
                    }}
                    style={{
                        paddingBottom: "10px",
                        cursor: "pointer",
                        fontWeight: "bold",
                        textDecoration: "none",
                        transition: "text-decoration 0.3s",
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.textDecoration = "underline";
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.textDecoration = "none";
                    }}
                >
                    &nbsp;{t("Here")}
                    <PrivacyPolicyModal privacyModalVisible={privacyModalVisible} setPrivacyModalVisible={setPrivacyModalVisible} selectedLanguage={selectedLanguage} />
                </span>
            </p>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px", margin: "auto" }}>
                <button className="ButtonAccept-mobile" onClick={handleAccept}>
                    {t("ACCEPT")}
                </button>
                <button className="ButtonReject-mobile" onClick={handleReject}>
                    {t("REJECT")}
                </button>
            </div>
            <Modal
                title={selectedLanguage === "pt" ? "Política de Cookies" : "Cookies Policy"}
                visible={isModalVisible}
                width="100%"
                onCancel={handleCancel}
                footer={[
                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "10px" }}>
                        <button className="ButtonAcceptAllModal" onClick={handleAcceptSelection}>
                            {selectedLanguage === "pt" ? "ACEITAR SELEÇÃO" : "ACCEPT SELECTION"}
                        </button>
                        <button className="ButtonRejectAllModal" onClick={handleCancel}>
                            {selectedLanguage === "pt" ? "REJEITAR TODOS" : "REJECT ALL"}
                        </button>
                    </div>
                ]}
            >
                <div style={{ height: "400px", overflow: "auto" }}>
                    <h2>{selectedLanguage === "pt" ? "Definir o âmbito do consentimento" : "Define the scope of consent"}</h2>
                    <p>
                        {selectedLanguage === "pt" ? "Pode dar o seu consentimento às operações de tratamento de dados, de forma individual e de acordo com as finalidades e funções abaixo indicadas. Se alargar o respetivo botão, encontrará informações pormenorizadas sobre as técnicas e o tratamento de dados abrangidos em cada caso. Para mais informações, incluindo sobre o seu direito de retirada do consentimento em qualquer altura, consulte a nossa política de proteção de dados." : "It can consent to data processing operations individually and in accordance with the purposes and functions below. If you widen your button, you will find detailed information about the techniques and data processing covered in each case. For more information, including your right to withdraw consent at any time, see our data protection policy."}
                    </p>
                    <button className="ButtonAcceptAllModal" onClick={handleAcceptAll}>
                        {selectedLanguage === "pt" ? "ACEITAR TODOS" : "ACCEPT ALL"}
                    </button>
                    <h2 style={{ marginTop: "16px" }}>{selectedLanguage === "pt" ? "Gerir preferências de cookies" : "Manage cookie preferences"}</h2>
                    <CustumizeCookies checkboxes={checkboxes} setCheckboxes={setCheckboxes} />
                </div>
            </Modal>
        </div>
    );
};
export default CookiesMobile;
