import React from "react";
import styles from "./BTNSendMessage.module.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import checkIsMobile from "../../../utils/checkIsMobile";

export default function BTNSendMessage({ backgroundColor }) {
	const { t } = useTranslation();

	return (
		<>
			<Link
				to="https://wa.me/351964927140"
				className={!checkIsMobile() ? styles.whatsappBbutton : styles.whatsappBbuttonMobile}
				style={{ backgroundColor }}
			>
				<svg
					width="25"
					height="24"
					viewBox="0 0 25 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M7.40001 20.55C8.90001 21.45 10.7 21.9 12.5 21.9C18.05 21.9 22.4 17.4 22.4 12.15C22.4 9.45 21.5 7.05 19.55 5.1C17.6 3.3 15.2 2.25 12.5 2.25C7.1 2.25 2.59999 6.75 2.59999 12.15C2.59999 13.95 3.05 15.75 4.09999 17.4L4.40001 17.85L3.34999 21.45L7.09999 20.4L7.40001 20.55ZM15.8 13.5C16.1 13.5 17.6 14.25 17.9 14.4C17.9467 14.4234 17.9934 14.4431 18.0396 14.4626C18.2898 14.5682 18.5234 14.6668 18.65 15.3C18.8 15.3 18.8 15.9 18.5 16.65C18.35 17.25 17.15 18 16.55 18C16.4484 18 16.351 18.0086 16.247 18.0178C15.7372 18.0629 15.068 18.1221 12.95 17.25C10.3213 16.1985 8.49877 13.6499 7.98699 12.9342C7.9147 12.8331 7.86855 12.7686 7.84999 12.75C7.82445 12.6989 7.77281 12.6174 7.70472 12.5099C7.37288 11.9859 6.65001 10.8446 6.65001 9.6C6.65001 8.1 7.39999 7.35 7.69998 7.05C7.99998 6.75 8.29998 6.75 8.44998 6.75H9.05002C9.20002 6.75 9.50001 6.75 9.65001 7.2C9.95 7.8 10.55 9.3 10.55 9.45C10.55 9.5 10.5667 9.55 10.5833 9.6C10.6167 9.7 10.65 9.8 10.55 9.9C10.475 9.97499 10.4375 10.05 10.4 10.125C10.3625 10.2 10.325 10.275 10.25 10.35L9.80002 10.8C9.65002 10.95 9.50001 11.1 9.65001 11.4C9.80001 11.7 10.4 12.75 11.3 13.5C12.3127 14.3861 13.1117 14.738 13.5168 14.9165C13.5917 14.9495 13.6532 14.9766 13.7 15C14 15 14.15 15 14.3 14.85C14.375 14.7 14.5625 14.475 14.75 14.25C14.9375 14.025 15.125 13.8 15.2 13.65C15.35 13.35 15.5 13.35 15.8 13.5Z"
						fill="white"
					/>
				</svg>

				
				{!checkIsMobile() ? (<p>{t("sendMessage")}</p>) : (<p></p>)}
			</Link>
		</>
	);
}
