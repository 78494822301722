import React from 'react'
import styles from './Text_Semi_Bold_35.module.css'

export default function Text_Semi_Bold_35({
    value,
    isSelected,
    textColor
}) {
    return (
        <>
            <p
                className={styles.text35}
                style={{
                    opacity: isSelected ? 1 : .5,
                    color: textColor ? textColor : 'white'
                }}>
                {value}
            </p>
        </>

    )
}