import React from 'react'
import Section_Contact_Us from '../../components/organisms/SectionContactUs'
import GoogleMap from '../../components/organisms/MapContainer'
import FooterWeb from '../../components/organisms/FooterWeb/FooterWeb'
import SectionHeroRemodeling from '../../components/organisms/SectionHeroRemodeling'
import CardsRelatedProjectsRemodeling from '../../components/molecules/CardsRelatedProjectsRemodeling'
import SliderModelWebRenovations from '../../components/organisms/SliderModelWebRenovations'
/**
 * Renders the Projects Remodeling Page template.
 *
 * @returns {JSX.Element} The rendered component.
 */
export default function ProjectsRemodelingPageTemplate() {
    return (
        <div>
            <SectionHeroRemodeling />
            <SliderModelWebRenovations customColor={"#FFFFFF"} />
            <CardsRelatedProjectsRemodeling backgroundColor={"#FFFFFF"} />
            <Section_Contact_Us />
            <div style={{ height: "627px", width: "100vw" }}>
                <GoogleMap />
            </div>
            <FooterWeb />
        </div>
    )
}
