import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, ConfigProvider, DatePicker, Form, Input, Modal, Select, Space } from 'antd';
import { ProjectService } from "boho-casas-client-module/client-web/services/ProjectService"; // Update the import path accordingly
import React, { useEffect, useState } from 'react';
import UploadImages from '../Upload';
import './stylesRead.css';

const imagesAtualizadas = () => {
  console.log(imagesAtualizadas);
}



const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const ReadProjects = () => {
  const [showButton, setShowButton] = useState(null);
  const { Option } = Select;
  const handleMouseEnter = (index) => {
    setShowButton(index);
  };

  const handleMouseLeave = () => {
    setShowButton(null);
  };

  const [projects, setProjects] = useState([]);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editedProject, setEditedProject] = useState({});
  const [form] = Form.useForm();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  const [imageURLs, setImageURLs] = useState([]);
  const handleCancel = () => {
    setPreviewOpen(false)
    form.resetFields()
  };
  const apagarImagem = (project, Arrayimagem) => {
    console.log(project, Arrayimagem);
    var newImages = project.images.filter((item) => item !== Arrayimagem);
    console.log(newImages);
    ProjectService.updateProject(project._id.$oid, {
      title_pt: project.title_pt,
      title_en: project.title_en,
      subtitle_pt: project.subtitle_pt,
      subtitle_en: project.subtitle_en,
      description_pt: project.description_pt,
      description_en: project.description_en,
      client_name: project.client_name,
      created_project: project.created_project,
      service: project.service,
      location: project.location,
      team_member: project.team_member,
      tags: project.tags,
      images: newImages

    })
  }
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    setImageURLs([...imageURLs, file.url || file.preview]);
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Carregar
      </div>
    </div>
  );
  useEffect(() => {

    const fetchAllProjects = async () => {
      try {
        const response = await ProjectService.returnAllProject();
        setProjects(response.data);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };
    fetchAllProjects();
  }, [projects, editedProject]);



  const handleEdit = project => {
    console.log(project);
    form.setFieldsValue(project)
    setEditedProject(project)
    setEditModalVisible(true)
  }
  const handleSave = (values) => {
    console.log('Received values of form: ', values);
    ProjectService.updateProject(editedProject._id.$oid, {
      title_pt: values.title_pt,
      title_en: values.title_en,
      subtitle_pt: "",
      subtitle_en: "",
      description_pt: values.description_pt,
      description_en: values.description_en,
      client_name: "",
      created_project: "",
      service: "",
      team_member: values.team_member,
      location: values.location,
      tags: values.tags,
      images: values.images.concat(Images),
    }).then((response) => {
      console.log(response.data);

    }).catch((error) => {
      console.error(error);
    })
    setEditModalVisible(false);
  };



  const handleDelete = async (project) => {
    try {
      const response = await ProjectService.deleteProject(project._id.$oid);
    } catch (error) {
      console.error('Error Delete project:', error);
    }
  };
  const [Images, setImages] = useState([
  ])
  const [newImages, setnewImages] = useState([])

  const handleDataReceivedImages = (values) => {
    setImages(values)
  }

  return (
    <div style={{ backgroundColor: '#F2F2F2', color: 'rgba(35, 39, 47, 1)', height: 'auto', maxWidth: "100vw" }}>
      <div style={{
        display: 'flex', flexWrap: 'wrap', justifyContent: 'center',
        alignItems: 'stretch', width: '100vw', color: 'rgba(35, 39, 47, 1)', gap: '1rem'
      }}>
        {projects.map(project => (
          <Card
            key={project._id.$oid}
            title={project.title}
            style={{
              width: "40%",
              height: "auto",
              margin: 16,
              backgroundColor: '#ccd7d9',
            }}
            extra={
              <>

                <ConfigProvider>
                  <Space>
                    <Button type="default" onClick={() => handleEdit(project)} style={{ background: '#fff', color: '#51787d', marginLeft: '0.1rem' }}>
                      <EditOutlined /> Editar
                    </Button>
                    <Button type="danger" onClick={() => handleDelete(project)} style={{ background: '#51787d', color: '#fff' }}>
                      <DeleteOutlined /> Deletar
                    </Button>
                  </Space>
                </ConfigProvider>
              </>
            }
          >
            <div style={{ display: 'flex', gap: '20px', overflow: 'auto' }}>
              <div
                style={{ position: 'relative', display: 'flex', gap: '20px' }}

              >
                {project.images.map((url, index) => (
                  <div style={{ position: 'relative', display: 'flex', gap: '20px' }}
                    onMouseEnter={(value) => {

                      setShowButton(url)

                    }}
                    onMouseLeave={(value) => {

                      setShowButton(null)
                    }}>
                    <img
                      src={url}
                      alt={`Image ${index}`}
                      style={{ width: '100px', height: '100px' }}
                    />
                    {url === showButton && <div style={{ position: 'absolute', top: 0, right: 0 }}>
                      <Button
                        type="danger"
                        style={{ background: '#51787d', color: '#fff' }}
                        onClick={() => apagarImagem(project, url)}
                      >
                        <DeleteOutlined />
                      </Button>
                    </div>}
                  </div>
                ))}
              </div>
            </div>
            <br />
            <p><strong>Título em Português:</strong> {project.title_pt}</p>
            <p><strong>Título em Inglês:</strong> {project.title_en}</p>
            {/* <p><strong>Subtitulo em Português:</strong> {project.subtitle_pt}</p> */}
            {/* <p><strong>Subtitulo em Inglês:</strong> {project.subtitle_en}</p> */}
            <p><strong>Descrição em Português:</strong> {project.description_pt}</p>
            <p><strong>Descrição em Inglês:</strong> {project.description_en}</p>
            <p><strong>Nome do cliente:</strong> {project.client_name}</p>
            <p><strong>Data que o Projeto foi criado:</strong> {project.created_project}</p>
            <p><strong>Etiqueta:</strong> {project.service}</p>
            <p><strong>Localização:</strong> {project.location}</p>
            <p><strong>Time Envolvido:</strong> {project.team_member}</p>
            <p><strong>Tags:</strong> {project.tags}</p>

          </Card>
        ))}
      </div>

      <Modal
        title="Editar Projeto"
        visible={editModalVisible}
        onCancel={() => setEditModalVisible(false)}
        onOk={form.submit}
        width={700}
        className="custom-modal"


      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSave} // Use onFinish to handle form submission
        >


          <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
            <img
              alt="example"
              style={{
                width: '100%',
              }}
              src={previewImage}
            />
          </Modal>
          <Form.Item name="images" label="Mude a sua imagem">
            <UploadImages onDataReceived={handleDataReceivedImages} setImagesList={setImages} />
          </Form.Item>
          <Form.Item name="title_pt" label="Titulo em Português">
            <Input style={{ width: '250px' }} />
          </Form.Item>
          {/* <Form.Item name="subtitle_pt" label="Subtitulo em Português">
            <Input />
          </Form.Item> */}
          <Form.Item name="description_pt" label="Descrição em Português">
            <Input.TextArea />
          </Form.Item>
          {/* <Form.Item name="client_name" label="Nome do cliente">
            <Input />
          </Form.Item> */}
          {/* <Form.Item name="service" label="Etiqueta">
            <Input />
          </Form.Item> */}
          <Form.Item name="team_member" label="Time envolvido">
            <Input />
          </Form.Item>
          <Form.Item name="title_en" label="Titulo em Inglês">
            <Input />
          </Form.Item>
          {/* <Form.Item name="subtitle_en" label="Subtitulo em Inglês">
            <Input />
          </Form.Item> */}
          <Form.Item name="description_en" label="Descrição em Inglês">
            <Input.TextArea />
          </Form.Item>
          {/* <Form.Item
            name="date_created_project" label="Data que o Projeto foi criado"
            required
            rules={
              [
                {
                  required: true,
                  message: 'Por favor selecione a data',
                },
              ]
            }
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item> */}
          <Form.Item name="location" label="Localização">
            <Input style={{ width: '250px' }} />
          </Form.Item>
          <Form.Item name="tags" label="Tags">
            <Select
              mode="multiple"
              placeholder="Selecione as tags"
              style={{ width: '100%' }}
            >
              <Option value="LSF" label="LSF">
                <Space>
                  LSF
                </Space>
              </Option>
              <Option value="Remodelações" label="Remodelações">
                <Space>
                  Remodelações
                </Space>
              </Option>
              <Option value="Comerciais e franquias" label="Comerciais e franquias">
                <Space>
                  Comerciais e franquias
                </Space>
              </Option>
              {/* <Option value="Residenciais" label="Residenciais">
                <Space>
                  Residenciais
                </Space>
              </Option> */}
              <Option value="Interior" label="Interior">
                <Space>
                  Residenciais de Interior
                </Space>
              </Option>
              <Option value="Arquitetura" label="Arquitetura">
                <Space>
                  Residenciais de Arquitetura
                </Space>
              </Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>

  );
};

export default ReadProjects;