import React from 'react'
import ProjectsRemodelingPageTemplate from '../templates/ProjectsRemodelingPageTemplate'

export default function ProjectsRemodelingPage() {
    return (
        <div>
            <ProjectsRemodelingPageTemplate />
        </div>
    )
}
