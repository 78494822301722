import React from "react";

const BTNInstagram = () => {
	return (
		<a
			href="https://www.instagram.com/bohocasas/"
			style={{ paddingBottom: "30px" }}
		>
			<svg
				width="56"
				height="56"
				viewBox="0 0 56 56"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle opacity="0.16" cx="28" cy="28" r="28" fill="white" />
				<path
					d="M28.1413 22.666C30.6724 22.666 32.7615 24.7552 32.7615 27.2863C32.7615 29.8575 30.6724 31.9065 28.1413 31.9065C25.57 31.9065 23.5211 29.8575 23.5211 27.2863C23.5211 24.7552 25.57 22.666 28.1413 22.666ZM28.1413 30.2994C29.7885 30.2994 31.1143 28.9736 31.1143 27.2863C31.1143 25.639 29.7885 24.3132 28.1413 24.3132C26.4539 24.3132 25.1281 25.639 25.1281 27.2863C25.1281 28.9736 26.4941 30.2994 28.1413 30.2994ZM34.007 22.5053C34.007 23.108 33.5248 23.5901 32.9222 23.5901C32.3196 23.5901 31.8375 23.108 31.8375 22.5053C31.8375 21.9027 32.3196 21.4206 32.9222 21.4206C33.5248 21.4206 34.007 21.9027 34.007 22.5053ZM37.0603 23.5901C37.1407 25.0766 37.1407 29.5361 37.0603 31.0226C36.98 32.4689 36.6586 33.7144 35.614 34.7991C34.5694 35.8437 33.2838 36.1651 31.8375 36.2454C30.351 36.3258 25.8915 36.3258 24.4049 36.2454C22.9586 36.1651 21.7132 35.8437 20.6284 34.7991C19.5839 33.7144 19.2625 32.4689 19.1821 31.0226C19.1018 29.5361 19.1018 25.0766 19.1821 23.5901C19.2625 22.1438 19.5839 20.8581 20.6284 19.8136C21.7132 18.769 22.9586 18.4476 24.4049 18.3672C25.8915 18.2869 30.351 18.2869 31.8375 18.3672C33.2838 18.4476 34.5694 18.769 35.614 19.8136C36.6586 20.8581 36.98 22.1438 37.0603 23.5901ZM35.1319 32.5895C35.614 31.4244 35.4935 28.6121 35.4935 27.2863C35.4935 26.0006 35.614 23.1883 35.1319 21.9831C34.8105 21.2197 34.2078 20.5769 33.4445 20.2957C32.2392 19.8136 29.4269 19.9341 28.1413 19.9341C26.8155 19.9341 24.0032 19.8136 22.8381 20.2957C22.0346 20.6171 21.4319 21.2197 21.1105 21.9831C20.6284 23.1883 20.749 26.0006 20.749 27.2863C20.749 28.6121 20.6284 31.4244 21.1105 32.5895C21.4319 33.393 22.0346 33.9956 22.8381 34.317C24.0032 34.7991 26.8155 34.6786 28.1413 34.6786C29.4269 34.6786 32.2392 34.7991 33.4445 34.317C34.2078 33.9956 34.8506 33.393 35.1319 32.5895Z"
					fill="white"
				/>
			</svg>
		</a>
	);
};

export default BTNInstagram;
