import React, { useState } from "react";
import styles from "./HeaderDescriptionTypeProject.module.css";
import Text_Bold_14 from "../../atoms/Text_Bold_14";
import TextSemiBold32 from "../../atoms/TextSemiBold32";
import checkIsMobile from "../../../utils/checkIsMobile";
import BoldH6 from "../../atoms/TextStyleBold/BoldH6";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Header_Description_Type_Project({
	title,
	subtitle,
	backgroundColor,
	buttonTitle,
	buttonAction,
	showSVG,
}) {
	const navigate = useNavigate();
	const handleClick = () => {
		navigate("/blog/");
		window.location.reload()
	}
	return (
		<>
			{!checkIsMobile() ? (
				<div className={styles.rootContainer} style={{ backgroundColor: backgroundColor, flexDirection: "row" }}>
					<div style={{ height: "100%", flex: 1 }}>
						<div className={styles.firstLine}>
							<div className={styles.line} />
							<Text_Bold_14 textColor={"#51787d"} value={title}/>
						</div>
						<br></br>
						<div className={styles.secondLine}>
							<div style={{ marginLeft: "50px" }}>
								<TextSemiBold32
									textColor={"#545454"}
									isSelected={true}
									value={subtitle}
								/>
							</div>
						</div>
					</div>
					<div
						style={{ height: "100%", flex: .2, justifyContent: "center", alignContent: "center", alignItems: "center", display: "flex", cursor: "pointer" }}
						onClick={buttonAction}
					>
						{showSVG && (
							<span style={{ display: "flex", alignItems: "center", paddingRight: "10px" }}>
								<svg width="24" height="2" viewBox="0 0 24 2" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect y="0.5" width="24" height="1" fill="#51787d" />
								</svg>
							</span>
						)}
						<div style={{ textDecoration: "none", minWidth:"300px"}} onClick={() => handleClick()}>
							<BoldH6 value={buttonTitle} isSelected={true} textColor="#648586" fontSize="18" />
						</div>
					</div>
				</div>
			) : (
				<div className={styles.rootMobile}>
					<div className={styles.firstLineMobile}>
						<div className={styles.lineMobile} />
						<Text_Bold_14 textColor={"#51787d"} value={title} />
					</div>
					<br></br>
					<div className={styles.secondLineMobile}>
						<div>
							<TextSemiBold32
								textColor={"#545454"}
								isSelected={true}
								value={subtitle}
								fontSize={24}
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
