import React from "react";
import styles from "./Section_Projects_Residentials.module.css";
import InfiniteScrollComponent from "../../molecules/Slider_Projects";
import Header_Description_Type_Project from "../../molecules/HeaderDescriptionTypeProject";
import { PageHead } from "../../atoms/PageHead/index";
import AccessAreaMenuBig from "../../atoms/AccessAreaMenuBig";
import checkIsMobile from "../../../utils/checkIsMobile";
import { useLanguage } from "../../../utils/translations/LanguageContext";
import { useTranslation } from "react-i18next";
export default function Section_Projects_Residentials_Interior() {
  const { selectedLanguage } = useLanguage();
  const { t } = useTranslation();

  const [projects] = React.useState([
    {
      _id: "5f9d5f1b9d6f6e0017f7e3a1",
      name:
        selectedLanguage === "pt"
          ? "Apartamento – Campo de Ourique"
          : "Apartment - Campo de Ourique",
      description:
        selectedLanguage === "pt"
          ? "Projeto de interiores completo de um apartamento."
          : "Complete interior design of an apartment.",
      images: [
        "https://bohocasasstorage.blob.core.windows.net/boho-casas/Lisboa2_SALA1.png",
      ],
    },
    {
      _id: "5f9d5f1b9d6f6e0017f7e3a2",
      name: selectedLanguage === "pt" ? "Cozinha - Sintra" : "Kitchen - Sintra",
      description:
        selectedLanguage === "pt"
          ? "Projeto de interiores para a cozinha de uma moradia"
          : "Interior design for the kitchen of a housing",
      images: [
        "https://bohocasasstorage.blob.core.windows.net/boho-casas/SintraCozinha.png",
      ],
    },
    {
      _id: "5f9d5f1b9d6f6e0017f7e3a3",
      name:
        selectedLanguage === "pt"
          ? "Sala de estar - Sintra"
          : "Living Room - Sintra",
      description:
        selectedLanguage === "pt"
          ? "Projeto de interiores para Sala de Estar de uma moradia"
          : "Interiors Project for the living room of a housing",
      images: [
        "https://bohocasasstorage.blob.core.windows.net/boho-casas/SalaSintra.png",
      ],
    },
  ]);

  function navigateToLink() {
    window.location.href = "/projects-and-works";
  }

  return (
    <>
      {!checkIsMobile() ? (
        <div className={styles.ContainerSection} id="ResidentialsInterior">
          <div className={styles.Pagehead}>
            <div>
              <PageHead
                text={t("WHAT WE'VE ALREADY DONE")}
                text1={t("textSectionResidentialProjectsInterior")}
              ></PageHead>
            </div>
            <div>
              <AccessAreaMenuBig
                className="access-area-menu-big-instance"
                frameClassName="design-component-instance-node"
                text={t("See Projects")}
                goTo={"ResidentialsInterior"}
              />
            </div>
          </div>
          <InfiniteScrollComponent
            listOfProjects={projects}
            backgroundColor={"#F2F2F2"}
          />
        </div>
      ) : (
        <div
          className={styles.ContainerSectionMobile}
          id="ResidentialsInterior"
        >
          <Header_Description_Type_Project
            title={t("WHAT WE'VE ALREADY DONE")}
            subtitle={t("textSectionResidentialProjectsInterior")}
          />
          <InfiniteScrollComponent listOfProjects={projects} />
          <div>
            <AccessAreaMenuBig
              className="access-area-menu-big-instance"
              frameClassName="design-component-instance-node"
              text={t("See Projects")}
              goTo={"ResidentialsInterior"}
            />
          </div>
        </div>
      )}
    </>
  );
}
