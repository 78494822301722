
import styles from "./Section_Our_Story.module.css";
import Text_Bold_14 from "../../atoms/Text_Bold_14";
import Text_Semi_Bold_35 from "../../atoms/Text_Semi_Bold_35";
import { useTranslation } from "react-i18next";
import Text_Regular_32 from "../../atoms/Text_Regular_32";
import Carousel_Know_Our_Team from "../Carousel_Know_Our_Team";
import checkIsMobile from "../../../utils/checkIsMobile";


const scrollToTopAndPlayVideo = () => {
  // Rola até o topo da página
  window.scrollTo({ top: 0, behavior: "smooth" });
};



const Section_Our_Story = () => {



  const { t } = useTranslation();
  return (
    <>
      {!checkIsMobile() ? (
        <>
          <div className={styles.lineHorizontal} id="AboutUs"></div>
          <div className={styles.lineVertical}></div>
          <div className={styles.lineVertical2}></div>
          <div className={styles.rootContainer}>
            <div className={styles.ContainerOurStory}>
              <div className={styles.ContainerOurStory1}>
                <div className={styles.label}>
                  <div className={styles.fristPartLabel}>
                    <svg
                      width="64"
                      height="1"
                      viewBox="0 0 64 1"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="64" height="1" fill="white" />
                    </svg>
                  </div>
                  <div className={styles.secondPartLabel}>
                    <Text_Bold_14 value={t('About Us')} />
                  </div>
                </div>
                <Text_Semi_Bold_35
                  value={t('About Us')}
                  isSelected={true}
                />
                <div className={styles.introduceTeam}>
                  <br />
                  <p style={{ color: "#F2F2F2" }}>{t("textIntroduceTeam1")}</p>
                  <br />
                  <p style={{ color: "#F2F2F2" }}>{t("textIntroduceTeam2")}</p>
                  <br />
                  <p style={{ color: "#F2F2F2" }}>{t("textIntroduceTeam3")}</p>
                  <br />
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div

                    onClick={scrollToTopAndPlayVideo}
                    style={{ cursor: "pointer" }}
                    className={styles.playVideo}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="79"
                      xheight="79"
                      viewBox="0 0 79 79"
                      fill="none"
                    >
                      <circle
                        opacity="0.3"
                        cx="39.5"
                        cy="39.5"
                        r="39.5"
                        fill="white"
                      />
                      <circle cx="39.5" cy="39.5" r="27.5" fill="white" />
                      <path
                        d="M47.1033 38.3882L36.3118 32.2463C35.1671 31.5928 34 32.3075 34 33.5927V46.4145C34 47.5034 34.7089 47.9999 35.3677 47.9999C35.678 47.9999 35.9941 47.902 36.3081 47.7109L47.148 41.0757C47.693 40.741 47.999 40.2455 47.9894 39.716C47.9804 39.1859 47.6579 38.7016 47.1033 38.3882Z"
                        fill="#55473D"
                      />
                    </svg>
                  </div>
                  <span style={{ color: "white", marginLeft: "20px" }}>
                    {t("textPlayVideoIntroBoho")}
                  </span>
                </div>
              </div>
              <div className={styles.ContainerOurStory2}>
                <div className={styles.card1}>
                </div>
              </div>
            </div>
            <div style={{
              height: "50px",
              background: 'transparent'
            }}></div>
            <Text_Regular_32 value={t('Meet our Team')} isSelected={true} />
            <Carousel_Know_Our_Team />
          </div>
        </>
      ) : (
        <>
          <div className={styles.rootContainerMobile} id="about">
            <div className={styles.ContainerOurStoryMobile}>
              <div className={styles.ContainerOurStory2Mobile}>
              </div>
              <div className={styles.ContainerOurStory1Mobile}>
                <div className={styles.labelMobile}>
                  <div className={styles.fristPartLabelMobile}>
                    <svg
                      width="64"
                      height="1"
                      viewBox="0 0 64 1"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="60" height="2" fill="white" />
                    </svg>
                  </div>
                  <div className={styles.secondPartLabelMobile}>
                    <Text_Bold_14 value={t('About Us')} />
                  </div>
                </div>

                <div className={styles.introduceTeamMobile}>
                  <Text_Regular_32
                    value={t('About Us')}
                    isSelected={true}
                  />
                  <br />
                  <p style={{ color: "#F2F2F2", fontSize: 14 }} >{t("textIntroduceTeam1")}</p>
                  <br />
                  <p style={{ color: "#F2F2F2", fontSize: 14 }}>{t("textIntroduceTeam2")}</p>
                  <br />
                  <p style={{ color: "#F2F2F2", fontSize: 14 }}>{t("textIntroduceTeam3")}</p>
                  <br />
                </div>
                <div
                  style={{
                    marginTop: "3rem",
                    marginBottom: "3rem",
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center"
                  }}
                >
                  <div
                    onClick={scrollToTopAndPlayVideo}
                    style={{ cursor: "pointer" }}
                    className={styles.playVideoMobile}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="79"
                      xheight="79"
                      viewBox="0 0 79 79"
                      fill="none"
                    >
                      <circle
                        opacity="0.3"
                        cx="39.5"
                        cy="39.5"
                        r="39.5"
                        fill="white"
                      />
                      <circle cx="39.5" cy="39.5" r="27.5" fill="white" />
                      <path
                        d="M47.1033 38.3882L36.3118 32.2463C35.1671 31.5928 34 32.3075 34 33.5927V46.4145C34 47.5034 34.7089 47.9999 35.3677 47.9999C35.678 47.9999 35.9941 47.902 36.3081 47.7109L47.148 41.0757C47.693 40.741 47.999 40.2455 47.9894 39.716C47.9804 39.1859 47.6579 38.7016 47.1033 38.3882Z"
                        fill="#55473D"
                      />
                    </svg>
                  </div>
                  <span style={{ color: "white", fontSize: 14 }}>
                    {t("textPlayVideoIntroBoho")}
                  </span>
                </div>
              </div>
            </div>
            <Text_Regular_32
              value={t('Meet our Team')}
              isSelected={true}
              FontSize={"24px"} />
            <Carousel_Know_Our_Team />
          </div>
        </>
      )}
    </>
  );
};

export default Section_Our_Story;
