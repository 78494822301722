import { useState } from "react";
import styles from "./Card_services.module.css";
import { useTranslation } from "react-i18next";
import checkIsMobile from "../../../utils/checkIsMobile";
import { useNavigate } from "react-router-dom";
function CardServices() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [cardOne, setCarOne] = useState(false);
  const [carTwo, setCarTwo] = useState(false);
  const [carThree, setCarThree] = useState(false);
  const [carFour, setCarFour] = useState(false);
  const handleClick = () => {
    navigate("/services/");
    setTimeout(() => {
      document.getElementById("#services1").scrollIntoView({
        behavior: "smooth",
      });
    }, 200);
  };
  const handleClickTwo = () => {
    navigate("/services/");
    setTimeout(() => {
      document.getElementById("#services2").scrollIntoView({
        behavior: "smooth",
      });
    }, 200);
  };

  const handleClickThree = () => {
    navigate("/services/");
    setTimeout(() => {
      document.getElementById("#services3").scrollIntoView({
        behavior: "smooth",
      });
    }, 200);
  };

  const handleClickFour = () => {
    navigate("/services/");
    setTimeout(() => {
      document.getElementById("#services4").scrollIntoView({
        behavior: "smooth",
      });
    }, 200);
  };

  const handleClickFive = () => {
    navigate("/services/");
    setTimeout(() => {
      document.getElementById("#services5").scrollIntoView({
        behavior: "smooth",
      });
    }, 200);
  };

  return (
    <>
      {!checkIsMobile() ? (
        <div id="cardHomeServicesOne" className={styles.carContainer}>
          <div
            className={styles.cardItem}
            to={"/blog/ResidentialsArchiteture"}
            onClick={() => {
              handleClick();
            }}
            onMouseOver={() => {
              setCarOne(true);
            }}
            onMouseLeave={() => {
              setCarOne(false);
            }}
          >
            <div className={styles.circle}>
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="32" cy="32" r="32" fill="#EDFDF8" />
                <path
                  d="M15.3335 47.6665H48.6668"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.9155 47.6673L16.9989 27.6172C16.9989 26.6005 17.4822 25.634 18.2822 25.0006L29.9489 15.9173C31.1489 14.984 32.8322 14.984 34.0489 15.9173L45.7155 24.984C46.5322 25.6173 46.9989 26.5839 46.9989 27.6172V47.6673"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linejoin="round"
                />
                <path
                  d="M33.6668 39.3335H30.3335C28.9502 39.3335 27.8335 40.4502 27.8335 41.8335V47.6668H36.1668V41.8335C36.1668 40.4502 35.0502 39.3335 33.6668 39.3335Z"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linejoin="round"
                />
                <path
                  d="M27.8335 33.9168H24.5002C23.5835 33.9168 22.8335 33.1668 22.8335 32.2502V29.7502C22.8335 28.8335 23.5835 28.0835 24.5002 28.0835H27.8335C28.7502 28.0835 29.5002 28.8335 29.5002 29.7502V32.2502C29.5002 33.1668 28.7502 33.9168 27.8335 33.9168Z"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linejoin="round"
                />
                <path
                  d="M39.5 33.9168H36.1667C35.25 33.9168 34.5 33.1668 34.5 32.2502V29.7502C34.5 28.8335 35.25 28.0835 36.1667 28.0835H39.5C40.4167 28.0835 41.1667 28.8335 41.1667 29.7502V32.2502C41.1667 33.1668 40.4167 33.9168 39.5 33.9168Z"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linejoin="round"
                />
                <path
                  d="M43.667 22.6665L43.617 17.6665H36.2837"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>

            <h3 className={styles.titleCard} style={{ textDecoration: "none" }}>
              {t("textTitleCard1")}
            </h3>
            <p className={styles.textDescription}>
              {t("textDescriptionCard1")}
            </p>
          </div>
          <div
            className={styles.cardItem}
            to="/blog/ResidentialsInterior"
            onClick={() => {
              handleClickTwo();
            }}
            onMouseOver={() => {
              setCarOne(true);
            }}
            onMouseLeave={() => {
              setCarOne(false);
            }}
          >
            <div className={styles.circle}>
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="32" cy="32" r="32" fill="#EDFDF8" />
                <path
                  d="M15.3335 47.6665H48.6668"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.9155 47.6673L16.9989 27.6172C16.9989 26.6005 17.4822 25.634 18.2822 25.0006L29.9489 15.9173C31.1489 14.984 32.8322 14.984 34.0489 15.9173L45.7155 24.984C46.5322 25.6173 46.9989 26.5839 46.9989 27.6172V47.6673"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linejoin="round"
                />
                <path
                  d="M33.6668 39.3335H30.3335C28.9502 39.3335 27.8335 40.4502 27.8335 41.8335V47.6668H36.1668V41.8335C36.1668 40.4502 35.0502 39.3335 33.6668 39.3335Z"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linejoin="round"
                />
                <path
                  d="M27.8335 33.9168H24.5002C23.5835 33.9168 22.8335 33.1668 22.8335 32.2502V29.7502C22.8335 28.8335 23.5835 28.0835 24.5002 28.0835H27.8335C28.7502 28.0835 29.5002 28.8335 29.5002 29.7502V32.2502C29.5002 33.1668 28.7502 33.9168 27.8335 33.9168Z"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linejoin="round"
                />
                <path
                  d="M39.5 33.9168H36.1667C35.25 33.9168 34.5 33.1668 34.5 32.2502V29.7502C34.5 28.8335 35.25 28.0835 36.1667 28.0835H39.5C40.4167 28.0835 41.1667 28.8335 41.1667 29.7502V32.2502C41.1667 33.1668 40.4167 33.9168 39.5 33.9168Z"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linejoin="round"
                />
                <path
                  d="M43.667 22.6665L43.617 17.6665H36.2837"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>

            <h3 className={styles.titleCard}>{t("textTitleCard5")}</h3>
            <p className={styles.textDescription}>
              {t("textDescriptionCard5")}
            </p>
          </div>
          <div
            className={styles.cardItem}
            to="/blog/Commercial_Projects_And_Franchises"
            onClick={() => {
              handleClickThree();
            }}
            onMouseOver={() => {
              setCarTwo(true);
            }}
            onMouseLeave={() => {
              setCarTwo(false);
            }}
          >
            <div className={styles.circle}>
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="32" cy="32" r="32" fill="#EDFDF8" />
                <path
                  d="M35.8347 48.6674H21.8013C19.868 48.6674 18.2847 47.1174 18.2847 45.2174V20.4841C18.2847 16.1174 21.5347 14.1341 25.518 16.0841L32.918 19.7174C34.518 20.5007 35.8347 22.5841 35.8347 24.3507V48.6674Z"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M51.6168 37.1V43.4C51.6168 47 49.9502 48.6667 46.3502 48.6667H35.8335V29.3667L36.6168 29.5334L44.1168 31.2167L47.5002 31.9667C49.7002 32.45 51.5002 33.5834 51.6002 36.7834C51.6168 36.8834 51.6168 36.9834 51.6168 37.1Z"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M24.1665 27H29.9498"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M24.1665 33.667H29.9498"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M44.1177 31.2165V36.5832C44.1177 38.6499 42.4343 40.3332 40.3677 40.3332C38.301 40.3332 36.6177 38.6499 36.6177 36.5832V29.5332L44.1177 31.2165Z"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M51.601 36.784C51.501 38.7506 49.8677 40.334 47.8677 40.334C45.801 40.334 44.1177 38.6506 44.1177 36.584V31.2173L47.501 31.9673C49.701 32.4506 51.501 33.584 51.601 36.784Z"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <h3 className={styles.titleCard}>{t("textTitleCard2")}</h3>
            <p className={styles.textDescription}>
              {t("textDescriptionCard2")}
            </p>
          </div>
          <div
            className={styles.cardItem}
            to="/blog/Projects_And_Constructions_In_LSF"
            onClick={() => {
              handleClickFour();
            }}
            onMouseOver={() => {
              setCarThree(true);
            }}
            onMouseLeave={() => {
              setCarThree(false);
            }}
          >
            <div className={styles.circle}>
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="32" cy="32" r="32" fill="#EDFDF8" />
                <path
                  d="M15.3335 48.6665H48.6668"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M32 15.3335C34.6667 16.4002 37.6667 16.4002 40.3333 15.3335V20.3335C37.6667 21.4002 34.6667 21.4002 32 20.3335V15.3335Z"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M32 20.3335V25.3335"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M40.3332 25.3335H23.6665C20.3332 25.3335 18.6665 27.0002 18.6665 30.3335V48.6668H45.3332V30.3335C45.3332 27.0002 43.6665 25.3335 40.3332 25.3335Z"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19.6333 32H44.3666"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M25.3169 32V48.6667"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linejoin="round"
                />
                <path
                  d="M31.9834 32V48.6667"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linejoin="round"
                />
                <path
                  d="M38.6499 32V48.6667"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <h3 className={styles.titleCard}>{t("textTitleCard3")}</h3>
            <p className={styles.textDescription}>
              {t("textDescriptionCard3")}
            </p>
          </div>
          <div
            className={styles.cardItem}
            to="/blog/Remodeling"
            onClick={() => {
              handleClickFive();
            }}
            onMouseOver={() => {
              setCarFour(true);
            }}
            onMouseLeave={() => {
              setCarFour(false);
            }}
          >
            <div className={styles.circle}>
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="32" cy="32" r="32" fill="#EDFDF8" />
                <g clip-path="url(#clip0_362_2676)">
                  <path
                    d="M27.8332 44.5002V42.0002H19.4998C18.5832 42.0002 17.7498 41.6335 17.1498 41.0168C16.5332 40.4168 16.1665 39.5835 16.1665 38.6668C16.1665 36.9502 17.4998 35.5168 19.1832 35.3502C19.2832 35.3335 19.3832 35.3335 19.4998 35.3335H44.4998C44.6165 35.3335 44.7165 35.3335 44.8165 35.3502C45.6165 35.4168 46.3165 35.7668 46.8498 36.3168C47.5332 36.9835 47.8998 37.9335 47.8165 38.9668C47.6665 40.7168 46.0832 42.0002 44.3165 42.0002H36.1665V44.5002C36.1665 46.8002 34.2998 48.6668 31.9998 48.6668C29.6998 48.6668 27.8332 46.8002 27.8332 44.5002Z"
                    stroke="#51787d"
                    stroke-width="2.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M45.6165 20.8335L44.8165 35.3502C44.7165 35.3335 44.6165 35.3335 44.4998 35.3335H19.4998C19.3831 35.3335 19.2831 35.3335 19.1831 35.3502L18.3831 20.8335C18.0831 17.8835 20.3998 15.3335 23.3498 15.3335H40.6498C43.5998 15.3335 45.9165 17.8835 45.6165 20.8335Z"
                    stroke="#51787d"
                    stroke-width="2.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M25.3169 15.3335V23.6668"
                    stroke="#51787d"
                    stroke-width="2.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M32 15.3335V18.6668"
                    stroke="#51787d"
                    stroke-width="2.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_362_2676">
                    <rect
                      width="40"
                      height="40"
                      fill="white"
                      transform="translate(12 12)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <h3 className={styles.titleCard}>{t("textTitleCard4")}</h3>
            <p className={styles.textDescription}>
              {t("textDescriptionCard4")}
            </p>
          </div>
        </div>
      ) : (
        <div className={styles.carContainerMobile}>
          <div
            className={styles.cardItem}
            to={"/blog/ResidentialsArchiteture"}
            onClick={() => {
              handleClick();
            }}
            onMouseOver={() => {
              setCarOne(true);
            }}
            onMouseLeave={() => {
              setCarOne(false);
            }}
          >
            <div className={styles.circle}>
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="32" cy="32" r="32" fill="#EDFDF8" />
                <path
                  d="M15.3335 47.6665H48.6668"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.9155 47.6673L16.9989 27.6172C16.9989 26.6005 17.4822 25.634 18.2822 25.0006L29.9489 15.9173C31.1489 14.984 32.8322 14.984 34.0489 15.9173L45.7155 24.984C46.5322 25.6173 46.9989 26.5839 46.9989 27.6172V47.6673"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linejoin="round"
                />
                <path
                  d="M33.6668 39.3335H30.3335C28.9502 39.3335 27.8335 40.4502 27.8335 41.8335V47.6668H36.1668V41.8335C36.1668 40.4502 35.0502 39.3335 33.6668 39.3335Z"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linejoin="round"
                />
                <path
                  d="M27.8335 33.9168H24.5002C23.5835 33.9168 22.8335 33.1668 22.8335 32.2502V29.7502C22.8335 28.8335 23.5835 28.0835 24.5002 28.0835H27.8335C28.7502 28.0835 29.5002 28.8335 29.5002 29.7502V32.2502C29.5002 33.1668 28.7502 33.9168 27.8335 33.9168Z"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linejoin="round"
                />
                <path
                  d="M39.5 33.9168H36.1667C35.25 33.9168 34.5 33.1668 34.5 32.2502V29.7502C34.5 28.8335 35.25 28.0835 36.1667 28.0835H39.5C40.4167 28.0835 41.1667 28.8335 41.1667 29.7502V32.2502C41.1667 33.1668 40.4167 33.9168 39.5 33.9168Z"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linejoin="round"
                />
                <path
                  d="M43.667 22.6665L43.617 17.6665H36.2837"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>

            <h3 className={styles.titleCard}>{t("textTitleCard1")}</h3>
            <p className={styles.textDescription}>
              {t("textDescriptionCard1")}
            </p>
          </div>
          <div
            className={styles.cardItem}
            to="/blog/ResidentialsInterior"
            onClick={() => {
              handleClickTwo();
            }}
            onMouseOver={() => {
              setCarOne(true);
            }}
            onMouseLeave={() => {
              setCarOne(false);
            }}
          >
            <div className={styles.circle}>
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="32" cy="32" r="32" fill="#EDFDF8" />
                <path
                  d="M15.3335 47.6665H48.6668"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.9155 47.6673L16.9989 27.6172C16.9989 26.6005 17.4822 25.634 18.2822 25.0006L29.9489 15.9173C31.1489 14.984 32.8322 14.984 34.0489 15.9173L45.7155 24.984C46.5322 25.6173 46.9989 26.5839 46.9989 27.6172V47.6673"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linejoin="round"
                />
                <path
                  d="M33.6668 39.3335H30.3335C28.9502 39.3335 27.8335 40.4502 27.8335 41.8335V47.6668H36.1668V41.8335C36.1668 40.4502 35.0502 39.3335 33.6668 39.3335Z"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linejoin="round"
                />
                <path
                  d="M27.8335 33.9168H24.5002C23.5835 33.9168 22.8335 33.1668 22.8335 32.2502V29.7502C22.8335 28.8335 23.5835 28.0835 24.5002 28.0835H27.8335C28.7502 28.0835 29.5002 28.8335 29.5002 29.7502V32.2502C29.5002 33.1668 28.7502 33.9168 27.8335 33.9168Z"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linejoin="round"
                />
                <path
                  d="M39.5 33.9168H36.1667C35.25 33.9168 34.5 33.1668 34.5 32.2502V29.7502C34.5 28.8335 35.25 28.0835 36.1667 28.0835H39.5C40.4167 28.0835 41.1667 28.8335 41.1667 29.7502V32.2502C41.1667 33.1668 40.4167 33.9168 39.5 33.9168Z"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linejoin="round"
                />
                <path
                  d="M43.667 22.6665L43.617 17.6665H36.2837"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <h3 className={styles.titleCard}>{t("textTitleCard5")}</h3>
            <p className={styles.textDescription}>
              {t("textDescriptionCard5")}
            </p>
          </div>
          <div
            className={styles.cardItem}
            to="/blog/Commercial_Projects_And_Franchises"
            onClick={() => {
              handleClickThree();
            }}
            onMouseOver={() => {
              setCarTwo(true);
            }}
            onMouseLeave={() => {
              setCarTwo(false);
            }}
          >
            <div className={styles.circle}>
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="32" cy="32" r="32" fill="#EDFDF8" />
                <path
                  d="M35.8347 48.6674H21.8013C19.868 48.6674 18.2847 47.1174 18.2847 45.2174V20.4841C18.2847 16.1174 21.5347 14.1341 25.518 16.0841L32.918 19.7174C34.518 20.5007 35.8347 22.5841 35.8347 24.3507V48.6674Z"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M51.6168 37.1V43.4C51.6168 47 49.9502 48.6667 46.3502 48.6667H35.8335V29.3667L36.6168 29.5334L44.1168 31.2167L47.5002 31.9667C49.7002 32.45 51.5002 33.5834 51.6002 36.7834C51.6168 36.8834 51.6168 36.9834 51.6168 37.1Z"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M24.1665 27H29.9498"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M24.1665 33.667H29.9498"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M44.1177 31.2165V36.5832C44.1177 38.6499 42.4343 40.3332 40.3677 40.3332C38.301 40.3332 36.6177 38.6499 36.6177 36.5832V29.5332L44.1177 31.2165Z"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M51.601 36.784C51.501 38.7506 49.8677 40.334 47.8677 40.334C45.801 40.334 44.1177 38.6506 44.1177 36.584V31.2173L47.501 31.9673C49.701 32.4506 51.501 33.584 51.601 36.784Z"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <h3 className={styles.titleCard}>{t("textTitleCard2")}</h3>
            <p className={styles.textDescription}>
              {t("textDescriptionCard2")}
            </p>
          </div>
          <div
            className={styles.cardItem}
            to="/blog/Projects_And_Constructions_In_LSF"
            onClick={() => {
              handleClickFour();
            }}
            onMouseOver={() => {
              setCarThree(true);
            }}
            onMouseLeave={() => {
              setCarThree(false);
            }}
          >
            <div className={styles.circle}>
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="32" cy="32" r="32" fill="#EDFDF8" />
                <path
                  d="M15.3335 48.6665H48.6668"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M32 15.3335C34.6667 16.4002 37.6667 16.4002 40.3333 15.3335V20.3335C37.6667 21.4002 34.6667 21.4002 32 20.3335V15.3335Z"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M32 20.3335V25.3335"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M40.3332 25.3335H23.6665C20.3332 25.3335 18.6665 27.0002 18.6665 30.3335V48.6668H45.3332V30.3335C45.3332 27.0002 43.6665 25.3335 40.3332 25.3335Z"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19.6333 32H44.3666"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M25.3169 32V48.6667"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linejoin="round"
                />
                <path
                  d="M31.9834 32V48.6667"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linejoin="round"
                />
                <path
                  d="M38.6499 32V48.6667"
                  stroke="#51787d"
                  stroke-width="2.5"
                  stroke-miterlimit="10"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <h3 className={styles.titleCard}>{t("textTitleCard3")}</h3>
            <p className={styles.textDescription}>
              {t("textDescriptionCard3")}
            </p>
          </div>
          <div
            className={styles.cardItem}
            to="/blog/Remodeling"
            onClick={() => {
              handleClickFive();
            }}
            onMouseOver={() => {
              setCarFour(true);
            }}
            onMouseLeave={() => {
              setCarFour(false);
            }}
          >
            <div className={styles.circle}>
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="32" cy="32" r="32" fill="#EDFDF8" />
                <g clip-path="url(#clip0_362_2676)">
                  <path
                    d="M27.8332 44.5002V42.0002H19.4998C18.5832 42.0002 17.7498 41.6335 17.1498 41.0168C16.5332 40.4168 16.1665 39.5835 16.1665 38.6668C16.1665 36.9502 17.4998 35.5168 19.1832 35.3502C19.2832 35.3335 19.3832 35.3335 19.4998 35.3335H44.4998C44.6165 35.3335 44.7165 35.3335 44.8165 35.3502C45.6165 35.4168 46.3165 35.7668 46.8498 36.3168C47.5332 36.9835 47.8998 37.9335 47.8165 38.9668C47.6665 40.7168 46.0832 42.0002 44.3165 42.0002H36.1665V44.5002C36.1665 46.8002 34.2998 48.6668 31.9998 48.6668C29.6998 48.6668 27.8332 46.8002 27.8332 44.5002Z"
                    stroke="#51787d"
                    stroke-width="2.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M45.6165 20.8335L44.8165 35.3502C44.7165 35.3335 44.6165 35.3335 44.4998 35.3335H19.4998C19.3831 35.3335 19.2831 35.3335 19.1831 35.3502L18.3831 20.8335C18.0831 17.8835 20.3998 15.3335 23.3498 15.3335H40.6498C43.5998 15.3335 45.9165 17.8835 45.6165 20.8335Z"
                    stroke="#51787d"
                    stroke-width="2.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M25.3169 15.3335V23.6668"
                    stroke="#51787d"
                    stroke-width="2.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M32 15.3335V18.6668"
                    stroke="#51787d"
                    stroke-width="2.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_362_2676">
                    <rect
                      width="40"
                      height="40"
                      fill="white"
                      transform="translate(12 12)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <h3 className={styles.titleCard}>{t("textTitleCard4")}</h3>
            <p className={styles.textDescription}>
              {t("textDescriptionCard4")}
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default CardServices;
