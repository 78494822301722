import styled from "styled-components";

const ContainerSectionHero = styled.div`
	display: flex;
	background: url("/images/services.png");
	background-size: cover;
	background-repeat: no-repeat;
`;

const SectionHeroBody = styled.div`
	display: flex;
	flex-direction: column;
	height: 100vh;
	width: 100vw;
`;

const LineVertical = styled.div`
	border-right: 1px solid rgba(255, 255, 255, 0.24); /* Cor da borda com opacidade */
	margin-top: 0;
	width: 20px;
	height: 100vh;
	opacity: 0.24;
	z-index: 1;
`;

const LineVertical2 = styled.div`
	border-left: 1px solid rgba(255, 255, 255, 0.24); /* Cor da borda com opacidade */
	margin-top: 0;
	width: 20px;
	height: 100vh;
	opacity: 0.24;
	z-index: 1;
`;

const LineHorizontal = styled.div`
	z-index: 1;
	border-bottom: 1px solid rgba(255, 255, 255, 0.24); /* Cor da borda com opacidade */
	margin-top: 30px;
	position: absolute;
	width: 100vw;
	height: 49px;
	opacity: 0.24;
	z-index: 1;
`;
const TextSectionHeroBody = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 80px;
	margin-left: 28px;
	justify-content: center;
	height: 50%;
	width: 80%;
	z-index: 1;
`;

const RegularH4 = styled.h4`
	width: 100%;
	color: var(--token-colors-text-plain-text-dark, #fff);
	font-family: Maven Pro;
	font-size: 32px;
	font-style: normal;
	font-weight: 400;
	line-height: 40px;
	z-index: 1;
`;
const DivGray = styled.div` 
    background-color: rgb(0, 0, 0, 0.5);
	position: absolute;
	width: 100vw;
	height: 100vh;
	z-index: 0;
`;
export {
	ContainerSectionHero,
	SectionHeroBody,
	LineVertical,
	LineVertical2,
	LineHorizontal,
	TextSectionHeroBody,
	RegularH4,
	DivGray
};
