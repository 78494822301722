import React from "react";
import HomeTemplate from "../templates/HomeTemplate";

const HomePage = () => {
	return (
		<div style={{ overflow: "hidden" }}>
			<HomeTemplate />
		</div>
	);
};

export default HomePage;
