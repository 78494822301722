import ButtonBasicWhiteDefault from "../../components/atoms/ButtonBasicWhiteDefault";
import GhostHead from "../../components/atoms/GhostHead";
import Text_Regular_32 from "../../components/atoms/Text_Regular_32";
import Text_Regular_14 from "../../components/atoms/Text_Regular_14";
import Header from "../../components/organisms/Header";
import { useNavigate } from "react-router-dom";

export default function NotFoundTemplate() {
  const navigate = useNavigate();

  return (
    <div
      style={{
        overflow: "hidden",
        backgroundColor: "#556F70",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Header />
      <div
        style={{ height: "1px", width: "100%", background: "#84a8a9" }}
      ></div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "10vw",
          textAlign: "center",
        }}
      >
        <GhostHead />
        <Text_Regular_32 value={"Error 404"} isSelected />
        <Text_Regular_14
          value={"Oops! A página que você buscou não está disponível"}
          isSelected
        />
        <ButtonBasicWhiteDefault
          title={"Voltar para o Inicio"}
          onClick={() => {
            navigate("/");
          }}
        />
      </div>
    </div>
  );
}
