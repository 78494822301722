import React, { useState } from "react";
import { Form, Input, Button, Select, DatePicker, message, Space } from "antd";
import { ProjectService } from "boho-casas-client-module/client-web/services/ProjectService";
import UploadImages from "../Upload";
const CreateProject = () => {
    const { Option } = Select;
    const [form] = Form.useForm();
    const [images, setImages] = useState([])
    const [newImages, setnewImages] = useState([])
    const handleDataReceivedImages = (values) => {
        setImages(values)
    }
    const onFinish = (values) => {
        console.log("Onfinish", values)
        addNewProject(values);
    };
    const onFinishFailed = (values) => {
        console.log("Onfinishfailed", values)
        message.error("Erro ao adicionar o projeto");
    };
    const addNewProject = (projectData) => {
        console.log("AddNewProject", projectData)
        ProjectService.addNewProject(
            {
                title_pt: projectData.title_pt,
                title_en: projectData.title_en,
                description_pt: projectData.description_pt,
                description_en: projectData.description_en,
                service: "",
                location: projectData.location,
                team_member: projectData.team_member,
                tags: projectData.tags,
                images: images,
                client_name: "",
                created_project: "",
                subtitle_en: "",
                subtitle_pt: ""
            }
        )
            .then((response) => {
                message.success("Projeto adicionado com sucesso");
                form.resetFields();
                window.location.reload();
            })
            .catch((error) => {
                message.error("Erro ao adicionar o projeto");
                console.error(error);
            });
    };
    return (
        <div style={{ display: 'flex', width: '80%', height: '800px', justifyContent: 'center', alignItems: 'flex-start' }}>
            <div style={{ width: '30%', maxHeight: '600px', overflow: 'auto' }}>
                <h1 style={{ marginBottom: '1rem' }}>Imagens:</h1><UploadImages name="images" onDataReceived={handleDataReceivedImages} setImagesList={setImages} />
            </div>
            <Form
                form={form}
                style={{ width: '50%', }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        gap: 20
                    }}
                >
                    <div style={{ flex: 1 }}>
                        <Form.Item name="title_pt" label="Título em Português" rules={[{ required: true, message: 'Por favor, insira o título em português.' }]}>
                            <Input style={{}} />
                        </Form.Item>
                        {/* <Form.Item name="subtitle_pt" label="Subtítulo em Português" rules={[{ required: true, message: 'Por favor, insira o subtítulo em português.' }]}>
                            <Input />
                        </Form.Item> */}
                        <Form.Item name="description_pt" label="Descrição em Português" rules={[{ required: true, message: 'Por favor, insira a descrição em português.' }]}>
                            <Input.TextArea />
                        </Form.Item>
                        {/* <Form.Item name="client_name" label="Nome do cliente" rules={[{ required: true, message: 'Por favor, insira o nome do cliente.' }]}>
                            <Input />
                        </Form.Item> */}
                        <Form.Item name="team_member" label="Time Envolvido" rules={[{ required: true, message: 'Por favor, insira o time envolvido.' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="location" label="Localização" rules={[{ required: true, message: 'Por favor, insira a localização.' }]}>
                            <Input style={{}} />
                        </Form.Item>
                        {/* <Form.Item name="service" label="Etiqueta">
                            <Input />
                        </Form.Item> */}
                    </div>
                    <div style={{ flex: 1 }}>
                        <Form.Item name="title_en" label="Título em Inglês" rules={[{ required: true, message: 'Por favor, insira o título em inglês.' }]}>
                            <Input />
                        </Form.Item>
                        {/* <Form.Item name="subtitle_en" label="Subtítulo em Inglês" rules={[{ required: true, message: 'Por favor, insira o subtítulo em inglês.' }]}>
                            <Input />
                        </Form.Item> */}
                        <Form.Item name="description_en" label="Descrição em Inglês" rules={[{ required: true, message: 'Por favor, insira a descrição em inglês.' }]}>
                            <Input.TextArea />
                        </Form.Item>
                        {/* <Form.Item name="date_created_project" label="Data da Criação do Projeto" >
                            <DatePicker placeholder="Selecione a data" style={{ width: '100%' }} />
                        </Form.Item> */}

                        <Form.Item name="tags" label="Tags" rules={[{ required: true, message: 'Por favor, escolha as tags.' }]}>
                            <Select
                                mode="multiple"
                                placeholder="Por favor, escolha as tags"
                                style={{ width: '100%' }}
                            >
                                <Option value="LSF" label="LSF">
                                    <Space>
                                        LSF
                                    </Space>
                                </Option>
                                <Option value="Remodelações" label="Remodelações">
                                    <Space>
                                        Remodelações
                                    </Space>
                                </Option>
                                <Option value="Comerciais e franquias" label="Comerciais e franquias">
                                    <Space>
                                        Comerciais e franquias
                                    </Space>
                                </Option>
                                {/* <Option value="Residenciais" label="Residenciais">
                                    <Space>
                                        Residenciais
                                    </Space>
                                </Option> */}
                                <Option value="Interior" label="Interior">
                                    <Space>
                                        Residenciais de Interior
                                    </Space>
                                </Option>
                                <Option value="Arquitetura" label="Arquitetura">
                                    <Space>
                                        Residenciais de Arquitetura
                                    </Space>
                                </Option>
                            </Select>
                        </Form.Item>
                    </div>
                </div>
                <div
                    style={{
                        margin: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ width: '450px', height: 50, backgroundColor: '#51787d', }}>
                            Crie um novo projeto
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
};
export default CreateProject;
