import React from 'react'
import SectionHeroProjectsResidentials from '../../components/organisms/SectionHeroProjectsResidentials'
import FooterWeb from '../../components/organisms/FooterWeb/FooterWeb'
import Section_Contact_Us from '../../components/organisms/SectionContactUs'
import GoogleMap from "../../components/organisms/MapContainer";
import SliderModelWebProjectsResidentials from '../../components/organisms/SliderModelWebProjectsResidentials';
import CardsRelatedProjects from '../../components/molecules/CardsRelatedProjects';
import { Hidden } from '@material-ui/core';


export default function ResidentialProjectsPageTemplate() {
    return (
        <div style={{ overflow: "Hidden", width: "100%" }}>
            <SectionHeroProjectsResidentials />
            <SliderModelWebProjectsResidentials customColor="#F2F2F2" />
            <CardsRelatedProjects backgroundColor={"#fff"} />
            <Section_Contact_Us />
            <div style={{ height: "627px", width: "100vw" }}>
                <GoogleMap />
            </div>
            <FooterWeb />
        </div>
    )
}
