import React from "react";
import Slider from "../Slider/index";
import Header from "../Header/index";
import styles from "./Section_Home.module.css";
import checkIsMobile from "../../../utils/checkIsMobile";
export default function   Section_Home() {
  return (
    <>
      {!checkIsMobile() ? (
        <div className={styles.containerSectionHome}>
          <div className={styles.lineHorizontal}></div>
          <div className={styles.lineVertical}></div>
          <div className={styles.section}>
            <Header />
            <Slider />
          </div>
          <div className={styles.lineVertical2}></div>
        </div>
      ) : (
        <div className={styles.containerSectionHomeMobile}>
          <div className={styles.sectionMobile}>
            <Header />
            <Slider />
          </div>
        </div>
      )}
    </>
  );
}
