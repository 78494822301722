import React from "react";
import Section_Contact_Us from "../../components/organisms/SectionContactUs";
import SectionHeroProjectsResidentials from "../../components/organisms/SectionHeroProjectsResidentials";
import CardsRelatedProjects from '../../components/molecules/CardsRelatedProjects';
import SliderModelProjectsResidentialsMobile from "../../components/organisms/SliderModelProjectsResidentialsMobile";
import SectionHeroProjectsResidentialsMobile from "../../components/organisms/SectionHeroProjectsResidentialsMobile";
import GoogleMap from "../../components/organisms/MapContainer";
import Footer from "../../components/organisms/Footer";

export default function ResidentialProjectsPageMobileTemplate() {
  return (
    <div style={{ overflow: "hidden" }}>
      <SectionHeroProjectsResidentialsMobile />
      <SliderModelProjectsResidentialsMobile customColor="#F2F2F2" />
      <div style={{ height: "100px" }}></div>
      <CardsRelatedProjects backgroundColor={"#fff"} />
      <Section_Contact_Us />
      <div style={{ height: "100vw", width: "100vw" }}>
        <GoogleMap />
      </div>
      <Footer />
    </div>
  );
}
