import React from 'react'
import CommercialProjectsAndFranchisesPageMobileTemplate from '../templates/CommercialProjectsAndFranchisesPageMobileTemplate'


export default function CommercialProjectsAndFranchisesPageMobile() {
    return (
        <>
            <CommercialProjectsAndFranchisesPageMobileTemplate />
        </>
    )
}
