import { useTranslation } from "react-i18next";
import Header from "../Header/index";
import Aside_Bar_Social_Media from "./../../molecules/Aside_Bar_Social_Media";
import {
	ContainerSectionHero,
	LineHorizontal,
	LineVertical,
	LineVertical2,
	SectionHeroBody,
	TextSectionHeroBody,
	RegularH4,
	TextSectionHeroBodyMobile,
	RegularH4Mobile,
	ContainerSectionHeroMobile,
	SectionHeroBodyMobile,
} from "./SectionHeroAboutUs.Styles";
import RegularH1 from "./../../atoms/TextStyleRegular/RegularH1/";
import SemiBoldLabel from "./../../atoms/TextStyleSemiBold/SemiBoldLabel";
import checkIsMobile from "../../../utils/checkIsMobile";
import { DivGray } from "../HeroSectionServices/HeroSectionServices.Styles";
export default function Section_Home() {
	const scrollToNextSection = () => {
		// Find the next section element
		const nextSection = document.querySelector("#aboutUs");

		if (nextSection) {
			nextSection.scrollIntoView({ behavior: "smooth" });
		}
	};
	const { t } = useTranslation();

	return (
		<>
			{!checkIsMobile() ? (
				<ContainerSectionHero>
					<LineHorizontal></LineHorizontal>
					<LineVertical></LineVertical>
					<SectionHeroBody>
						<Header />
						<TextSectionHeroBody>
							<SemiBoldLabel value={"BohoCasas"} isSelected={true} />
							<RegularH1
								value={t("welcomeTextAboutUs")}
								isSelected={true}
								textColor={"var(--token-colors-text-plain-text-dark, #FFF)"}
							/>
							<br></br>
							<RegularH4>
								<p>
									{t(
										"BohoCasas® started in 2019, bringing the LSF concept from Brazil to Portugal. We offer complete services, from design to construction, with a multidisciplinary team. We turn dreams into reality."
									)} <br /><br />
									<a href="#aboutUs" style={{ cursor: 'pointer', color: "#fff", textDecoration: 'none' }}>{t('Read More')}</a>
								</p>

							</RegularH4>
						</TextSectionHeroBody>
						<Aside_Bar_Social_Media />
						<div
							style={{
								margin: "auto",
								display: "flex",
								cursor: "pointer",
								width: "100%",
								justifyContent: "center",
								display: "flex",
								zIndex: 1,
							}}
							onClick={scrollToNextSection}
						>
							<div>
								<img src="scroll.png" height={78} />
							</div>
						</div>
					</SectionHeroBody>
					<LineVertical2></LineVertical2>
					<DivGray />
				</ContainerSectionHero>
			) : (
				<>
					<ContainerSectionHeroMobile>
						<DivGray />
						<Header />
						<SectionHeroBodyMobile>
							<TextSectionHeroBodyMobile>
								<SemiBoldLabel value={"Boho Casas"} isSelected={true} />
								<RegularH1
									value={t("welcomeTextAboutUs")}
									isSelected={true}
									textColor={"var(--token-colors-text-plain-text-dark, #FFF)"}
								/>
								<RegularH4Mobile>
									<p>
										{t(
											"BohoCasas® started in 2019, bringing the LSF concept from Brazil to Portugal. We offer complete services, from design to construction, with a multidisciplinary team. We turn dreams into reality."
										)}
									</p>

								</RegularH4Mobile>
							</TextSectionHeroBodyMobile>
						</SectionHeroBodyMobile>
					</ContainerSectionHeroMobile>
				</>
			)}
		</>
	);
}
