import React from "react";
import styles from "./Section_Commercial__Projects_And_Constructions_In_LSF.module.css";
import InfiniteScrollComponent from "../../molecules/Slider_Projects";
import { useTranslation } from "react-i18next";
import { PageHead } from "./../../atoms/PageHead/index";
import AccessAreaMenuBig from "../../atoms/AccessAreaMenuBig";
import { useLanguage } from "../../../utils/translations/LanguageContext";
import checkIsMobile from "../../../utils/checkIsMobile";
import Header_Description_Type_Project from "../../molecules/HeaderDescriptionTypeProject";
export default function Section_Commercial__Projects_And_Constructions_In_LSF() {
  const { selectedLanguage } = useLanguage();
  const { t } = useTranslation();

  const [projects] = React.useState([
    {
      _id: "5f9d5f1b9d6f6e0017f7e3a1",
      name:
        selectedLanguage === "pt" ? "Condomínio Açores" : "Condominium Azores",
      description:
        selectedLanguage === "pt"
          ? "Projeto e acompanhamento de construção de um condomínio de casas para fins turísticos com área de lazer comum em LSF"
          : "Design and monitoring of construction of a condominium of houses for tourist purposes with common leisure area in LSF",
      images: [
        "https://bohocasasstorage.blob.core.windows.net/boho-casas/Cena1_trat.png",
      ],
    },
    {
      _id: "5f9d5f1b9d6f6e0017f7e3a2",
      name:
        selectedLanguage === "pt"
          ? "Projeto Padrão - Bohocasas Up"
          : "Standard Project - Bohocasas Up",
      description:
        selectedLanguage === "pt"
          ? "Projeto especial para construção em LSF"
          : "Special Project for Construction in LSF",
      images: [
        "https://bohocasasstorage.blob.core.windows.net/boho-casas/Up1.2_imagem1.png",
      ],
    },
    {
      _id: "5f9d5f1b9d6f6e0017f7e3a3",
      name:
        selectedLanguage === "pt"
          ? "Projeto padrão - Bohocasas Glamping"
          : "Standard Project - Bohocasas Glamping",
      description:
        selectedLanguage === "pt"
          ? "Projeto especial para construção em LSF para fins turísticos Local: Terrenos planos, quintas e zonas de interesse turístico."
          : "Special LSF construction project for local tourist purposes: flat land, farms and zones of tourist interest.",
      images: [
        "https://bohocasasstorage.blob.core.windows.net/boho-casas/Diapositivo1.png",
      ],
    },
  ]);
  return (
    <>
      {!checkIsMobile() ? (
        <div
          className={styles.ContainerSection}
          id="Projects_And_Constructions_In_LSF"
          style={{ backgroundColor: "#F2F2F2" }}
        >
          <div className={styles.Pagehead}>
            <div>
              <PageHead
                text={t("WHAT WE'VE ALREADY DONE")}
                text1={t("LSF Projects")}
                styles={{
                  textalign: "left",
                  display: "flex",
                  flexJustify: "flex-start",
                  alignItems: "flex-start",
                }}
              ></PageHead>
            </div>
            <div>
              <AccessAreaMenuBig
                className="access-area-menu-big-instance"
                frameClassName="design-component-instance-node"
                text= {selectedLanguage === "pt" ? "Ver projetos e obras" : "See projects and works"}
                goTo={"Projects_And_Constructions_In_LSF"}
              />
            </div>
          </div>
          <InfiniteScrollComponent
            listOfProjects={projects}
            backgroundColor={"#F2F2F2"}
          />
        </div>
      ) : (
        <div
          className={styles.ContainerSectionMobile}
          id="Projects_And_Constructions_In_LSF"
        >
          <Header_Description_Type_Project
            title={t("WHAT WE'VE ALREADY DONE")}
            subtitle={t("LSF Projects")}
          />
          <InfiniteScrollComponent
            listOfProjects={projects}
            backgroundColor={"#F2F2F2"}
          />
          <div>
            <AccessAreaMenuBig
              className="access-area-menu-big-instance"
              frameClassName="design-component-instance-node"
              text={selectedLanguage === "pt" ? "Ver projetos e obras" : "See projects and works"}
              goTo={"Projects_And_Constructions_In_LSF"}
            />
          </div>
          <div style={{ height: "20px", width: "100%" }}></div>
        </div>
      )}
    </>
  );
}
