import React, { useState, useRef } from "react";
import Flicking from "@egjs/react-flicking";
import styled from "styled-components";
import { PageHead } from "../../atoms/PageHead";
import AccessAreaMenuBig from "../../atoms/AccessAreaMenuBig";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../utils/translations/LanguageContext";

const SectionTitle = styled.div`
    padding-top: 72px;
    display:flex;
    align-items: center;
    justify-content:space-between;
    width:95%;
    margin:auto;
`
const CarouselContainer = styled.div`
    width: 100%;
    margin: 0 auto;
    height: auto;
    background-color: ${(props) => props.customColor};
    padding-bottom:108px;
`;

const Label = styled.div`
    width: 95%;
    margin: 0 auto;
    height: auto;
    justify-content:center;
    padding-bottom: 48px;
`
const Slide = styled.div`
    margin: 0 auto;
    width: 60%;
    height: 627px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 24px;
`;

const Image = styled.img`
    width: 99%;
    height: 100%;
    border-radius: 8px;
`;
const Captions = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: flex-end;
    border-radius: 10px 10px 0 0;
    width: 648px;
    height: 105px;
    flex-shrink: 0;
    bottom: 0;
    background-color: ${(props) => props.customColor};
    position: absolute;
`;
const Title = styled.p`
    color: #648586;
    font-family: "Maven Pro-Bold", Helvetica;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
    top: 0;
    white-space: nowrap;
`;

const Description = styled.p`
    color: #545454;
    font-family: "Maven Pro-Bold", Helvetica;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 40px;
    text-align: center;
    white-space: nowrap;
`;
const BulletContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 24px;
`;

const Bullet = styled.div`
    width: 10px;
    height: 10px;
    background-color: ${(props) => (props.active ? "#648586" : "gray")};
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
`;

function SliderModelWebRenovations(props) {
    const { t } = useTranslation();
    const { selectedLanguage } = useLanguage() ;
    const flicking = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [activeBulletIndex, setActiveBulletIndex] = useState(0);
    const [slides, setSlides] = useState([
        {
            image:
                "https://bohocasasstorage.blob.core.windows.net/boho-casas/RemodelingProject1.png",
            title: "LOREM",
            description: "LOREM",
        },
        {
            image:
                "https://bohocasasstorage.blob.core.windows.net/boho-casas/RemodelingProject2.png",
            title: "LOREM",
            description: "LOREM",
        },
        {
            image:
                "https://bohocasasstorage.blob.core.windows.net/boho-casas/RemodelingProject3.png",
            title: "LOREM",
            description: "LOREM",
        },
    ]);

    const handleIndexChanged = (e) => {
        setCurrentIndex(e.index);
    };

    const goToSlide = (index) => {
        flicking.current.moveTo(index);
        setActiveBulletIndex(index);

        const bullets = document.querySelectorAll(".bullet");
        bullets.forEach((bullet, bulletIndex) => {
            if (bulletIndex === index) {
                bullet.classList.add("active");
            } else {
                bullet.classList.remove("active");
            }
        });
    };

    return (
        <CarouselContainer customColor={props.customColor} id="SLIDER_REMODELING">
            <SectionTitle>
                <PageHead
                    text={t("Projects and Works")}
                    text1={t("textTitleCard4")}
                />
                <AccessAreaMenuBig
                    className="access-area-menu-big-instance"
                    frameClassName="design-component-instance-node"
                    text={selectedLanguage === "pt" ? "Ver obras" : "See works"}
                />
            </SectionTitle>
            <Label>
                <p className="descubra-a-categoria">
                    {t("Explore our Architecture Projects category at BohoCasas, which presents a wide range of works built using LSF - Light Steel Framing. Our specialized team has experience in this modern and innovative construction method, which uses lightweight steel structures to create durable, efficient and sustainable spaces")}
                </p>
            </Label>
            <Flicking
                ref={flicking}
                align="center"
                circular
                onIndexChanged={handleIndexChanged}
            >
                {slides.map((slide, index) => (
                    <Slide key={index}>
                        <Image src={slide.image} alt={`Slide ${index + 1}`} />
                        <Captions customColor={props.customColor}>
                            <Title>{slide.title}</Title>
                            <Description>{slide.description}</Description>
                        </Captions>
                    </Slide>
                ))}
            </Flicking>
            <BulletContainer>
                {slides.map((_, index) => (
                    <Bullet
                        key={index}
                        active={index === activeBulletIndex}
                        onClick={() => goToSlide(index)}
                    />
                ))}
            </BulletContainer>
        </CarouselContainer>
    );
}

export default SliderModelWebRenovations;