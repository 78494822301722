import React from "react";
import { useLanguage } from "../../../utils/translations/LanguageContext";
import Flicking from "@egjs/react-flicking";
import "./BoxWithIconsCommercialProjectsandFranchises.css";
import checkIsMobile from "../../../utils/checkIsMobile";

export default function BoxWithIconsCommercialProjectsandFranchises() {
    const { selectedLanguage } = useLanguage() || { selectedLanguage: "pt" };
    const iconDescriptions = selectedLanguage === "pt"
        ? [
            { number: "01", description: "Levantamento Arquitetônico do Imóvel", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/2.png" },
            { number: "02", description: "Reunião de Briefing", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/3.png" },
            { number: "03", description: "Projeto de Preliminar", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/4.png" },
            { number: "04", description: "Aprovação do Cliente", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/5.png" },
            { number: "05", description: "Projeto Executivo Completo", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/6.png" },
            { number: "01", description: "Levantamento Arquitetônico do Imóvel", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/2.png" },
            { number: "02", description: "Reunião de Briefing", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/3.png" },
            { number: "03", description: "Projeto de Preliminar", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/4.png" },
            { number: "04", description: "Aprovação do Cliente", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/5.png" },
            { number: "05", description: "Projeto Executivo Completo", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/6.png" },

        ]
        : [
            { number: "01", description: "Architectural Survey of the Property", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/2.png" },
            { number: "02", description: "Briefing Meeting", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/3.png" },
            { number: "03", description: "Preliminary Design", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/4.png" },
            { number: "04", description: "Client Approval", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/5.png" },
            { number: "05", description: "Complete Executive Project", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/6.png" },
            { number: "01", description: "Architectural Survey of the Property", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/2.png" },
            { number: "02", description: "Briefing Meeting", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/3.png" },
            { number: "03", description: "Preliminary Design", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/4.png" },
            { number: "04", description: "Client Approval", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/5.png" },
            { number: "05", description: "Complete Executive Project", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/6.png" },

        ];

    return (
        <>
            {
                !checkIsMobile() ? (
                    <Flicking circular={true} defaultIndex={0} align="prev">
                        {iconDescriptions.map((item, index) => (
                            <div className="Step" key={index}>
                                <div className="StepNumber">{item.number}</div>
                                <img src={item.iconComponent} alt={`Step ${item.number} Icon`} className="icons" />
                                <div className="StepDescription">{item.description}</div>
                            </div>
                        ))}
                    </Flicking>
                ) : (
                    <Flicking circular={true}>
                        {iconDescriptions.map((item, index) => (
                            <div className="Step" key={index}>
                                <div className="StepNumber">{item.number}</div>
                                <img src={item.iconComponent} alt={`Step ${item.number} Icon`} className="icons" />
                                <div className="StepDescription">{item.description}</div>
                            </div>
                        ))}
                    </Flicking>
                )
            }
        </>
    );
};
