import React, { useState } from "react";
import LogoBoho from "../../components/atoms/3DLogoBoho";
import AuthComponent from "../../components/atoms/Auth";
import "./loginTemplate.css";

export default function LoginTemplate() {
  const [loading, setLoading] = useState(true);
  return (
    <>
      <div className={loading ? "loading_screen" : "loading_screen deactivate"}><div class="custom-loader"></div></div>
      <div
        style={{ display: "flex", flexDirection: "row", overflow: "hidden" }}
      >
        <div style={{ flex: 4 }}>
          <LogoBoho setLoading={setLoading} />
        </div>
        <div style={{ flex: 1 }}>
          <AuthComponent />
        </div>
      </div>
    </>
  );
}
