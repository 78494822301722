import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import CustomMarkerSvg from "./pin.svg";
import checkIsMobile from "../../../utils/checkIsMobile";

class GoogleMap extends Component {
	render() {
		const { google } = this.props;

		const mapStyles = {
			width: "100%",
			height: checkIsMobile() ? "100vw" : "100%"
		};

		const initialCenter = {
			lat: 38.523140133154655,
			lng: -8.897032363211984,
		};

		return (
			<div>
				<Map
					google={google}
					zoom={17}
					style={mapStyles}
					initialCenter={initialCenter}
				>
					<Marker
						position={initialCenter}
						icon={{
							url: CustomMarkerSvg,
							scaledSize: new google.maps.Size(100, 100),
						}}
					/>
				</Map>
			</div>
		);
	}
}

export default GoogleApiWrapper({
	apiKey: "AIzaSyD6fOT8rSbECcaRqpKQL94QKtgtDss4jbw",
})(GoogleMap);
