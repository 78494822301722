import React from "react";
import "./ProjectsResidentialArchitectureSpecialties.css";
import { PageHead } from "../../atoms/PageHead";
import { useTranslation } from "react-i18next";
import SemiBoldH4 from "../../atoms/TextStyleSemiBold/SemiBoldH4";
import { useLanguage } from '../../../utils/translations/LanguageContext';
import BoxWithIconsResidentials from "../../molecules/BoxWithIconsResidential";
import checkIsMobile from "../../../utils/checkIsMobile";
import { Container, Typography } from "@mui/material";
import Text_Regular_14 from "../../atoms/Text_Regular_14";

export default function ProjectsResidentialArchitectureSpecialties() {
  const { selectedLanguage } = useLanguage();
  const { t } = useTranslation();

  const handleClickCalculator = () => {
    window.scrollTo({
      top: 1000,
      behavior: "smooth",
    });
  };
  return (
    <>
      {!checkIsMobile() ? (
        <div>
          <div className="containerResidential1" id="#services1">
            <div className="box">
              <div className="group">
                <div className="info">
                  <div className="overlap-group-Residential">
                    <div className="ImageResidential1" />
                    <div className="ImageResidential2" />
                  </div>
                </div>
                <div className="overlap-group-wrapper">
                  <PageHead
                    text={t("services")}
                    text1={selectedLanguage === "pt" ? "Projetos Residenciais De Arquitetura" : "Projects Residential Architecture"}
                  />
                  <p className="lorem-ipsum-dolor" style={{ textAlign: "justify", whiteSpace: "break-spaces" }}>
                    {selectedLanguage === "pt" ? "Desenvolvemos todos os projetos necessários para a construção de uma nova moradia ou para a remodelação total de um imóvel existente, começando pelo projeto personalizado de arquitetura e, na sequência, os projetos de especialidades necessários a cada caso específico, compatibilizando e cuidando do licenciamento destes projetos." : "We have developed all the necessary projects to build a new housing or for the total remodeling of an existing property, starting with the personalized architectural project and, then, the necessary specialty projects for each specific case, compatibilizing and taking care of the licensing of these projects."}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container3">
            <SemiBoldH4 value={selectedLanguage === "pt" ? "Projetos Residenciais De Arquitetura e Especialidades" : "Residential Architecture and Specialties Projects"} isSelected={true} textColor={"#51787d"} />
            <br></br>
            <SemiBoldH4 value={t("Step by step process")} isSelected={true} textColor={"#545454"} />
            <br></br>
            <BoxWithIconsResidentials />
          </div>
          <div className="BoxButton">
            <a
              style={{
                display: "flex",
                width: "427px",
                height: "50px",
                padding: "8px 16px",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
                flexShrink: "0px",
                borderRadius: "12px",
                border: "none",
                transition: "1s ease",
                textDecoration: "none",
                background:
                  "var(--token-colors-status-interaction-elements, #51787d)",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor =
                  "var(--slot-colors-brand-colors-base-slot-1, #84A8A9)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor =
                  "var(--token-colors-status-interaction-elements, #51787d)"; // Voltando à cor original no mouseleave
              }}
              href="/blog/ResidentialsArchiteture"
            >
              <Text_Regular_14 value={selectedLanguage === "pt" ? "Conheça Nossos Projetos" : "Get to know our projects"}
              isSelected={true} />
            </a>
          </div>
        </div>
      )
        :
        (
          <div id="#services1" style={
            {
              padding: "1rem"
            }}>
            <Typography fontSize={24} textAlign={"center"} color={"#51787D"}>
              {selectedLanguage === "pt" ? "Projetos Residenciais De Arquitetura e Especialidades" : "Residential Architecture and Specialties Projects"}
            </Typography>
            <div style={
              {
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
              }
            }>
              <img src="https://bohocasasstorage.blob.core.windows.net/boho-casas/CasaSaoMiguel.png" style={{
                width: "80vw",
                height: "40vh",
                objectFit: "cover",
                borderRadius: 18,
              }} />
              <img src="https://bohocasasstorage.blob.core.windows.net/boho-casas/Sintra1_A.png" style={{
                width: "80vw",
                height: "40vh",
                objectFit: "cover",
                borderRadius: 18
              }} />
            </div>
            <Container sx={{
              minWidth: 300,
              width: "95%",
              maxWidth: 900,
              padding: "1rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "0.5rem",
            }}>
              <div style={{ width: 100, height: 1, background: "#51787D" }} />
              <Typography fontSize={18} textAlign={"center"} color={"#51787D"}>
                {t("services")}
              </Typography>
              <Typography fontSize={24} textAlign={"center"} color={"#51787D"}>
                {selectedLanguage === "pt" ? "Projetos Residenciais De Arquitetura" : "Projects Residential Architecture"}
              </Typography>
              <Typography sx={{ textAlign: "justify", color: "#545454" }}>
                {selectedLanguage === "pt" ? "Desenvolvemos todos os projetos necessários para a construção de uma nova moradia ou para a remodelação total de um imóvel existente, começando pelo projeto personalizado de arquitetura e, na sequência, os projetos de especialidades necessários a cada caso específico, compatibilizando e cuidando do licenciamento destes projetos." : "We have developed all the necessary projects to build a new housing or for the total remodeling of an existing property, starting with the personalized architectural project and, then, the necessary specialty projects for each specific case, compatibilizing and taking care of the licensing of these projects."}
              </Typography>
              <Container sx={{
                minWidth: 300,
                width: "95%",
                maxWidth: 900,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                alignSelf: "center",
                justifyContent: "center",
                gap: "0.5rem",
              }}>
                <br></br>
                <br></br>
                {/* <Typography fontSize={24} textAlign={"center"} color={"#51787D"}>
                  {selectedLanguage === "pt" ? "Projetos Residenciais De Arquitetura e Especialidades" : "Residential Architecture and Specialties Projects"}
                </Typography> */}
                <Typography fontSize={18} textAlign={"center"} color={"#545454"}>
                  {t("Step by step process")}
                </Typography>
                <BoxWithIconsResidentials />
              </Container>
              <div className="BoxButton">
                <a
                  style={{
                    display: "flex",
                    width: "300px",
                    height: "50px",
                    padding: "8px 16px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "8px",
                    flexShrink: "0px",
                    borderRadius: "12px",
                    border: "none",
                    transition: "1s ease",
                    textDecoration: "none",
                    background:
                      "var(--token-colors-status-interaction-elements, #51787d)",
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor =
                      "var(--slot-colors-brand-colors-base-slot-1, #84A8A9)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor =
                      "var(--token-colors-status-interaction-elements, #51787d)";
                  }}
                  href="/blog/ResidentialsArchiteture"
                >
                  <Text_Regular_14 value={selectedLanguage === "pt" ? "Conheça Nossos Projetos" : "Get to know our projects"} isSelected={true} />
                </a>
              </div>
            </Container>
          </div>
        )
      }
    </>
  );
}
