import React, { useEffect } from 'react';
import { useLanguage } from './LanguageContext';
import { useTranslation } from 'react-i18next';
import { Select, MenuItem, makeStyles, FormControl } from '@material-ui/core';
import styles from './LanguageToggle.module.css';
import enFlag from './../../flags/en.png';
import ptFlag from './../../flags/pt.png';
import Cookies from 'js-cookie';
import Text_Bold_14 from '../../components/atoms/Text_Bold_14';
import Text_Bold_20 from '../../components/atoms/Text_Bold_20';

const languageOptions = [
	{ value: 'pt', flag: ptFlag },
	{ value: 'en', flag: enFlag },
];

const useStyles = makeStyles((theme) => ({
	formControl: {
		border: 'none',
	},

	flag: {
		marginRight: theme.spacing(1),
		width: '1.5rem',
		height: '1.5rem',
	},
}));

const LanguageToggle = () => {
	const { i18n } = useTranslation();
	const { selectedLanguage, setSelectedLanguage } = useLanguage("pt");

	useEffect(() => {
		i18n.changeLanguage(Cookies.get('languagePreference') || 'pt');
	}, [selectedLanguage, Cookies]);

	const classes = useStyles();

	const storeLanguagePreference = (language) => {
		setSelectedLanguage(language);
		i18n.changeLanguage(language);
		Cookies.set('languagePreference', language, { expires: 365 });
	};

	const handleChangeLanguage = (event) => {
		const newLanguage = event.target.value;
		i18n.changeLanguage(newLanguage);
		if (Cookies.get("languagePreference")) {
			storeLanguagePreference(newLanguage);
			window.location.reload();
		}

	};

	return (
		<div className={styles.divCustomSelect}>
			<FormControl className={classes.formControl}>
				<Select
					style={{
						backgroundColor: 'transparent',
						color: "white",
					}}
					MenuProps={{
						PaperProps: {
							style: {
								overflow: 'hidden',
								top: 40,
								backgroundColor: 'white',
							},
						},
					}}
					color='white'
					labelId="language-select-label"
					id="language-select"
					defaultValue={Cookies.get('languagePreference') || 'pt'}
					onChange={handleChangeLanguage}
				>
					{languageOptions.map((option) => (
						<MenuItem color='white' key={option.value} value={option.value}>
							{option.value.toUpperCase()}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default LanguageToggle;