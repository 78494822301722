import React from "react";
import BTNFacebook from "../../atoms/BTNFacebook";
import BTNInstagram from "../../atoms/BTNInstagram";
import styles from "./Aside_Bar_Social_Media.module.css";
/**
 * Aside_Bar_Social_Media Component
 *
 * This component renders social media buttons for various platforms within an aside bar.
 * It imports individual button components from atoms and arranges them in the layout.
 *
 * @component
 *
 * @returns {JSX.Element} The rendered Aside_Bar_Social_Media component.
 *
 * @example
 * // Usage
 * <Aside_Bar_Social_Media />
 *
 * @author Bruno Paulon
 */
const Aside_Bar_Social_Media = () => {
	return (
		<div className={styles.AsideBar}>
			<BTNFacebook  /> {/* Render the Facebook button */}
			<BTNInstagram /> {/* Render the Instagram button */}
		</div>
	);
};

export default Aside_Bar_Social_Media;
