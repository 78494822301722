import React, { useEffect, useState } from "react";
import Header from "../Header";
import { useTranslation } from "react-i18next";
import "./SectionHeroDetailsProjectsMobile.css";
import { PageHead } from "../../atoms/PageHead";
import { Link, useParams } from "react-router-dom";
import { ProjectService } from "boho-casas-client-module/client-web";
import SliderModelWebProjects from "../../molecules/SliderModelWebProjects";
import { Typography, Box } from "@mui/material";
import { useLanguage } from "../../../utils/translations/LanguageContext";
import Header_Description_Type_Project from "../../molecules/HeaderDescriptionTypeProject";

export default function SectionHeroDetailsProjectsMobile({ setTags }) {

  const { selectedLanguage, setSelectedLanguage } = useLanguage();
  const { t } = useTranslation();
  const [projects, setProjects] = useState([]);
  const { id } = useParams();
  const scrollToContact = () => {
    const contactSection = document.getElementById("contactSection");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    };
  };
  console.log("id clicado", id);

  useEffect(() => {
    ProjectService.returnAllProject().then((response) => {
      var filteredData = response.data.filter((item) => item._id.$oid === id);
      setProjects(filteredData[0]);
      console.log("filtrageCompleta agora temos outro problema", projects);
    });
  }, [id]);
  setTags(projects.tags);
  const date = new Date(projects.created_project);
  const formattedDate = date.toLocaleDateString("pt-PT", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  return (
    <div className="ContainerSectionHeroDetailsMobile">
      <Header />
      <Box alignSelf={"center"} pt={"2rem"} style={{ marginRight: 10, marginLeft: 10 }}>
        <Typography fontSize={24} color="white" fontFamily={"Maven Pro"}>
          {selectedLanguage === "pt" ? projects.title_pt : projects.title_en}
        </Typography>
      </Box>
      <SliderModelWebProjects />
      <div className="DetailsOfProjectMobile">
        <div className="LabelDetailsProjectsMobile">
          <Header_Description_Type_Project
            showSVG={false}
            title={t("WHAT WE'VE ALREADY DONE")}
            subtitle={t("Project Details")}
            styles={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flext-start",
              width: "100vw",
              textALign: "left"
            }}
          />
        </div>
        <div className="InfoAndTagsMobile">
          <div className="InfoMobile">
            <div>
              <p className="legend">{t("TIME ENVOLVIDO")}</p>
              <p className="label">{projects.team_member || "Default Time"}</p>
            </div>
            {/* <div>
              <p className="legend">{t("CLIENTE")}</p>
              <p className="label">{projects.client_name || "Default Client"}</p>
            </div> */}
            {/* <div>
              <p className="legend">{t("DATA DO PROJETO")}</p>
              <p className="label">{formattedDate || "Default Date"}</p>
            </div> */}
            {/* <div>
              <p className="legend">{t("SERVIÇO")}</p>
              <p className="label">{projects.service || "Default Service"}</p>
            </div> */}
            <div>
              <p className="legend">{t("LOCAL")}</p>
              <p className="label">{projects.location || "Default Location"}</p>
            </div>
          </div>
        </div>
        <span className="AboutTheProject">{t("ABOUT THE PROJECT")}</span>
        <p className="descriptionProject">
          {projects.description_pt || "Default Project Description"}
        </p>
        <div className="ContactUs">
          <p className="labelForContactUs">
            {t("Would you like something similar?")}
          </p>
          <Link
            href="/"
            className="ButtonEnterContact"
            onClick={scrollToContact}
          >
            {t("GET IN TOUCH")}
          </Link>
        </div>
      </div>
    </div>
  );
}
