import { useTranslation } from "react-i18next";
import Header from "../Header/index";
import Aside_Bar_Social_Media from "../../molecules/Aside_Bar_Social_Media";
import {
	ContainerSectionHero,
	LineHorizontal,
	LineVertical,
	LineVertical2,
	SectionHeroBody,
	TextSectionHeroBody,
	RegularH4,
} from "./HeroSectionServices.Styles";
import RegularH1 from "../../atoms/TextStyleRegular/RegularH1";
import SemiBoldLabel from "../../atoms/TextStyleSemiBold/SemiBoldLabel";
export default function Section_Home() {
	
	const { t } = useTranslation();

	const scrollToNextSection = () => {
		// Find the next section element
		const nextSection = document.getElementById("#services1");

		if (nextSection) {
			nextSection.scrollIntoView({ behavior: "smooth" });
		}
	};

	return (
		<ContainerSectionHero>
			<LineHorizontal></LineHorizontal>
			<LineVertical></LineVertical>
			<SectionHeroBody>
				<Header />
				<TextSectionHeroBody>
					<SemiBoldLabel value={"BohoCasas"} isSelected={true} />
					<RegularH1
						value={t("TextServicesH1")}
						isSelected={true}
						textColor={"var(--token-colors-text-plain-text-dark, #FFF)"}
					/>
					<br></br>
					<RegularH4>
						<p>
							{t(
								"At Bohocasas the customer has complete solutions to have their dream property, whether for a root home, to the interior of their apartment or for your business, which needs to gain prominence in the market."
							)}
						</p>
					</RegularH4>
				</TextSectionHeroBody>
				<Aside_Bar_Social_Media />
				<div
					style={{
						margin: "auto",
						display: "flex",
						cursor: "pointer",
						width: "100%",
						justifyContent: "center",
						display: "flex",
						zIndex: 1,
					}}
					onClick={scrollToNextSection}
				>
					<div style={{position: "absolute", bottom: 15}} >
						<img src="https://bohocasasstorage.blob.core.windows.net/boho-casas/scroll.png" height={48} />
					</div>
				</div>
			</SectionHeroBody>
			<LineVertical2></LineVertical2>
		</ContainerSectionHero>
	);
}