import React from "react";
import Header_Description_Type_Project from "../../molecules/HeaderDescriptionTypeProject";
import styles from "./SectionContactUs.module.css";
import FormSendMessage from "../../molecules/FormSendMessage";
import checkIsMobile from "../../../utils/checkIsMobile";
import BTNInstagramGreen4Mobile from "../../atoms/BTNInstagramGreen4Mobile";
import BTNFacebookGreen4Mobile from "../../atoms/BTNFacebookGreen4Mobile";
import ButtonWhatsAppGreen from "../../atoms/BTNSendMessageGreen";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Text_Regular_14 from "../../atoms/Text_Regular_14";
import { PageHead } from "../../atoms/PageHead";
import { useLanguage } from "../../../utils/translations/LanguageContext";
import { Typography } from "@mui/material";
function Section_Contact_Us({ backgroundColor }) {
  const { selectedLanguage } = useLanguage();
  const { t } = useTranslation();
  return (
    <>
      {!checkIsMobile() ? (
        <section className={styles.contactUs} id="contactSection">
          <div className={styles.container1}>
            <p style={{
              "color": "#545454",
              "textAlign": "left",
              "fontFamily": "Maven Pro, sans-serif",
              "fontSize": "2rem",
              "fontWeight": "bold"
            }}>
              {t('sendMessage')}
            </p>
            <FormSendMessage />
          </div>
          <div className={styles.container2}>
            <div style={
              {
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: "3rem"
              }
            }>
              <span style={{
                margintop: "2rem",
                color: "#545454",
                fontFamily: "Maven Pro, sans-serif",
                fontSize: "2rem",
                textAlign: "left",
                fontWeight: "bold"
              }}>
                {t('MORE INFORMATION')}
              </span>
              <span style={{
                color: "#545454",
                textAlign: "left",
                fontSize: "1rem"
              }}>
                <b >{t("Phone")}</b>
                <br />
                +351 265 104 072
              </span>
              <span style={{
                color: "#545454",
                textAlign: "left",
                fontSize: "1rem"
              }}>
                <b>{t("Mail")}</b>
                <br />
                contato@bohocasas.com
              </span>
              <span style={{
                color: "#545454",
                textAlign: "left",
                fontSize: "1rem"
              }}>
                <b>{selectedLanguage === "pt" ? "Endereço" : "Address"}</b> <br />
                {selectedLanguage === "pt" ? "Avenida Luisa Todi, 406 Setúbal - Portugal" : "Luisa Todi Avenue, 406 Setúbal - Portugal"}
              </span>
              <span style={{
                color: "#545454",
                textAlign: "left",
                fontSize: "1rem"
              }}>
                <b>{selectedLanguage === "pt" ? "Horários" : "Schedules"}</b> <br />
                {selectedLanguage === "pt" ? "Agende seu horário para que possamos melhor atendê-lo." : "Schedule your time so we can better serve you."}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                gap: "2rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Link to={'https://www.facebook.com/bohocasas'}>
                <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle opacity="0.16" cx="28" cy="28" r="28" fill="#648586" />
                  <path d="M32.209 28.5719H29.1959V37.5712H25.1783V28.5719H21.8839V24.8757H25.1783V22.0232C25.1783 18.8092 27.1067 17.0013 30.0395 17.0013C31.4457 17.0013 32.9322 17.2825 32.9322 17.2825V20.4564H31.285C29.678 20.4564 29.1959 21.4206 29.1959 22.4652V24.8757H32.7715L32.209 28.5719Z" fill="#648586" />
                </svg>
              </Link>
              <Link to={'https://www.instagram.com/bohocasas'}>
                <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle opacity="0.16" cx="28" cy="28" r="28" fill="#648586" />
                  <path d="M28.1413 22.666C30.6724 22.666 32.7615 24.7552 32.7615 27.2863C32.7615 29.8575 30.6724 31.9065 28.1413 31.9065C25.57 31.9065 23.5211 29.8575 23.5211 27.2863C23.5211 24.7552 25.57 22.666 28.1413 22.666ZM28.1413 30.2994C29.7885 30.2994 31.1143 28.9736 31.1143 27.2863C31.1143 25.639 29.7885 24.3132 28.1413 24.3132C26.4539 24.3132 25.1281 25.639 25.1281 27.2863C25.1281 28.9736 26.4941 30.2994 28.1413 30.2994ZM34.007 22.5053C34.007 23.108 33.5248 23.5901 32.9222 23.5901C32.3196 23.5901 31.8375 23.108 31.8375 22.5053C31.8375 21.9027 32.3196 21.4206 32.9222 21.4206C33.5248 21.4206 34.007 21.9027 34.007 22.5053ZM37.0603 23.5901C37.1407 25.0766 37.1407 29.5361 37.0603 31.0226C36.98 32.4689 36.6586 33.7144 35.614 34.7991C34.5694 35.8437 33.2838 36.1651 31.8375 36.2454C30.351 36.3258 25.8915 36.3258 24.4049 36.2454C22.9586 36.1651 21.7132 35.8437 20.6284 34.7991C19.5839 33.7144 19.2625 32.4689 19.1821 31.0226C19.1018 29.5361 19.1018 25.0766 19.1821 23.5901C19.2625 22.1438 19.5839 20.8581 20.6284 19.8136C21.7132 18.769 22.9586 18.4476 24.4049 18.3672C25.8915 18.2869 30.351 18.2869 31.8375 18.3672C33.2838 18.4476 34.5694 18.769 35.614 19.8136C36.6586 20.8581 36.98 22.1438 37.0603 23.5901ZM35.1319 32.5895C35.614 31.4244 35.4935 28.6121 35.4935 27.2863C35.4935 26.0006 35.614 23.1883 35.1319 21.9831C34.8105 21.2197 34.2078 20.5769 33.4445 20.2957C32.2392 19.8136 29.4269 19.9341 28.1413 19.9341C26.8155 19.9341 24.0032 19.8136 22.8381 20.2957C22.0346 20.6171 21.4319 21.2197 21.1105 21.9831C20.6284 23.1883 20.749 26.0006 20.749 27.2863C20.749 28.6121 20.6284 31.4244 21.1105 32.5895C21.4319 33.393 22.0346 33.9956 22.8381 34.317C24.0032 34.7991 26.8155 34.6786 28.1413 34.6786C29.4269 34.6786 32.2392 34.7991 33.4445 34.317C34.2078 33.9956 34.8506 33.393 35.1319 32.5895Z" fill="#648586" />
                </svg>
              </Link>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Link to={"https://api.whatsapp.com/send/?phone=351964927140&text&type=phone_number&app_absent=0"}
                style={{
                  display: "flex",
                  width: "427px",
                  justifySelf: "center",
                  height: "50px",
                  padding: "8px 16px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                  flexShrink: "0px",
                  borderRadius: "12px",
                  border: "none",
                  transition: "all 1s ease 0s",
                  textDecoration: 'none',
                  background:
                    "var(--token-colors-status-interaction-elements, #51787d)",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor =
                    "var(--slot-colors-brand-colors-base-slot-1, #CCD7D9)"; // Voltando à cor original no mouseleave
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "#51787d";
                }}
              >
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.40001 20.55C8.90001 21.45 10.7 21.9 12.5 21.9C18.05 21.9 22.4 17.4 22.4 12.15C22.4 9.45 21.5 7.05 19.55 5.1C17.6 3.3 15.2 2.25 12.5 2.25C7.1 2.25 2.59999 6.75 2.59999 12.15C2.59999 13.95 3.05 15.75 4.09999 17.4L4.40001 17.85L3.34999 21.45L7.09999 20.4L7.40001 20.55ZM15.8 13.5C16.1 13.5 17.6 14.25 17.9 14.4C17.9467 14.4234 17.9934 14.4431 18.0396 14.4626C18.2898 14.5682 18.5234 14.6668 18.65 15.3C18.8 15.3 18.8 15.9 18.5 16.65C18.35 17.25 17.15 18 16.55 18C16.4484 18 16.351 18.0086 16.247 18.0178C15.7372 18.0629 15.068 18.1221 12.95 17.25C10.3213 16.1985 8.49877 13.6499 7.98699 12.9342C7.9147 12.8331 7.86855 12.7686 7.84999 12.75C7.82445 12.6989 7.77281 12.6174 7.70472 12.5099C7.37288 11.9859 6.65001 10.8446 6.65001 9.6C6.65001 8.1 7.39999 7.35 7.69998 7.05C7.99998 6.75 8.29998 6.75 8.44998 6.75H9.05002C9.20002 6.75 9.50001 6.75 9.65001 7.2C9.95 7.8 10.55 9.3 10.55 9.45C10.55 9.5 10.5667 9.55 10.5833 9.6C10.6167 9.7 10.65 9.8 10.55 9.9C10.475 9.97499 10.4375 10.05 10.4 10.125C10.3625 10.2 10.325 10.275 10.25 10.35L9.80002 10.8C9.65002 10.95 9.50001 11.1 9.65001 11.4C9.80001 11.7 10.4 12.75 11.3 13.5C12.3127 14.3861 13.1117 14.738 13.5168 14.9165C13.5917 14.9495 13.6532 14.9766 13.7 15C14 15 14.15 15 14.3 14.85C14.375 14.7 14.5625 14.475 14.75 14.25C14.9375 14.025 15.125 13.8 15.2 13.65C15.35 13.35 15.5 13.35 15.8 13.5Z" fill="white" />
                </svg>
                <Text_Regular_14 value={t('sendMessage')} isSelected={true} />
              </Link>
            </div>
          </div>
        </section >
      ) : (
        <section className={styles.contactUsMobile} style={{ background: backgroundColor }} id="contactSection">
          <div className={styles.container1Mobile}>
            <div style={{ width: 100, height: 1, background: "#51787D" }} />
            <Typography fontSize={18} textAlign={"center"} color={"#51787D"}>
              {t('contact')}
            </Typography>
            <Typography fontSize={24} textAlign={"center"} color={"#545454"}>
              {t("sendMessage")}
            </Typography>
            <FormSendMessage />
          </div>
          <div className={styles.container2Mobile}>
            <div style={{ width: 100, height: 1, background: "#51787D", margin: "auto" }} />
            <Typography fontSize={18} textAlign={"center"} color={"#51787D"}>
              {t("MORE INFORMATION")}
            </Typography>
            <Typography fontSize={24} textAlign={"center"} color={"#545454"}>
              {t('How to contact us')}
            </Typography>
            <div className={styles.containerContactMobile}>
              <div className={styles.contactItem}>
                <p
                  style={{
                    color: "#545454",
                    textAlign: "left",
                    fontSize: "14px"
                  }}>
                  <b>{t("Phone")}</b>
                </p>
                <p
                  style={{
                    color: "#545454",
                    textAlign: "left",
                    fontSize: "14px"
                  }}>+351 265 104 072</p>
              </div>
              <div className={styles.contactItem}>
                <p style={{
                  color: "#545454",
                  textAlign: "left",
                  fontSize: "14px"
                }}>
                  <b>{t('Mail')}</b>
                </p>
                <p style={{
                  color: "#545454",
                  textAlign: "left",
                  fontSize: "14px"
                }}>contato@bohocasas.com</p>
              </div>
              <div className={styles.contactItem}>
                <p style={{
                  color: "#545454",
                  textAlign: "left",
                  fontSize: "14px"
                }}>
                  <b >{selectedLanguage === 'pt' ? 'Endereço' : 'Address'}</b>
                </p>
                <p style={{
                  color: "#545454",
                  textAlign: "left",
                  fontSize: "14px"
                }}>{selectedLanguage === "pt" ? "Avenida Luisa Todi, 406 Setúbal - Portugal" : "Luisa Todi Avenue, 406 Setúbal - Portugal"}</p>
              </div>
              <div className={styles.contactItem}>
                <p style={{
                  color: "#545454",
                  textAlign: "left",
                  fontSize: "14px"
                }}>
                  <b>{selectedLanguage === "pt" ? "Horários" : "Schedules"}</b>
                </p>
                <p style={{
                  color: "#545454",
                  textAlign: "left",
                  fontSize: "14px"
                }}>{selectedLanguage === "pt" ? "Agende seu horário para que possamos melhor atendê-lo." : "Schedule your time to better serve you."}</p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  gap: "2rem",
                  padding: "0 2rem",
                  margin: "1rem",
                  width: "100%",
                }}
              >
                <BTNFacebookGreen4Mobile />
                <BTNInstagramGreen4Mobile />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Link to={"https://api.whatsapp.com/send/?phone=351964927140&text&type=phone_number&app_absent=0"}
                style={{
                  display: "flex",
                  width: "427px",
                  justifySelf: "center",
                  height: "50px",
                  padding: "8px 16px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                  flexShrink: "0px",
                  borderRadius: "12px",
                  border: "none",
                  transition: "all 1s ease 0s",
                  textDecoration: 'none',
                  background:
                    "var(--token-colors-status-interaction-elements, #51787d)",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor =
                    "var(--slot-colors-brand-colors-base-slot-1, #CCD7D9)"; // Voltando à cor original no mouseleave
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "#51787d";
                }}
              >
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.40001 20.55C8.90001 21.45 10.7 21.9 12.5 21.9C18.05 21.9 22.4 17.4 22.4 12.15C22.4 9.45 21.5 7.05 19.55 5.1C17.6 3.3 15.2 2.25 12.5 2.25C7.1 2.25 2.59999 6.75 2.59999 12.15C2.59999 13.95 3.05 15.75 4.09999 17.4L4.40001 17.85L3.34999 21.45L7.09999 20.4L7.40001 20.55ZM15.8 13.5C16.1 13.5 17.6 14.25 17.9 14.4C17.9467 14.4234 17.9934 14.4431 18.0396 14.4626C18.2898 14.5682 18.5234 14.6668 18.65 15.3C18.8 15.3 18.8 15.9 18.5 16.65C18.35 17.25 17.15 18 16.55 18C16.4484 18 16.351 18.0086 16.247 18.0178C15.7372 18.0629 15.068 18.1221 12.95 17.25C10.3213 16.1985 8.49877 13.6499 7.98699 12.9342C7.9147 12.8331 7.86855 12.7686 7.84999 12.75C7.82445 12.6989 7.77281 12.6174 7.70472 12.5099C7.37288 11.9859 6.65001 10.8446 6.65001 9.6C6.65001 8.1 7.39999 7.35 7.69998 7.05C7.99998 6.75 8.29998 6.75 8.44998 6.75H9.05002C9.20002 6.75 9.50001 6.75 9.65001 7.2C9.95 7.8 10.55 9.3 10.55 9.45C10.55 9.5 10.5667 9.55 10.5833 9.6C10.6167 9.7 10.65 9.8 10.55 9.9C10.475 9.97499 10.4375 10.05 10.4 10.125C10.3625 10.2 10.325 10.275 10.25 10.35L9.80002 10.8C9.65002 10.95 9.50001 11.1 9.65001 11.4C9.80001 11.7 10.4 12.75 11.3 13.5C12.3127 14.3861 13.1117 14.738 13.5168 14.9165C13.5917 14.9495 13.6532 14.9766 13.7 15C14 15 14.15 15 14.3 14.85C14.375 14.7 14.5625 14.475 14.75 14.25C14.9375 14.025 15.125 13.8 15.2 13.65C15.35 13.35 15.5 13.35 15.8 13.5Z" fill="white" />
                </svg>
                <Text_Regular_14 value={t('sendMessage')} isSelected={true} />
              </Link>
            </div>
          </div>
        </section>
      )
      }
    </>
  );
}
export default Section_Contact_Us;
