import styled from "styled-components";

const ContainerSectionHero = styled.div`
  display: flex;
  background: url("https://bohocasasstorage.blob.core.windows.net/boho-casas/herosectionaboutus.png");
  background-color: lightgray 50%;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
`;

const SectionHeroBody = styled.div`
  display: flex;
  /** Displaying the section content in a flex container with vertical arrangement. */
  flex-direction: column;
  /** Setting the height of the section to cover the entire viewport height. */
  height: 100vh;
  /** Setting the width of the section to cover the entire viewport width. */
  width: 100vw;
  z-index: 1;
`;

const LineVertical = styled.div`
  border-right: 1px solid rgba(255, 255, 255, 0.24); /* Cor da borda com opacidade */
  margin-top: 0;
  width: 20px;
  height: 100vh;
  opacity: 0.24;
`;

const LineVertical2 = styled.div`
  border-left: 1px solid rgba(255, 255, 255, 0.24); /* Cor da borda com opacidade */
  margin-top: 0;
  width: 20px;
  height: 100vh;
  opacity: 0.24;
`;

const LineHorizontal = styled.div`
  z-index: 1;
  border-bottom: 1px solid rgba(255, 255, 255, 0.24); /* Cor da borda com opacidade */
  margin-top: 30px;
  position: absolute;
  width: 100vw;
  height: 49px;
  opacity: 0.24;
`;
const TextSectionHeroBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  margin-left: 28px;
  justify-content: center;
  height: 50%;
`;

const RegularH4 = styled.h4`
  width: 1348px;
  color: var(--token-colors-text-plain-text-dark, #fff);
  font-family: Maven Pro;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
`;

/**
 * Styles for the mobile
 */

const TextSectionHeroBodyMobile = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  height: 50%;
  z-index: 1;
  margin-top: 9rem
`;

const RegularH4Mobile = styled.h4`
  
  color: var(--token-colors-text-plain-text-dark, #fff);
  font-family: Maven Pro;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  padding: 3rem;
  z-index: 1;
  text-align: center;
`;

const ContainerSectionHeroMobile = styled.div`
  display: flex;
  flex-direction: column;
  background: url("https://bohocasasstorage.blob.core.windows.net/boho-casas/herosectionaboutus.png");
  background-color: lightgray 50%;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
`;

const SectionHeroBodyMobile = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
`;

export {
  ContainerSectionHero,
  SectionHeroBody,
  LineVertical,
  LineVertical2,
  LineHorizontal,
  TextSectionHeroBody,
  RegularH4,
  TextSectionHeroBodyMobile,
  RegularH4Mobile,
  ContainerSectionHeroMobile,
  SectionHeroBodyMobile,
};
