import React from 'react';
import { Modal, Button, Typography } from 'antd';
const TermsAndConditionsModal = ({ TermsAndConditionsModalVisible, setTermsAndConditionsModalVisible, selectedLanguage }) => {
    return (
        <Modal
            width={'90%'}
            style={{ display: "flex", flexDirection: 'column', gap: 20 }}
            title={selectedLanguage === 'pt' ? 'Termos e condições' : 'Terms and conditions'}
            open={TermsAndConditionsModalVisible}
            onOk={() => setTermsAndConditionsModalVisible(false)}
            onCancel={() => { setTermsAndConditionsModalVisible(!TermsAndConditionsModalVisible) }}
            footer={[
                <Button key="ok" type="primary" onClick={() => setTermsAndConditionsModalVisible(false)}>
                    {selectedLanguage === 'pt' ? 'Fechar' : 'Close'}
                </Button>
            ]}
        >
            <Typography style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'pt' ? 'TERMOS E CONDIÇÕES DE USO ' : 'TERMS AND CONDITIONS OF USE'}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify', fontWeight: 'bold' }}>
                {selectedLanguage === 'pt' ? 'Introdução' : 'Introduction'}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'pt' ?
                    `A BOHO CASAS acredita que é fundamental a transparência nos serviços
                    prestados, e por essa razão apresenta aqui os Termos de Uso e Condições de
                    Navegação do seu site abaixo discriminado, que deve ser respeitado pelos
                    seus usuários, sob pena das sanções legais pertinentes. Este site é destinado à
                    apresentação institucional da empresa, dos seus serviços e projetos. Esta
                    filosofia inclui as seguintes páginas: https://www.bohocasas.pt e
                    www.bohocasas.com` : `BOHO CASAS believes that transparency in services is essential
                    provided, and for this reason it presents here the Terms of Use and Conditions of
                    Navigation of your website detailed below, which must be respected by
                    its users, under penalty of relevant legal sanctions. This website is intended for
                    institutional presentation of the company, its services and projects. It is
                    philosophy includes the following pages: https://www.bohocasas.pt and
                    www.bohocasas.com`}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'pt' ?
                    `O acesso ao site institucional está disponível a todos os usuários que queiram
                    conhecer a filosofia e serviços da BOHO CASAS, bem como o conteúdo
                    especializado publicado.` : `Access to the institutional website is available to all users who wish
                    get to know the philosophy and services of BOHO CASAS, as well as the content
                    published specialist.`}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'pt' ?
                    `No site existem campos de recolha de dados pessoais como nome, e-mail e
                    telemóvel, para as finalidades de envio de mais informações sobre a BOHO
                    CASAS e sobre novos projetos, promoções e eventos; bem como para o
                    usuário solicitar algo relacionado aos nossos serviços, como dúvidas ou
                    agendamentos, na página CONTATE-NOS.` : `On the website there are fields for collecting personal data such as name, email and
                    mobile phone, for the purposes of sending more information about BOHO
                    CASAS and about new projects, promotions and events; as well as for the
                    user requests something related to our services, such as questions or
                    appointments, on the CONTACT US page.`}
            </Typography>
            <Typography style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'pt' ?
                    `
                    Não é permitido aos usuários acessarem as áreas de programação do site,
ficando sujeito quem o fizer à legislação penal brasileira e obrigado a reparar
os danos que causar.
` : `Users are not permitted to access the website's programming areas,
whoever does so will be subject to Brazilian criminal legislation and obliged to repair
the damage it causes.`}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'pt' ?
                    `Também não é autorizado realizar ou permitir engenharia reversa, nem
                    traduzir, decompilar, copiar, modificar, reproduzir, alugar, sublicenciar, publicar,
                    divulgar, transmitir, emprestar, distribuir ou, de outra maneira, dispor das
                    ferramentas de consulta deste Site e de suas funcionalidades. ` : `It is also not authorized to carry out or allow reverse engineering, nor
                    translate, decompile, copy, modify, reproduce, rent, sublicense, publish,
                    disclose, transmit, lend, distribute or otherwise dispose of the
                    consultation tools for this Site and its features.`}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'pt' ?
                    `O site não fornece acesso à Internet a quem tiver interesse de navegar em seu
                    espaço, sendo ônus destes possuírem meios próprios para navegação.` : `The site does not provide Internet access to anyone interested in browsing its website.
                    space, and it is their responsibility to have their own means of navigation.`}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify', fontWeight: 'bold' }}>
                {selectedLanguage === 'pt' ?
                    `DO FUNCIONAMENTO DO SITE` : `THE OPERATION OF THE SITE`}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'pt' ?
                    `O site está hospedado no Azure Cloud, com certificado ssl de 2048 bits. A
                    proteção de dados em trânsito inclui comunicação através de RedesPrivadas
                    Virtuais (utilizando encriptação IPsec/IKE), Transport Layer Security(TLS) 1.2 ou
                    posterior (através de componentes do Azure, como Gateway deAplicação ou
                    Azure Front Door). Além disso, a "encriptação por predefinição" com o MACsec
                    (uma norma IEEEna camada de ligação de dados) está ativada para todo o
                    tráfego do Azureque viaja entre datacenters do Azure para garantir a
                    confidencialidade eintegridade dos dados dos clientes.` : `The website is hosted on Azure Cloud, with a 2048-bit SSL certificate. A
                    data protection in transit includes communication via Private Networks
                    Virtual (using IPsec/IKE encryption), Transport Layer Security(TLS) 1.2 or
                    later (through Azure components such as Application Gateway or
                    Azure Front Door). Additionally, "encryption by default" with MACsec
                    (an IEEE standard at the data link layer) is enabled for the entire
                    Azure traffic that travels between Azure datacenters to ensure
                    confidentiality and integrity of customer data.`}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'pt' ?
                    `A BOHO CASAS canaliza seus esforços para a disponibilidade contínua e
                    permanente do site, ficando sujeita, todavia a eventos extraordinários, como
                    desastres naturais, falhas ou colapsos nos sistemas centrais de comunicação
                    e acesso à internet ou fatos de terceiro, que fogem de sua esfera de vigilância
                    e responsabilidade.` : `BOHO CASAS channels its efforts towards continuous availability and
                    site, being subject, however, to extraordinary events, such as
                    natural disasters, failures or breakdowns in central communication systems
                    and access to the internet or third-party facts, which are beyond its sphere of surveillance
                    and responsibility.`}
            </Typography>
            <Typography style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'pt' ?
                    `A BOHO CASAS não se responsabiliza por qualquer dano, prejuízo ou perda no
                    equipamento dos usuários causado por falhas no sistema, no servidor ou na
                    conexão à Internet dos mesmos, ainda que decorrentes de condutas de
                    terceiros, inclusive por ações de softwares maliciosos como vírus, cavalos de
                    tróia, e outros que possam, de algum modo, danificar o equipamento ou a
                    conexão dos usuários em decorrência do acesso, utilização ou navegação no
                    site, bem como a transferência de dados, arquivos, imagens, textos, áudios ou
                    vídeos contidos no mesmo.` : `BOHO CASAS is not responsible for any damage, loss or loss in the
                    users' equipment caused by failures in the system, server or
                    connection to the Internet, even if resulting from conduct of
                    third parties, including through the actions of malicious software such as viruses, malware,
                    trojan, and others that may, in some way, damage the equipment or
                    connection of users as a result of accessing, using or browsing the
                    website, as well as the transfer of data, files, images, texts, audio or
                    videos contained therein.BOHO CASAS is not responsible for any damage, loss or loss to the equipment of the
                    users caused by failures in the system, server or their Internet connection, even
                    arising from the conduct of third parties, including the actions of malicious software such as
                    viruses, Trojan horses, and others that may, in some way, damage the equipment or
                    connection of users as a result of accessing, using or browsing the website, as well as
                    transfer of data, files, images, texts, audios or videos contained therein.`}
            </Typography>
            <Typography style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'pt' ?
                    `Os usuários não possuem qualquer direito para exigir a disponibilidade do site
                    conforme melhor lhes convêm, tampouco poderão pleitear indenização ou
                    reparação de danos em caso deste site permanecer fora do ar, independente
                    da motivação` : `Users do not have any right to demand the availability of the website
                    as best suits them, nor will they be able to claim compensation or
                    compensation for damages if this site remains offline, regardless of
                    of motivation`}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify', fontWeight: 'bold' }}>
                {selectedLanguage === 'pt' ? `DOS DIREITOS AUTORAIS E PROPRIEDADE INTELECTUAL DO SITE` : `COPYRIGHT AND INTELLECTUAL PROPERTY OF THE SITE`}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'pt' ?
                    `O uso comercial da expressão BOHO CASAS ou suas variações como marca,
                    nome empresarial ou nome de domínio, além do conteúdo do Site, assim
                    como os programas, bancos de dados, redes, arquivos que permitem que o
                    usuário acesse sua página são de propriedade da BOHO CASAS e estão
                    protegidos pelas leis e tratados internacionais de direito autoral, marcas,
                    patentes, modelos e desenhos industriais.` : `The commercial use of the expression BOHO CASAS or its variations as a brand,
                    business name or domain name, in addition to the content of the Site, as well as
                    such as programs, databases, networks, files that allow the
                    user accesses your page are the property of BOHO CASAS and are
                    protected by international laws and treaties on copyright, trademarks,
                    patents, models and industrial designs.`}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'pt' ?
                    `Ao acessar este Site, o usuário declara que irá respeitar todos os direitos de
                    propriedade intelectual e os decorrentes da proteção de marcas, patentes
                    e/ou desenhos industriais, depositadas ou registradas em nome da BOHO
                    CASAS, bem como de todos os direitos referentes a terceiros que porventura
                    estejam, ou estiveram, de alguma forma, disponíveis no Site.` : `By accessing this Site, the user declares that they will respect all rights of
                    intellectual property and those resulting from the protection of brands, patents
                    and/or industrial designs, deposited or registered in the name of BOHO
                    CASAS, as well as all rights relating to third parties who may
                    are, or were, in any way available on the Website.`}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'pt' ?
                    `O simples acesso ao Site não confere aos usuários qualquer direito ao uso dos
                    nomes, títulos, palavras, frases, marcas, projetos, imagens, dados e
                    informações, dentre outras, que nele estejam ou estiveram disponíveis.` : `Simply accessing the Site does not give users any right to use the
                    names, titles, words, phrases, brands, projects, images, data and
                    information, among others, that is or was available there.`}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'pt' ?
                    `A reprodução dos conteúdos descritos anteriormente está proibida, salvo com
                    prévia autorização por escrito da BOHO CASAS ou caso se destinem ao uso
                    exclusivamente pessoal e sem que em nenhuma circunstância os usuários
                    adquiram qualquer direito sobre os mesmos` : `Reproduction of the contents described above is prohibited, except with
                    prior written authorization from BOHO CASAS or if intended for use
                    exclusively personal and under no circumstances will users
                    acquire any rights over them`}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'pt' ?
                    `É permitido fazer somente o arquivo temporário deste Site, sendo vedada sua
                    utilização para finalidades comerciais, publicitárias ou qualquer outra que
                    contrarie a realidade para o qual foi concebido, conforme definido neste
                    documento. Restam igualmente proibidas a reprodução, distribuição e
                    divulgação, total ou parcial, dos textos, figuras, projetos, sem prévia e expressa
                    autorização da BOHO CASAS, sendo permitida somente a impressão de cópias
                    para uso e arquivo pessoal, sem que sejam separadas as partes que
                    permitam dar o fiel e real entendimento de seu conteúdo e objetivo.` : `It is only permitted to create a temporary file on this Site, and its
                    use for commercial, advertising or any other purpose that
                    goes against the reality for which it was designed, as defined in this
                    document. Reproduction, distribution and
                    disclosure, total or partial, of texts, figures, projects, without prior and express
                    authorization from BOHO CASAS, only the printing of copies being permitted
                    for personal use and archive, without separating the parts that
                    allow us to give a faithful and real understanding of its content and objective.`}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'pt' ?
                    `Os usuários assumem toda e qualquer responsabilidade, de caráter civil e/ou
                    criminal, pela utilização indevida das informações, textos, gráficos, marcas,
                    projetos, obras, imagens, enfim, de todo e qualquer direito de propriedade
                    intelectual ou industrial deste Site.` : `Users assume any and all liability, civil and/or
                    criminal, for the improper use of information, texts, graphics, brands,
                    projects, works, images, in short, of any and all property rights
                    intellectual or industrial nature of this Site.`}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify', fontWeight: 'bold' }}>
                {selectedLanguage === 'pt' ?
                    `DAS OBRIGAÇÕES DA BOHO CASAS` : `BOHO CASAS OBLIGATIONS`}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify', fontWeight: 'bold' }}>
                {selectedLanguage === 'pt' ?
                    `A BOHO CASAS SE OBRIGA
                        COM OS SEUS USUÁRIIOS A:` : `BOHO CASAS IS OBLIGED
                        WITH ITS USERS TO:`}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'pt' ?
                    `Manter o ambiente virtual seguro, salvo por ato destrutivo de terceiro que vá
                    além dos esforços empenhados, hipótese que não se responsabilizará por
                    danos oriundos dessa prática danosa.` : `✓ Keep the virtual environment safe, except for a destructive act by a third party that will
                    in addition to the efforts made, in which case it will not be responsible for
                    damages arising from this harmful practice.`}
            </Typography>
            <Typography style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'pt' ?
                    `✓ Preservar a funcionalidade do site, com links não quebrados, utilizando layout
                    que respeita a usabilidade e navegabilidade, facilitando a navegação sempre
                    que possível.` : `✓ Preserve the functionality of the site, with unbroken links, using layout
                    which respects usability and navigability, facilitating navigation at all times
                    as possible.`}
            </Typography>
            <Typography style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'pt' ?
                    `✓ Exibir as funcionalidades de maneira clara, completa, precisa e suficiente de
                    modo que exista a exata percepção das operações realizadas.` : `✓ Display the functionalities in a clear, complete, precise and sufficient manner
                    so that there is exact perception of the operations carried out.`}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify', fontWeight: 'bold' }}>
                {selectedLanguage === 'pt' ?
                    `DAS OBRIGAÇÕES DOS USUÁRIOS` : `USER OBLIGATIONS`}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'pt' ?
                    `✓ Os usuários se obrigam a realizar uma navegação com retidão ética, sempre
                    respeitando as condições que regem a utilização do Site.` : `✓ Users are obliged to navigate with ethical integrity, always
                    respecting the conditions that govern the use of the Website.`}
            </Typography>
            <Typography style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'pt' ?
                    `✓ Ao fornecer dados e informações a BOHO CASAS, o usuário se obriga a fazê-lo
                    sempre com compromisso de veracidade e autenticidade, sob pena da
                    aplicação das penas da lei e de indenizar a quem causar dano.` : `✓ When providing data and information to BOHO CASAS, the user undertakes to do so
                    always with a commitment to truthfulness and authenticity, under penalty of
                    application of the penalties of the law and to compensate those who cause damage.`}
            </Typography>
            <Typography style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'pt' ?
                    `✓ Na incidência de danos ao Site ou a terceiros, o responsável se compromete a
                    arcar com todas as obrigações de indenizar o sujeito lesado, não devendo a
                    BOHO CASAS responder por tais prejuízos.` : `✓ In the event of damage to the Website or third parties, the person responsible undertakes to
                    bear all obligations to compensate the injured party, and the
                    BOHO CASAS will be liable for such losses.`}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify', fontWeight: 'bold' }}>
                {selectedLanguage === 'pt' ?
                    `DOS DISPOSITIVOS GERAIS` : `GENERAL DEVICES`}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'pt' ?
                    `Ao navegar por este site, você confirma que leu e compreendeu os Termos e
                    Condições de Uso que se encontram vigentes na data e, portanto, deve
                    verificar o mesmo cada vez que visitar estes sites.` : `By browsing this website, you confirm that you have read and understood the Terms and
                    Conditions of Use that are in force on the date and, therefore, must
                    check the same every time you visit these sites.`}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'pt' ?
                    `A BOHO CASAS reserva-se o direito de modificar o presente Termo de Uso
                    para adaptá-lo a alterações legislativas em vigor em Portugal, ou aquelas
                    relativas às práticas comerciais. Em qualquer caso, será informado no site, por
                    meio desta página, as mudanças introduzidas.` : `BOHO CASAS reserves the right to modify this Terms of Use
                    to adapt it to legislative changes in force in Portugal, or those
                    relating to commercial practices. In any case, you will be informed on the website, by
                    through this page, the changes introduced.`}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify', fontWeight: 'bold'}}>
                {selectedLanguage === 'pt' ? `O Usuário utiliza este website exclusivamente pela sua conta e
risco.`: `The User uses this website exclusively for their own account and
risk.`}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify', fontWeight: 'bold' }}>
                {selectedLanguage === 'pt' ? `DA COMUNIICAÇÃO COM
SEUS USUÁRIIOS` : `COMMUNICATION WITH
YOUR USERS`}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'pt' ? `A BOHO CASAS utiliza vários canais de comunicação com seus usuários. Para
os usuários do site institucional, existe a área “CONTATE-NOS” específica para o
contato direto para assuntos diversos. Para a efetiva comunicação por e-mail,
é indispensável que o usuário sempre forneça os dados corretos e precisos, e
os mantenha atualizados.` : `BOHO CASAS uses several communication channels with its users. For
users of the institutional website, there is a “CONTACT US” area specifically for the
direct contact for various matters. For effective email communication,
It is essential that the user always provides correct and accurate data, and
keep them updated.`}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'pt' ? `É igualmente de sua responsabilidade, deixar seus sistemas de anti-spam
configurados de modo que não interfiram no recebimento das informações da
BOHO CASAS, não sendo aceitável nenhuma escusa caso não tenha tido
acesso a algum e-mail em virtude desse bloqueio ou filtro similar.` : `It is also your responsibility to leave your anti-spam systems
configured so that they do not interfere with the reception of information from the
BOHO CASAS, no excuse being acceptable if you have not had
access to any email due to this blocking or similar filter.`}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify', fontWeight: 'bold' }}>
                {selectedLanguage === 'pt' ? `DA PRIVACIDADE DOS USUÁRIOS NO SITE` : `USER PRIVACY ON THE SITE`}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'pt' ? `A BOHO CASAS possui documento separado para a Política de Privacidade que
regula o tratamento dos dados pessoais estabelecendo como premissa a
transparência e privacidade dos dados do usuário.` : `BOHO CASAS has a separate document for the Privacy Policy that
regulates the processing of personal data, establishing as a premise the
transparency and privacy of user data.`}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'pt' ? `A Política de Privacidade é parte integrante e inseparável destes Termos de Uso
e Condições de Navegação dos sites, e pode ser acessado através dos link´s
úteis no final da página principal.` : `The Privacy Policy is an integral and inseparable part of these Terms of Use
and Navigation Conditions of the websites, and can be accessed through the links
useful information at the bottom of the main page.`}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'center' }}>
                <b>
                    {selectedLanguage === 'pt' ?
                        `AINDA COM DÚVIDAS?` : `STILL HAVE QUESTIONS?`}
                </b>
            </Typography>
            <br />
            <br />
            <Typography style={{ textAlign: 'center' }}>
                <b>
                    {selectedLanguage === 'pt' ?
                        `Caso você tenha alguma dúvida, sugestão ou problema no que diz respeito a
                        este Termo de Uso ou a Proteção de Dados Pessoais no site da BOHO
                        CASAS, entre em contato através do nosso e-mail:` : `If you have any questions, suggestions or problems regarding
                        this Terms of Use or the Protection of Personal Data on the BOHO website
                        CASAS, contact us via email:`}
                </b>
            </Typography>
            <br />
            <br />
            <Typography style={{ textAlign: 'center' }}>
                <b>
                    {selectedLanguage === 'pt' ?
                        `contato@bohocasas.com` : `contato@bohocasas.com`}
                </b>
            </Typography>
            <br />
        </Modal>
    );
};
export default TermsAndConditionsModal;
