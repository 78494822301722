import React, { useState } from 'react'
import SectionHeroDetailsProjects from '../../components/organisms/SectionHeroCommercialProjectsAndFranchisesMobile/SectionHeroDetailsProjects'
import Section_Contact_Us from '../../components/organisms/SectionContactUs'
import GoogleMap from '../../components/organisms/MapContainer'
import Footer from '../../components/organisms/Footer'
import SectionHeroDetailsProjectsMobile from '../../components/organisms/SectionHeroDetailsProjectsMobile'
import SliderModelWebProjects from '../../components/molecules/SliderModelWebProjects'

export default function DetailsProjectsPageMobileTemplate() {
    const [tags, setTags] = useState([])
    return (
        <div style={{ overflow: 'hidden' }}>
            <SectionHeroDetailsProjectsMobile setTags={setTags} />
            <Section_Contact_Us backgroundColor={"#F2F2F2"} />
            <div style={{ height: "100vw", width: "100vw" }}>
                <GoogleMap />
            </div>
            <Footer />
        </div>
    )
}
