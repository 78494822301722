import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  ConfigProvider,
  notification,
} from "antd";
import "./auth.css";
import { UserService } from "boho-casas-client-module/client-web";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const AuthComponent = () => {
  const [api, contextHolder] = notification.useNotification();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userDataCookie = Cookies.get("userData");
  const [loginFailed, setLoginFailed] = useState(false);
  const navigate = useNavigate();

  const openNotificationError = (placement) => {
    api.error({
      message: `Operação fracassada`,
      description: `Por favor verifique as informações colocadas`,
      placement,
      duration: 3,
      onClose: () => {
        console.log("closed notification");
        setLoginFailed(false);
      },
    });
  };
  useEffect(() => {
    if (loginFailed) {
      notification.open({
        message: "Erro ao por os Dados",
        description: "Por favor verifique os dados postos",
      });
      setLoginFailed(false);
    }
  }, [loginFailed]);

  useEffect(() => {
    if (userDataCookie && userData) {
      navigate("/gestao");
    }
  }, [userDataCookie])
  const onFinish = (values) => {
    console.log("Success:", values);
    if (userData && userDataCookie) {
      if (userData.email === values.email) {
        console.log("entrou em login com userData");
        UserService.login(values.email, values.password)
          .then((response) => {
            navigate("/gestao");
          })
          .catch(() => {
            onFinishFailed(values);
          });
      } else {
        onFinishFailed(values);
      }

    } else {
      UserService.login(values.email, values.password)
        .then((response) => {
          console.log("entrou em login sem userData");
          localStorage.setItem("userData", JSON.stringify(response.data));
          Cookies.set("userData", JSON.stringify(response.data));
          navigate("/gestao");
        })
        .catch(() => {
          onFinishFailed(values);
        });
    }
  };



  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setLoginFailed(true);
  };

  return (
    <div className="ContainerAuth" style={{ textAlign: "center" }}>
      <ConfigProvider
        theme={{
          components: {
            Form: {
              labelColor: "#50777C",
            },
            Button: {
              linkHoverBg: "#F2F2F2",
            },
          },
        }}
      >
        <Form
          name="basic"
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <img src="logo.png" alt="Logo Bohocasas" />
          {/* <h1>Faça o seu login aqui!</h1> */}
          <Form.Item
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Por favor insira o seu email!",
              },
            ]}
          >
            <Input type="email" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Por favor insira a sua password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <ConfigProvider
              theme={{
                token: {
                  colorBgContainer: "#50777C",
                  colorText: "#F2F2F2",
                  colorPrimaryHover: "#F2F2F2",
                  fontSize: 20,
                  paddingContentHorizontal: 100,
                  paddingXS: 100,
                },
              }}
            >
              <Button htmlType="submit" className="buttonLogin">
                Login
              </Button>
            </ConfigProvider>
          </Form.Item>
        </Form>
      </ConfigProvider>
    </div>
  );
};

export default AuthComponent;
