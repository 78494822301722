import React from "react";
import styles from "./Text_Bold_14.module.css";

export default function Text_Bold_14({ value, isSelected, textColor, customStyles }) {
	return (
		<>
			<p
				className={styles.text14}
				style={{
					opacity: isSelected ? 0.5 : 1,
					color: textColor ? textColor : "white",
				}}
			>
				{value}
			</p>
		</>
	);
}
