import styles from "./AccessAreaMenuBig.module.css";
import checkIsMobile from "../../../utils/checkIsMobile";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function AccessAreaMenuBig({
  text,
  className,
  frameClassName,
  goTo,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [sectionId, setSectionId] = useState(window.location.hash.substring(1));
  const handleClick = async () => {
    try {
      await Promise.resolve(navigate("/services/"));
      await new Promise(resolve => setTimeout(resolve, 200));
      navigate(`/blog/${goTo}`);
      window.scrollTo(0, 0);
      // if (goTo == "ResidentialsArchiteture") {
      //   document.getElementById("#services1").scrollIntoView({
      //     behavior: "smooth",
      //   });
      // }else if (goTo == "ResidentialsInterior") {
      //   document.getElementById("#services2").scrollIntoView({
      //     behavior: "smooth"
      //   });
      // }else if (goTo == "Commercial_Projects_And_Franchises") {
      //   document.getElementById("#services3").scrollIntoView({
      //     behavior: "smooth"
      //   });
      // }else if (goTo == "Projects_And_Constructions_In_LSF") {
      //   document.getElementById("#services4").scrollIntoView({
      //     behavior: "smooth"
      //   });
      // }else{
      //   document.getElementById("#services5").scrollIntoView({
      //     behavior: "smooth"
      //   });
      // }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <>
      {!checkIsMobile() ? (
        <div className={`access-area-menu-big ${className}`}>
          <div className={`frame ${frameClassName}`}>
            <div className="rectangle" />
            <Link
              className="VER-DETALHES-DO"
              to={"/services/"}
              onClick={() => {
                handleClick()
              }}
            >
              {text}
            </Link>
          </div>
        </div>
      ) : (
        <div className={styles.buttonMobileAll} onClick={() => handleClick()}>
          <Link to={"/services/"} className="VER-DETALHES-DO-MOBILE">{text}</Link>
        </div>
      )}
    </>
  );
}
