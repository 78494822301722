import { useTranslation } from "react-i18next";
import "./HeroSectionServicesMobile.css";
import Header from "../Header";
import SemiBoldLabel from "../../atoms/TextStyleSemiBold/SemiBoldLabel";
import RegularH1 from "../../atoms/TextStyleRegular/RegularH1";
export default function Section_Home_Mobile() {
  const { t } = useTranslation();

  return (
    <>
      <div className="ContainerSectionHeroService">
        <Header />
        <div className="SectionContent">
          <div style={{ zIndex: "3" }}>
            <SemiBoldLabel value={"BohoCasas"} isSelected={true} />
            <RegularH1
              value={t("TextServicesH1")}
              isSelected={true}
              textColor={"var(--token-colors-text-plain-text-dark, #FFF)"}
            /><p>
              {t(
                "At Bohocasas the customer has complete solutions to have their dream property, whether for a root home, to the interior of their apartment or for your business, which needs to gain prominence in the market."
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
