import React, { useState } from "react";
import SectionHeroProjectsResidentials from "../../components/organisms/SectionHeroProjectsResidentials";
import FooterWeb from "../../components/organisms/FooterWeb/FooterWeb";
import Section_Contact_Us from "../../components/organisms/SectionContactUs";
import GoogleMap from "../../components/organisms/MapContainer";
import CardsRelatedProjectsLSF from "../../components/molecules/CardsRelatedProjectsLSF";
import SectionHeroProjectsLSFPage from "../../components/organisms/SectionHeroProjectsLSFPage";
import Modal from "antd/es/modal/Modal";
import Calculator from "../../components/organisms/Calculator/Calculator";
import SliderModelWebLSFProjects from '../../components/organisms/SliderModelWebLSFProjects'
export default function ProjectsLSFPageTemplate() {
  return (
    <div style={{ overflow: "Hidden", width: "100%" }}>
      <SectionHeroProjectsLSFPage />
      <SliderModelWebLSFProjects customColor={"#fff"} />
      <CardsRelatedProjectsLSF backgroundColor={"#fff"} />
      <div id="simulate"
        style={{ width: "100vw", display: "flex", justifyContent: "center" }}
      >
        <Calculator />
      </div>
      <Section_Contact_Us />
      <div style={{ height: "627px", width: "100vw" }}>
        <GoogleMap />
      </div>
      <FooterWeb />
    </div>
  );
}
