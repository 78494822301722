import React from 'react'
import SectionHeroProjectsResidentials from '../../components/organisms/SectionHeroProjectsResidentials'
import Footer from '../../components/organisms/Footer'
import Section_Contact_Us from '../../components/organisms/SectionContactUs'
import GoogleMap from "../../components/organisms/MapContainer";
import CardsRelatedProjectsLSF from '../../components/molecules/CardsRelatedProjectsLSF';
import SectionHeroProjectsLSFPage from '../../components/organisms/SectionHeroProjectsLSFPage';
import SectionHeroProjectsLSFPageMobile from '../../components/organisms/SectionHeroProjectsLSFPageMobile';
import Calculator from "../../components/organisms/Calculator/Calculator";
import SliderModelLSFProjectsMobile from '../../components/organisms/SliderModelLSFProjectsMobile';


export default function ProjectsLSFPageMobileTemplate() {
    return (
        <div style={{overflow: "hidden"}}>
            <SectionHeroProjectsLSFPageMobile />
            <SliderModelLSFProjectsMobile customColor={"#fff"} />
            <div style={{ height: "100px" }}></div>
            <CardsRelatedProjectsLSF backgroundColor={"#fff"} />
            <Calculator />
            <Section_Contact_Us />
            <div style={{ height: "100vw", width: "100vw" }}>
                <GoogleMap />
            </div>
            <Footer />
        </div>
    )
}
