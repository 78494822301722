import Section_Home from "../../components/organisms/Section_Home";
import Our_Services_Section from "../../components/organisms/Our_Services_Section";
import Section_Projects_Residentials_Architeture from "../../components/organisms/Section_Projects_Residentials";
import Section_Commercial_Projects_And_Franchises from "../../components/organisms/Section_Commercial_Projects_And_Franchises";
import Section_Commercial__Projects_And_Constructions_In_LSF from "../../components/organisms/Section_Commercial__Projects_And_Constructions_In_LSF";
import Section_Remodeling from "../../components/organisms/Section_Remodeling";
import Section_Our_Story from "../../components/organisms/Section_Our_Story";
import SectionContactUs from "../../components/organisms/SectionContactUs";
import GoogleMap from "../../components/organisms/MapContainer";
import FooterWeb from "../../components/organisms/FooterWeb/FooterWeb";
import Section_Projects_Residentials_Interior from "../../components/organisms/Section_Projects_Residentials_Interior";
import { useEffect } from "react";
const HomeTemplate = () => {
  useEffect(() => {
    const sectionId = window.location.hash.substring(1);
    console.log(sectionId);
    if (sectionId) {
      const targetSection = document.getElementById(sectionId);
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);
  function scrollToSecondSection() {
    window.scrollTo({
      top: document.getElementById("second-section").offsetTop,
      behavior: "smooth",
    });
  }

  function scrollToSecondSection() {
    window.scrollTo({
      top: document.getElementById("ContactUsSection").offsetTop,
      behavior: "smooth",
    });
  }
  return (
    <div
      style={{
        overflow: "hidden",
        width: "100vw",
      }}
    >
      <Section_Home />
      <Our_Services_Section id="second-section" />
      <Section_Projects_Residentials_Architeture
        style={{ background: "#F2F2F2" }}
      />
      <div style={{ height: "50px", backgroundColor: "#F2F2F2" }} />
      <Section_Projects_Residentials_Interior
        style={{ background: "#F2F2F2" }}
      />
      <div style={{ height: "50px", backgroundColor: "#F2F2F2" }} />
      <Section_Commercial_Projects_And_Franchises
        style={{ background: "#F2F2F2" }}
      />
      <div style={{ height: "50px", backgroundColor: "#F2F2F2" }} />
      <Section_Commercial__Projects_And_Constructions_In_LSF
        style={{ background: "#F2F2F2" }}
      />
      <div style={{ height: "50px", backgroundColor: "#F2F2F2" }} />
      <Section_Remodeling style={{ background: "#F2F2F2" }} />
      <div style={{ height: "50px", backgroundColor: "#F2F2F2" }} />
      <Section_Our_Story />
      <SectionContactUs />
      <div style={{ height: "627px", width: "100vw" }}>
        <GoogleMap />
      </div>
      <FooterWeb />
    </div >
  );
};

export default HomeTemplate;
