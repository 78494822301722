import React from "react";
import Header from "../Header";
import SemiBoldLabel from "../../atoms/TextStyleSemiBold/SemiBoldLabel";
import RegularH1 from "../../atoms/TextStyleRegular/RegularH1";
import { useTranslation } from "react-i18next";
import "./SectionHeroProjectsResidentials.css";

export default function SectionHeroProjectsResidentialsMobile() {
  const { t } = useTranslation();

  return (
    <div className="ContainerSectionHeroMobile">
      <Header />
      <div className="filter"></div>
      <div className="SectionHeroBodyMobile">
        <div className="TextSectionHeroBodyMobile">
          <SemiBoldLabel value={"BohoCasas"} isSelected={true} />
          <RegularH1
            value={t("textTitleCard1")}
            isSelected={true}
            textColor={"var(--token-colors-text-plain-text-dark, #FFF)"}
          />
          <br></br>
          <p className="RegularH4Mobile">
            {t(
              "Find out the category of residential projects in Bohocasas, offering diversity in interior and outdoor work. Our talented team will turn your residence into an inspiring and functional home. Trust us to create personalized spaces that reflect your style."
            )}
          </p>
        </div>
      </div>
    </div>
  );
}
