import React, { useState } from "react";
import { Form, Input, Checkbox, Button, Row, Col, Typography } from "antd";
import Text_Regular_14 from "../../atoms/Text_Regular_14";
import styles from "./FormSendMessage.module.css";
import checkIsMobile from "../../../utils/checkIsMobile";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../utils/translations/LanguageContext";
import { ContactService } from "boho-casas-client-module/client-web";
import { Modal } from "antd";
import PrivacyPolicyModal from "./PrivacyPolicyModal";

const FormSendMessage = () => {
  const { selectedLanguage } = useLanguage();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [success, setSuccess] = useState(false);
  const [privacyModalVisible, setPrivacyModalVisible] = useState(false);
  const openPrivacyModal = () => {
    setPrivacyModalVisible(true);
  };

  const onFinish = (values) => {
    console.log("Valores do formulário:", values);
    ContactService.addContact({
      name: values.fullName,
      email: values.email,
      phone: values.phone,
      message: values.message,
    })
      .then((response) => {
        console.log(response.data);
        setSuccess(true);
        form.resetFields();
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Falha no envio do formulário:", errorInfo);
  };

  return (
    <>
      {!checkIsMobile() ? (
        <Form
          form={form}
          name="myForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          labelAlign="top" // Posiciona as labels na parte superior
          size="large"
          style={{height: "100%", display: "flex" , flexDirection: "column", alignItems: 'stretch', justifyContent: "space-between" }}
        >
          
          <Form.Item
            label={selectedLanguage === "en" ? "Full Name" : "Nome Completo"}
            name="fullName"
            rules={[
              {
                required: true,
                message:
                  selectedLanguage === "pt"
                    ? "Por favor, insira seu nome completo!"
                    : "Please enter your full name!",
              },
            ]}
          >
            <Input placeholder="João Alves da Silva" />
          </Form.Item>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                label={
                  selectedLanguage === "pt"
                    ? "Email de Contato"
                    : "Contact e-mail"
                }
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message:
                      selectedLanguage === "pt"
                        ? "Por favor, insira um email válido!"
                        : "Please enter a valid email!",
                  },
                ]}
              >
                <Input placeholder="joao.alves@gmail.com" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  selectedLanguage === "pt"
                    ? "Telemóvel / Whatsapp"
                    : "Mobile phone / Whatsapp"
                }
                name="phone"
                rules={[
                  {
                    required: true,
                    message:
                      selectedLanguage === "pt"
                        ? "Por favor, insira um número de telefone ou WhatsApp!"
                        : "Please enter a phone number or WhatsApp!",
                  },
                ]}
              >
                <Input placeholder="+351 *** *** ***" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label={selectedLanguage === "pt" ? "Mensagem" : "Message"}
            name="message"
            rules={[
              {
                required: true,
                message:
                  selectedLanguage === "pt"
                    ? "Por favor, insira uma mensagem!"
                    : "Please enter a message!",
              },
            ]}
          >
            <Input.TextArea
              placeholder={
                selectedLanguage === "pt"
                  ? "Gostaria de saber mais"
                  : "Would you like to know more"
              }
            />
          </Form.Item>

          <div style={{ display: "flex", justifyContent: "flex-start", gap: "1rem", alignItems: "flex-start" }}>
            <Form.Item
              name="agreePrivacy"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                        selectedLanguage === "pt"
                          ? "Por favor, aceite a recolha dos dados!"
                          : "Please accept the data collection!"
                      ),
                },

                /* {
                  required: true,
                  message:
                    selectedLanguage === "pt"
                      ? "Por favor, aceite a recolha dos dados!"
                      : "Please accept the data collection!",
                }, */

              ]}
            >
              <Checkbox className={styles.checkCustom} style={{ alignSelf: "flex-start", justifySelf: "flex-start" }} >
                <Typography style={{ alignSelf: "flex-start", justifySelf: "flex-start", fontSize: "14px", textAlign: "justify", color: "#545454" }}>{selectedLanguage === "pt"
                  ? ` Concordo com a recolha desses dados com a finalidade exclusiva de
              contato da empresa com o possível cliente. Para mais
              informações, acessar nossa `
                  : `I agree with the collection of this data with the exclusive purpose of Company contact with the possible customer. For adults information, access our`}
                  &nbsp;
                  <a onClick={openPrivacyModal}>
                    {selectedLanguage === "pt"
                      ? "Política de Privacidade"
                      : "Privacy Policy"}
                  </a></Typography>
              </Checkbox>
            </Form.Item>
          </div>
          <PrivacyPolicyModal privacyModalVisible={privacyModalVisible} setPrivacyModalVisible={setPrivacyModalVisible} selectedLanguage={selectedLanguage}  />
          <Form.Item style={{margin:"0px", alignSelf: 'center',}}>
            <button
              style={{
                display: "flex",
                width: "427px",
                height: "50px",
                padding: "8px 16px",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
                flexShrink: "0px",
                borderRadius: "12px",
                border: "none",
                transition: "1s ease",
                background:
                  "var(--token-colors-status-interaction-elements, #51787d)",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor =
                  "var(--slot-colors-brand-colors-base-slot-1, #84A8A9)"; // Voltando à cor original no mouseleave
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor =
                  "var(--token-colors-status-interaction-elements, #51787d)"; // Voltando à cor original no mouseleave
              }}
            >
              <Text_Regular_14 value={t("sendMessage")} isSelected={true} />
            </button>
          </Form.Item>
          {success && <p>Enviado com succeso! Será contactado em breve</p>}
        </Form>
      ) : (
        <Form
          form={form}
          name="myForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          labelAlign="top"
          size="large"
          style={{ paddingTop: "20px", justifyContent: "center" }}
        >
          <Form.Item
            label={selectedLanguage === "en" ? "Full Name" : "Nome Completo"}
            name="fullName"
            rules={[
              {
                required: true,
                message:
                  selectedLanguage === "pt"
                    ? "Por favor, insira seu nome completo!"
                    : "Please enter your full name!",
              },
            ]}
          >
            <Input placeholder="João Alves da Silva" />
          </Form.Item>

          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                label={
                  selectedLanguage === "pt"
                    ? "Email de Contato"
                    : "Contact e-mail"
                }
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message:
                      selectedLanguage === "pt"
                        ? "Por favor, insira um email válido!"
                        : "Please enter a valid email!",
                  },
                ]}
              >
                <Input placeholder="joao.alves@gmail.com" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  selectedLanguage === "pt" ? "Telemóvel / Whatsapp" : "Mobile phone / Whatsapp"
                }
                name="phone"
                rules={[
                  {
                    required: true,
                    message:
                      selectedLanguage === "pt" ? "Por favor, insira um número de telefone ou WhatsApp!" : "Please enter a phone number or WhatsApp!",
                  },
                ]}
              >
                <Input placeholder="+351 *** *** ***" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label={
              selectedLanguage === "pt" ? "Mensagem" : "Message"
            }
            name="message"
            rules={[
              {
                required: true,
                message: selectedLanguage === "pt" ? "Por favor, insira uma mensagem!" : "Please enter a message!",
              },
            ]}
          >
            <Input.TextArea placeholder={selectedLanguage === "pt " ? "Gostaria de mais informações sobre os serviços LSF" : "Would you like more information about our services LSF"} />
          </Form.Item>

          <Form.Item
            style={{width: "90%"}}
            name="agreePrivacy"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                      selectedLanguage === "pt" ? "Por favor, concorde com a recolha desses dados!" : "Please accept the data collection!"
                    ),
              },
            ]}
          >
            <Checkbox className={styles.checkCustom}>
              <Typography style={{ alignSelf: "flex-start", justifySelf: "flex-start", fontSize: "14px", textAlign: "justify", color:"#545454" }}>{selectedLanguage === "pt"
                ? ` Concordo com a recolha desses dados com a finalidade exclusiva de
              contato da empresa com o possível cliente. Para mais
              informações, acessar nossa `
                : `I agree with the collection of this data with the exclusive purpose of Company contact with the possible customer. For adults information, access our`}
                &nbsp;
                <a onClick={openPrivacyModal}>
                  {selectedLanguage === "pt"
                    ? "Política de Privacidade"
                    : "Privacy Policy"}
                </a></Typography>
            </Checkbox>
          </Form.Item>
          <PrivacyPolicyModal privacyModalVisible={privacyModalVisible} setPrivacyModalVisible={setPrivacyModalVisible} selectedLanguage={selectedLanguage} />

          <Form.Item>
            <button
              style={{
                display: "flex",
                width: "100%",
                height: "48px",
                padding: "8px 16px",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
                flexShrink: "0px",
                borderRadius: "12px",
                border: "none",
                background:
                  "#51787d",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor =
                  "#51787d";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor =
                  "#aabfbf";
              }}
              onClick={() => {
                console.log("CLICKED");
              }}
            >
              <Text_Regular_14 value={t("sendMessage")} isSelected={true} />
            </button>
          </Form.Item>
          {success && <p> {selectedLanguage === "pt" ? "Enviado com sucesso! Será contactado em breve" : "Sent successfully! Will be contacted soon"} </p>}
        </Form >
      )}
    </>
  );
};

export default FormSendMessage;
