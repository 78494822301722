import React from "react";
import Header from "../Header";
import SemiBoldLabel from "../../atoms/TextStyleSemiBold/SemiBoldLabel";
import RegularH1 from "../../atoms/TextStyleRegular/RegularH1";
import Aside_Bar_Social_Media from "../../molecules/Aside_Bar_Social_Media";
import { useTranslation } from "react-i18next";
import "./SectionHeroCommercialProjectsAndFranchisesMobile.css";
import { RegularH4 } from "../HeroSectionServices/HeroSectionServices.Styles";

export default function SectionHeroCommercialProjectsAndFranchisesMobile() {
  const { t } = useTranslation();

  return (
    <div className="ContainerSectionHeroMobile">
      
      <Header />
      <div className="filter"></div>
      <div className="ContainerComercialContentMobile">
        <SemiBoldLabel value={"BohoCasas"} isSelected={true} />
        <RegularH1
          value={t("Commercial Projects and Franchises")}
          isSelected={true}
          textColor={"var(--token-colors-text-plain-text-dark, #FFF)"}
        />
        <p>
          {t(
            "Discover the category of commercial projects in Bohocasas, offering diversity in work focused on your business. Our talented team will turn your trade or franchise into an inspiring and functional home. Trust us to create personalized spaces that reflect your style."
          )}
        </p>
      </div>
    </div>
  );
}
