import React from "react";
import "./SectionDifferentialsCommercialProjectsAndFranchises.css";
import { PageHead } from "../../atoms/PageHead";
import { useTranslation } from "react-i18next";
import SemiBoldH4 from "../../atoms/TextStyleSemiBold/SemiBoldH4";
import BoxWithIconsCommercialProjectsandFranchises from "../../molecules/BoxWithIconsCommercialProjectsandFranchises";
import { Button } from "../../atoms/Button";
import { useLanguage } from "../../../utils/translations/LanguageContext";
import checkIsMobile from "../../../utils/checkIsMobile";
import { Container, Typography } from "@mui/material";
import Text_Regular_14 from "../../atoms/Text_Regular_14";
export default function SectionDifferentialsCommercialProjectsAndFranchises() {
  const { selectedLanguage } = useLanguage();
  const { t } = useTranslation();
  return (
    <>
      {
        !checkIsMobile() ? (
          <div style={{ marginBottom: "88px" }} >
            <div className="container1">
              <div className="box">
                <div className="group">
                  <div className="overlap-group-wrapper">
                    <div className="overlap-group">
                      <div className="ImageCommercial1" />
                      <div className="ImageCommercial2" />
                    </div>
                  </div>
                  <div className="info" id="#services3">
                    <PageHead
                      className="page-head-instance"
                      divClassName="design-component-instance-node"
                      text={t("services")}
                      text1={selectedLanguage === "pt" ? "Projetos Comerciais e de Franquias" : "Commercial and franchise projects"}
                    />
                    <p className="lorem-ipsum-dolor" style={{ textAlign: "justify", whiteSpace: "break-spaces" }}>
                      {selectedLanguage === "pt" ? "Nossos projetos comerciais possuem todas as peças gráficas necessárias para transformar qualquer espaço em um grande negócio! Trabalhamos projetos para os mais diversos seguimentos e também somos especialistas em projetos de franquias." : "Our commercial projects have all the necessary graphic elements to transform any space into a great business! We work on projects for various sectors and we are also specialists in franchise projects."}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="container3">
              <SemiBoldH4 value={selectedLanguage === "pt" ? "Projetos Comerciais e de Franquias" : "Commercial and Franchise Projects"} isSelected={true} textColor={"#51787d"} />
              <br></br>
              <SemiBoldH4 value={t("Step by step process")} isSelected={true} textColor={"#545454"} />
              <br></br>
              <BoxWithIconsCommercialProjectsandFranchises />
            </div>
            <div className="BoxButton">
              <a
                style={{
                  display: "flex",
                  width: "427px",
                  height: "50px",
                  padding: "8px 16px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                  flexShrink: "0px",
                  borderRadius: "12px",
                  border: "none",
                  transition: "1s ease",
                  textDecoration: "none",
                  background:
                    "var(--token-colors-status-interaction-elements, #51787d)",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor =
                    "var(--slot-colors-brand-colors-base-slot-1, #84A8A9)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor =
                    "var(--token-colors-status-interaction-elements, #51787d)"; // Voltando à cor original no mouseleave
                }}
                href="/blog/Commercial_Projects_And_Franchises"
              >
                <Text_Regular_14 value={selectedLanguage === "pt" ? "Conheça Nossos Projetos" : "Get to know our projects"}
                  isSelected={true} />
              </a>
            </div>
          </div>
        ) :
          (
            <div 
            id="#services3"
            style={
              {
                padding: "1rem"
              }}>
              <Typography fontSize={24} textAlign={"center"} color={"#51787D"}>
                {selectedLanguage === "pt" ? "Projetos Comerciais e de Franquias" : "Commercial and franchise projects"}
              </Typography>
              <div style={
                {
                  padding: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1rem",
                }
              }>
                <img src="https://bohocasasstorage.blob.core.windows.net/boho-casas/DESTAQUE3.png" style={{
                  width: "80vw",
                  height: "40vh",
                  objectFit: "cover",
                  borderRadius: 18,
                }} />
                <img src="https://bohocasasstorage.blob.core.windows.net/boho-casas/DESTAQUE2.png" style={{
                  width: "80vw",
                  height: "40vh",
                  objectFit: "cover",
                  borderRadius: 18
                }} />
              </div>
              <Container sx={{
                minWidth: 300,
                width: "95%",
                maxWidth: 900,
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.5rem",
              }}>

                <div style={{ width: 100, height: 1, background: "#51787D" }} />
                <Typography fontSize={18} textAlign={"center"} color={"#51787D"}>
                  {t("services")}
                </Typography>
                <Typography fontSize={24} textAlign={"center"} color={"#51787D"}>
                  {selectedLanguage === "pt" ? "Projetos Comerciais e de Franquias" : "Commercial and franchise projects"}
                </Typography>
                <Typography sx={{ textAlign: "justify", color: "#545454" }}>
                  {selectedLanguage === "pt" ? "Nossos projetos comerciais possuem todas as peças gráficas necessárias para transformar qualquer espaço em um grande negócio! Trabalhamos projetos para os mais diversos seguimentos e também somos especialistas em projetos de franquias." : "Our commercial projects have all the necessary graphic elements to transform any space into a great business! We work on projects for various sectors and we are also specialists in franchise projects."}
                </Typography>
                <Container sx={{
                  minWidth: 300,
                  width: "95%",
                  maxWidth: 900,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  alignSelf: "center",
                  justifyContent: "center",
                  gap: "0.5rem",
                }}>
                  <br></br>
                  <br></br>
                  {/* <Typography fontSize={24} textAlign={"center"} color={"#51787D"}>
                    {selectedLanguage === "pt" ? "Projetos Comerciais e de Franquias" : "Commercial and franchise projects"}
                  </Typography> */}
                  <Typography fontSize={18} textAlign={"center"} color={"#545454"}>
                    {t("Step by step process")}
                  </Typography>
                  <BoxWithIconsCommercialProjectsandFranchises />
                </Container>
                <div className="BoxButton">
                  <a
                    style={{
                      display: "flex",
                      width: "300px",
                      height: "50px",
                      padding: "8px 16px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "8px",
                      flexShrink: "0px",
                      borderRadius: "12px",
                      border: "none",
                      transition: "1s ease",
                      textDecoration: "none",
                      background:
                        "var(--token-colors-status-interaction-elements, #51787d)",
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor =
                        "var(--slot-colors-brand-colors-base-slot-1, #84A8A9)";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor =
                        "var(--token-colors-status-interaction-elements, #51787d)";
                    }}
                    href="/blog/Commercial_Projects_And_Franchises"
                  >
                    <Text_Regular_14 value={selectedLanguage === "pt" ? "Conheça Nossos Projetos" : "Get to know our projects"} isSelected={true} />
                  </a>
                </div>
              </Container>
            </div>
          )
      }
    </>
  );
}
