import React from 'react'
import SectionHeroCommercialProjectsAndFranchises from '../../components/organisms/SectionHeroCommercialProjectsAndFranchises'
import SliderModelWebCommercialProjectsAndFranchises from '../../components/organisms/SliderModelWebCommercialProjectsAndFranchises'
import Section_Contact_Us from '../../components/organisms/SectionContactUs'
import GoogleMap from './../../components/organisms/MapContainer'
import FooterWeb from '../../components/organisms/FooterWeb/FooterWeb'
import CardsRelatedProjectsCommercialAndFranchieses from '../../components/molecules/CardsRelatedProjectsCommercialAndFranchieses'
export default function CommercialProjectsAndFranchisesPageTemplate() {
    return (
        <div>
            <SectionHeroCommercialProjectsAndFranchises />
            <SliderModelWebCommercialProjectsAndFranchises customColor="#F2F2F2" />
            <CardsRelatedProjectsCommercialAndFranchieses backgroundColor={"#F2F2F2"} />
            <Section_Contact_Us />
            <div style={{ height: "627px", width: "100vw" }}>
                <GoogleMap />
            </div>
            <FooterWeb />
        </div>
    )
}
