import React from "react";
import styles from "./ButtonBasicWhiteDefault.module.css";
import { useTranslation } from "react-i18next";
import checkIsMobile from "../../../utils/checkIsMobile";

const ButtonBasicWhiteDefault = ({ title, onClick }) => {
  const { t } = useTranslation();

  const handleButtonClick = (event) => {
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <>
      {!checkIsMobile() ? (
        <button onClick={handleButtonClick} className={styles.Button}>
          {t(title)}
        </button>
      ) : (
        <button onClick={handleButtonClick} className={styles.Button}>
          {t(title)}
        </button>
      )}
    </>
  );
};

export default ButtonBasicWhiteDefault;
