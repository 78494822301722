import React, { useState } from 'react'
import SectionHeroDetailsProjects from '../../components/organisms/SectionHeroCommercialProjectsAndFranchisesMobile/SectionHeroDetailsProjects'
import FooterWeb from '../../components/organisms/FooterWeb/FooterWeb'
import Section_Contact_Us from '../../components/organisms/SectionContactUs'
import GoogleMap from './../../components/organisms/MapContainer'
import CardsTagsRelatedProjects from '../../components/molecules/CardsTagsRelatedProjects'
import { Container } from '@mui/material'

export default function DetailsProjectsPageTemplate() {
    const [tags, setTags] = useState([])
    return (
        <div>
            <SectionHeroDetailsProjects setTags={setTags} />
            <div style={{ width: "100%", height: "100px" }}></div>
            <CardsTagsRelatedProjects newTags={tags} />
            <Section_Contact_Us />
            <div style={{ height: "627px", width: "100vw" }}>
                <GoogleMap />
            </div>
            <FooterWeb />
        </div>
    )
}
