import React from 'react'
import ResidentialProjectsPageTemplate from '../templates/ResidentialProjectsPageTemplate'

export default function ResidentialProjectsPage() {
    return (
        <div>
            <ResidentialProjectsPageTemplate />
        </div>
    )
}
