import React from 'react'
import CommercialProjectsAndFranchisesPageTemplate from '../templates/CommercialProjectsAndFranchisesPageTemplate'

export default function CommercialProjectsAndFranchisesPage() {
    return (
        <>
            <CommercialProjectsAndFranchisesPageTemplate />
        </>
    )
}
