import React from 'react'
import ProjectsRemodelingPageMobileTemplate from '../templates/ProjectsRemodelingPageMobileTemplate'


export default function ProjectsRemodelingPageMobile() {
    return (
        <div>
            <ProjectsRemodelingPageMobileTemplate />
        </div>
    )
}
