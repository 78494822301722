import React, { useEffect, useRef, useState } from "react";
import styles from "./CardBlogsAllProjects.module.css";
import { useTranslation } from "react-i18next";
import ProjectButton from "../../atoms/ButtonSeeDetailsProjects";
import checkIsMobile from "../../../utils/checkIsMobile";
import Flicking from "@egjs/react-flicking";
import AccessAreaMenuBig from "../../atoms/AccessAreaMenuBig";
import { ProjectService } from "boho-casas-client-module/client-web";
import { useLanguage } from "../../../utils/translations/LanguageContext";
import { useNavigate, useParams } from "react-router-dom";
import Header_Description_Type_Project from "../../molecules/HeaderDescriptionTypeProject";
import { Box } from "@mui/material";
import { Avatar, Card } from "antd";
import Meta from "antd/es/card/Meta";

const CardBlogsAllProjects = ({ backgroundColor }) => {
  const { tag } = useParams();
  const navigate = useNavigate();
  const { selectedLanguage } = useLanguage();
  const [item, setItem] = useState([]);
  const { t } = useTranslation();
  const itemsPerPage = !checkIsMobile() ? 6 : 5;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = item.slice(startIndex, endIndex);
  const totalPages = Math.ceil(item.length / itemsPerPage);
  const generatePageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i < totalPages; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };
  const pageNumbers = generatePageNumbers();
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const refFlipping = useRef(null);
  const shouldShowElement = currentPage > 4;
  const tagsMap = {
    ResidentialsArchiteture: ["Arquitetura"],
    ResidentialsInterior: ["Interior"],
    Commercial_Projects_And_Franchises: ["Comerciais e franquias"],
    Projects_And_Constructions_In_LSF: ["LSF"],
    Remodeling: ["Remodelações"],
  };
  useEffect(() => { }, []);
  useEffect(() => {
    const newTags = tagsMap[tag];
    console.log(newTags);
    if (newTags == undefined || newTags == null) {
      ProjectService.returnAllProject().then((response) => {
        console.log(response.data);
        setItem(response.data);
      });
    } else {
      ProjectService.returnAllProject().then((response) => {
        console.log(response.data);
        var filteredData = response.data.filter((itemProject) => {
          return newTags?.every((tag) => itemProject.tags.includes(tag));
        });
        setItem(filteredData);
      });
    }
  }, []);

  return (
    <>
      {!checkIsMobile() ? (
        <div className={styles.rootContainer}>
          <div
            className={styles.containerBlog}
            style={{ backgroundColor: backgroundColor }}
          >

            <Header_Description_Type_Project
              title={t("textLabelSectionProjects")}
              subtitle={t("textLabelSectionProjects")}
              backgroundColor={backgroundColor}
              showSVG={true}
              buttonTitle={selectedLanguage === "pt" ? "Ver todos os tipos de projeto" : "See all types of project"}
            />

            {
              currentItems.length > 0 ? (
                <Box display={"flex"} flexDirection={"row"} flexWrap={"wrap"}>
                  {currentItems.map((project, index) => (
                    <div className={styles.CardBlog} key={index}>
                      <img
                        className={styles.img}
                        src={project.images[0]}
                        alt={`Project ${index + 1}`}
                      />
                      <div className={styles.datePostAndLocation}>
                        <div>
                          {/* {new Date(project.created_project).toLocaleDateString()} */}
                          {selectedLanguage === "pt"
                            ? project.title_pt
                            : project.title_en}
                        </div>
                        <div>{project.location}</div>
                      </div>
                      {/* <div className={styles.titlePost}>
                    {selectedLanguage === "pt"
                      ? project.title_pt
                      : project.title_en}
                  </div> */}
                      <ProjectButton projectId={project._id.$oid} />
                    </div>
                  ))}
                </Box>
              ) : (
                <Box display={"flex"} flexDirection={"row"} flexWrap={"wrap"} justifyContent={"space-around"} gap={2} pt={"10px"} pb={"10px"}>
                  {
                    [1, 2, 3, 4, 5, 6].map(() => {
                      return (
                        <Card
                          style={{
                            width: 500,
                            height: 500,
                          }}
                          loading={true}
                        >
                          <Meta
                            avatar={<Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=1" />}
                            title="Card title"
                            description="This is the description"
                          />
                        </Card>)
                    })
                  }
                </Box>
              )
            }


          </div>
          <Box
            display={"flex"}
            alignSelf={"center"}
            backgroundColor={"#F2F2F2"}
            width={"100%"}
            justifyContent={"center"}
          >
            {totalPages > 1 && (
              <Box
                width={"50%"}
                display={"flex"}
                gap={"3rem"}
                alignSelf={"center"}
                justifyContent={"center"}
              >
                <button
                  style={{
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="49"
                    height="35"
                    viewBox="0 0 49 35"
                    fill="none"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="34"
                      height="34"
                      rx="17"
                      stroke={currentPage === 1 ? "#E1E6EF" : "#51787D"}
                    />
                    <path
                      d="M49 17.0551H23.8011V13.1988L19 18.0001L23.8011 22.8012V18.945H49V17.0551Z"
                      fill={currentPage === 1 ? "#E1E6EF" : "#51787D"}
                    />
                  </svg>
                </button>
                {pageNumbers.map((pageNumber) => (
                  <button
                    style={{
                      background: "transparent",
                      border: "none",
                      cursor: "pointer",
                      color: pageNumber === currentPage ? "#545454" : "#51787D",
                      transition: "0.5s all",
                      fontFamily: "Maven Pro",
                      fontSize: pageNumber === currentPage ? "20px" : "16px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "24px",
                    }}
                    key={pageNumber}
                    onClick={() => handlePageChange(pageNumber)}
                  >
                    {pageNumber}
                  </button>
                ))}
                <div>
                  <span
                    style={{
                      background: "transparent",
                      border: "none",
                      cursor: "pointer",
                      color: "#51787D",
                      fontFamily: "Maven Pro",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "24px",
                    }}
                  >
                    . . .
                  </span>
                </div>
                <button
                  style={{
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                    color: "#51787D",
                    fontFamily: "Maven Pro",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "24px",
                  }}
                  onClick={() => handlePageChange(totalPages)}
                  disabled={currentPage === totalPages}
                >
                  {totalPages}
                </button>
                <button
                  style={{
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  <svg
                    width="49"
                    height="35"
                    viewBox="0 0 49 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="14.5"
                      y="0.5"
                      width="34"
                      height="34"
                      rx="17"
                      stroke={
                        currentPage === totalPages ? "#E1E6EF" : "#51787D"
                      }
                    />
                    <path
                      d="M30 17.9999L25.1988 13.1987V17.055H0V18.9449H25.1988V22.8012L30 17.9999Z"
                      fill={currentPage === totalPages ? "#E1E6EF" : "#51787D"}
                    />
                  </svg>
                </button>
              </Box>
            )}
          </Box>
        </div >
      ) : (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            backgroundColor: backgroundColor,
            marginTop: "2rem"
          }}
        >
          <Header_Description_Type_Project
            title={t("textLabelSectionProjects")}
            subtitle={t("textSectionProjectsRecentsLabel")}
          />

          <Flicking
            ref={refFlipping}
            align="center"
            style={{ width: "100vw" }}
            circular={true}
            interruptable={true}
            onChanged={(e) => {
              setCurrentImageIndex(e.currentTarget.index);
            }}
          >
            {currentItems?.map((project, index) => (
              <div className={styles.CardMobile} key={index}>

                <img
                  className={styles.imgMobile}
                  src={project.images[0]}
                  alt={`Project ${index + 1}`}
                />
                <div className={styles.datePostAndLocationMobile}>
                  <div>
                    {/* {new Date(project.created_project).toLocaleDateString()} */}
                    {selectedLanguage === "pt"
                      ? project.title_pt
                      : project.title_en}
                  </div>
                  <div>{project.location}</div>
                </div>
                {/* <div className={styles.titlePostMobile}>
                  {selectedLanguage === "pt"
                    ? project.title_pt
                    : project.title_en}
                </div> */}
                <ProjectButton projectId={project._id.$oid} />
              </div>
            ))}
          </Flicking>
          <AccessAreaMenuBig

            text={selectedLanguage === "pt" ? "VER TODOS" : "SEE ALL"}
            goTo=""
          />
          <div style={{ height: "100px" }}></div>
          {totalPages > 1 && (
            <div className={styles.paginationMobile}>
              <button
                style={{
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="49"
                  height="35"
                  viewBox="0 0 49 35"
                  fill="none"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="34"
                    height="34"
                    rx="17"
                    stroke={currentPage === 1 ? "#E1E6EF" : "#51787D"}
                  />
                  <path
                    d="M49 17.0551H23.8011V13.1988L19 18.0001L23.8011 22.8012V18.945H49V17.0551Z"
                    fill={currentPage === 1 ? "#E1E6EF" : "#51787D"}
                  />
                </svg>
              </button>
              {pageNumbers.map((pageNumber) => (
                <button
                  style={{
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                    color: pageNumber === currentPage ? "#545454" : "#51787D",
                    transition: "0.5s all",
                    fontFamily: "Maven Pro",
                    fontSize: pageNumber === currentPage ? "20px" : "16px",
                    fontStyle: "normal",
                    fontWeight: "600",
                  }}
                  key={pageNumber}
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {pageNumber}
                </button>
              ))}
              <div>
                <span
                  style={{
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                    color: "#51787D",
                    fontFamily: "Maven Pro",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "24px",
                  }}
                >
                  . . .
                </span>
              </div>
              <button
                style={{
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                  color: "#51787D",
                  fontFamily: "Maven Pro",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "24px",
                }}
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                {totalPages}
              </button>
              <button
                style={{
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <svg
                  width="49"
                  height="35"
                  viewBox="0 0 49 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="14.5"
                    y="0.5"
                    width="34"
                    height="34"
                    rx="17"
                    stroke={currentPage === totalPages ? "#E1E6EF" : "#51787D"}
                  />
                  <path
                    d="M30 17.9999L25.1988 13.1987V17.055H0V18.9449H25.1988V22.8012L30 17.9999Z"
                    fill={currentPage === totalPages ? "#E1E6EF" : "#51787D"}
                  />
                </svg>
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CardBlogsAllProjects;
