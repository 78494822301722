import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React, { useEffect, useState } from "react";
import HomePage from "./pages/HomePage";
import ServicesPage from "./pages/ServicesPage";
import ProjectsAndWorksPage from "./pages/ProjectsAndWorksPage";
import AboutUsPage from "./pages/AboutUsPage";
import NotFoundPage from "./pages/NotFoundPage";
import HomeMobileTemplate from "./templates/HomeMobileTemplate";
import HomePageMobile from "./pages/HomePageMobile";
import checkIsMobile from "./utils/checkIsMobile";
import ResidentialProjectsPage from "./pages/ResidentialProjectsPage";
import CommercialProjectsAndFranchisesPage from "./pages/CommercialProjectsAndFranchisesPage";
import ProjectsLSFPage from "./pages/ProjectsLSFPage";
import ProjectsLSFPageMobile from "./pages/ProjectsLSFPageMobile";
import ProjectsRemodelingPage from "./pages/ProjectsRemodelingPage";
import ProjectsRemodelingPageMobile from "./pages/ProjectsRemodelingPageMobile";
import DetailsProjectsPage from "./pages/DetailsProjectsPage";
import AuthPage from "./pages/AuthPage";
import LoginPage from "./pages/LoginPage";
import DetailsProjectsPageMobile from "./pages/DetailsProjectsPageMobile";
import BlogDashboard from "./components/molecules/BlogDashboard";
import ServicesPageMobile from "./pages/ServicePageMobile";
import ProjectsAndWorksMobilePage from "./pages/ProjectsAndWorksMobilePage";
import ResidentialProjectsPageMobile from "./pages/ResidentialProjectsPageMobile";
import CommercialProjectsAndFranchisesPageMobile from "./pages/CommercialProjectsAndFranchisesPageMobile";
import AboutUsPageMobile from "./pages/AboutUsPageMobile";
import CookiesWeb from "./utils/CookiesWeb";
import CookiesMobile from "./utils/CookiesMobile";
import Cookies from "js-cookie";
import BlogPage from "./pages/BlogPage";
import { OpenAPI } from "boho-casas-client-module/client-web";
function App() {
  const [isMobile, setIsMobile] = useState();
  const [haveCookies, setHaveCookies] = useState(false);
  const cookies =
    Cookies.get("comfort") ||
    Cookies.get("statistics") ||
    Cookies.get("marketing");
  useEffect(() => {
    var detectIsMobile = checkIsMobile();
    setIsMobile(detectIsMobile);
  }, []);

  useEffect(() => {
    setHaveCookies(!!cookies);
    console.log(cookies);
  }, [cookies]);

  return (
    <>
      {haveCookies ? (
        <div></div>
      ) : isMobile ? (
        <CookiesMobile />
      ) : (
        <CookiesWeb />
      )}
      <Router>
        <Routes>
          {isMobile ? (
            <Route path="/" element={<HomePageMobile />} />
          ) : (
            <Route path="/" element={<HomePage />} />
          )}

          {isMobile ? (
            <Route path="/services" element={<ServicesPageMobile />} />
          ) : (
            <Route path="/services" element={<ServicesPage />} />
          )}
          {/* Blog Page */}
          {isMobile ? (
            <>
            
              <Route path="/blog/:tag" element={<BlogPage />} />
              <Route path="/blog/" element={<BlogPage />} />
            </>
          ) : (
            <>
              <Route path="/blog/:tag" element={<BlogPage />} />
              <Route path="/blog/" element={<BlogPage />} />
            </>
          )}
          {/* End Blog Page */}
          {isMobile ? (
            <Route
              path="/projects-and-works/residential"
              element={<ResidentialProjectsPageMobile />}
            />
          ) : (
            <Route
              path="/projects-and-works/residential"
              element={<ResidentialProjectsPage />}
            />
          )}
          {isMobile ? (
            <Route
              path="/projects-and-works/commercial"
              element={<CommercialProjectsAndFranchisesPageMobile />}
            />
          ) : (
            <Route
              path="/projects-and-works/commercial"
              element={<CommercialProjectsAndFranchisesPage />}
            />
          )}

          {isMobile ? (
            <Route
              path="/projects-and-works/lsf"
              element={<ProjectsLSFPageMobile />}
            />
          ) : (
            <Route
              path="/projects-and-works/lsf"
              element={<ProjectsLSFPage />}
            />
          )}
          {isMobile ? (
            <Route path="/about-us" element={<AboutUsPageMobile />} />
          ) : (
            <Route path="/about-us" element={<AboutUsPage />} />
          )}
          {isMobile ? (
            <Route
              path="/projects-and-works/remodeling"
              element={<ProjectsRemodelingPageMobile />}
            />
          ) : (
            <Route
              path="/projects-and-works/remodeling"
              element={<ProjectsRemodelingPage />}
            />
          )}
          {isMobile ? (
            <Route
              path="/details-projects/:id"
              element={<DetailsProjectsPageMobile />}
            />
          ) : (
            <Route
              path="/details-projects/:id"
              element={<DetailsProjectsPage />}
            />
          )}

          {isMobile ? (
            <Route path="*" element={<NotFoundPage />} />
          ) : (
            <Route path="/gestao" element={<BlogDashboard />} />
          )}
          {isMobile ? (
            <Route path="*" element={<NotFoundPage />} />
          ) : (
            <Route path="/login" element={<LoginPage />} />
          )}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
