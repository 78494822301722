// components/ProjectButton.js
import { Link } from "react-router-dom";
import styles from "./ButtonSeeDetailsProjects.module.css";
import { ProjectService } from "boho-casas-client-module/client-web";
import { useEffect, useState } from "react";
import { useLanguage } from "../../../utils/translations/LanguageContext";



const ProjectButton = ({ projectId }) => {
	const { selectedLanguage } = useLanguage();
	const handleClick = () => {
		window.scrollTo(0, 0);
	};


	return (
		<Link className={styles.Link} to={`/details-projects/${projectId}`} onClick={handleClick}>
			<svg
				width="24"
				height="2"
				viewBox="0 0 24 2"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect width="24" height="2" fill="#556F70" />
			</svg>
			<span className={styles.buttonSeeDetailsProject}>
				{selectedLanguage === "pt" ? "Ver Detalhes do Projeto" : "See Project Details"}
			</span>
		</Link>
	);
};

export default ProjectButton;
