"use client";
import React from "react";
import Section_Our_Story from "../../components/organisms/Section_Our_Story";
import AboutUs from "./../../components/organisms/AboutUs";
import SectionHeroAboutUs from "../../components/organisms/SectionHeroAboutUs";
import SectionContactUs from "../../components/organisms/SectionContactUs";
import GoogleMap from "../../components/organisms/MapContainer";
import FooterWeb from "../../components/organisms/FooterWeb/FooterWeb";

export default function AboutUsTemplate() {
	return (
		<div>
			<SectionHeroAboutUs />
			<AboutUs />
			<Section_Our_Story />
			<SectionContactUs />
			<div style={{ height: "627px", width: "100vw" }}>
				<GoogleMap />
			</div>
			<FooterWeb />
		</div>
	);
}
