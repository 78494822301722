import React from "react";
import "./SectionDifferentialsLSF.css";
import { PageHead } from "../../atoms/PageHead";
import { useTranslation } from "react-i18next";
import Calculator from "../Calculator/Calculator";
import { useLanguage } from "../../../utils/translations/LanguageContext";
import checkIsMobile from "../../../utils/checkIsMobile";
import Text_Regular_14 from "../../atoms/Text_Regular_14";
import { Container, Typography } from "@mui/material";



export default function SectionDifferentialsLSF() {
  const { selectedLanguage } = useLanguage();
  const { t } = useTranslation();
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      {
        !checkIsMobile() ? (
          <div style={{ marginBottom: "88px" }} id="nextSection">

            <div className="container1">
              <div className="box">
                <div className="group">
                  <div className="overlap-group-wrapper">
                    <div className="overlap-group">
                      <div className="element" />
                      <div className="div" />
                    </div>
                  </div>
                  <div className="info" id="#services4">
                    <PageHead
                      className="page-head-instance"
                      divClassName="design-component-instance-node"
                      text={t("services")}
                      text1={
                        <>{t("Discover LSF Projects - Light Steel Framing")}</>
                      }
                    />
                    <p className="lorem-ipsum-dolor" style={{ textAlign: "justify" }}>
                      {selectedLanguage === "pt" ? "Acreditamos que as construções em LSF sejam o que há de mais eficaz e inteligente da atualidade, a começar pela rapidez de construção, segurança estrutural, conforto térmico e acústico, além de ser uma construção seca, limpa e ecológica. Nossas construções permitem uma infinidade de acabamentos, podendo atender tanto a uma demanda mais económica quanto exigente e luxuosa." : "We believe that LSF constructions are the most effective and intelligent option today, starting with the speed of construction, structural safety, thermal and acoustic comfort, as well as being a dry, clean, and ecological construction. Our constructions allow for a multitude of finishes, catering to both more economical and demanding, luxurious demands."}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="BoxButton">
              <a
                style={{
                  display: "flex",
                  width: "427px",
                  height: "50px",
                  padding: "8px 16px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                  flexShrink: "0px",
                  borderRadius: "12px",
                  border: "none",
                  transition: "1s ease",
                  textDecoration: "none",
                  background:
                    "var(--token-colors-status-interaction-elements, #51787d)",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor =
                    "var(--slot-colors-brand-colors-base-slot-1, #84A8A9)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor =
                    "var(--token-colors-status-interaction-elements, #51787d)"; // Voltando à cor original no mouseleave
                }}
                href="/blog/Projects_And_Constructions_In_LSF"
              >
                <Text_Regular_14 value={selectedLanguage === "pt" ? "Conheça nossos projetos e obras" : "Get to know our projects and works"}
                  isSelected={true} />
              </a>
            </div>
            <div className="container2">
              <div className="boxLeft">
                <PageHead
                  className="page-head-instance"
                  divClassName="design-component-instance-node"
                  text={t("textTitleCard3")}
                  text1={selectedLanguage === "pt" ? "Construções em LSF" : "LSF Constructions"}
                />
                <p className="text-wrapper">
                  {selectedLanguage === "pt" ? "Gostaria de ter uma estimativa de custos para uma obra em LSF? Nossa calculadora irá te ajudar!" : "You would like to have a cost estimate for an LSF construction? Our calculator will help you!"}
                  <br></br>
                  <br></br>
                  <span>
                    <Calculator type={
                      "LSF"
                    } />
                  </span>
                </p>
              </div>
              <div className="boxRight">
                <PageHead
                  className="page-head-instance"
                  divClassName="design-component-instance-node"
                  text={t("textTitleCard3")}
                  text1={selectedLanguage === "pt" ? "Como Funciona o nosso trabalho:" : "How Our Works:"}
                  styles={{
                    width: "550px",
                    marginLeft: "90px",
                  }}
                />
                <div className="benefits">
                  <p className="text-wrapper2">
                    <b>{selectedLanguage === "pt" ? "Trabalhamos dentro de duas possibilidades:" : "We work within two possibilities:"}</b>
                  </p>
                  <br></br>
                  <p className="text-wrapper2">
                    <b>{selectedLanguage === "pt" ? "1. Se o cliente pretende construir, mas ainda não tem um terreno e precisa de ajuda nessa fase inicial:" : "1. If the client intends to build, but does not yet have land and needs help in this initial phase::"}
                    </b>
                  </p>
                  <p className="text-wrapper2">
                    {selectedLanguage === "pt" ? "Neste caso podemos orientar na escolha do melhor terreno para a construção, através de uma consultoria especializada e análise técnica. Após a escolha do terreno, desenvolvemos os projetos para a construção, licenciamento, especialidades, manual de fabricação e montagem, levantamento de custos, cronograma e a execução das obras." : "In this case, we can guide you in choosing the best land for construction, through specialized consultancy and technical analysis. After choosing the land, we develop projects for construction, licensing, specialties, manufacturing and assembly manual, cost survey, schedule and execution of works."}
                  </p>
                  <p className="text-wrapper2">
                    <b>{selectedLanguage === "pt" ? "2. Se o cliente já possui um terreno e um projeto aprovado:" : "2. If the client already has land and an approved project:"}</b>

                  </p>
                  <p className="text-wrapper2">
                    {selectedLanguage === "pt" ? "Estando todos os projetos aprovados ou em fase de licenciamento, incluindo arquitetura, especialidades e mapa de quantidades, fazemos um orçamento chave na mão para a execução das obras, de acordo com estes projetos." : "Once all projects are approved or in the licensing phase, including architecture, specialties and bill of quantities, we prepare a turnkey budget for the execution of the works, in accordance with these projects."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) :

          (
            <div style={
              {
                padding: "1rem"
              }}
              id="#services4">
              <Typography fontSize={24} textAlign={"center"} color={"#51787D"}>
                {t("Discover LSF Projects - Light Steel Framing")}
              </Typography>
              <div style={
                {
                  padding: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1rem",
                }
              }>
                <img src="https://bohocasasstorage.blob.core.windows.net/boho-casas/Cena1_trat.png" style={{
                  width: "80vw",
                  height: "40vh",
                  objectFit: "cover",
                  borderRadius: 18,
                }} />
                <img src="https://bohocasasstorage.blob.core.windows.net/boho-casas/Up1.2_imagem1.png" style={{
                  width: "80vw",
                  height: "40vh",
                  objectFit: "cover",
                  borderRadius: 18
                }} />
              </div>
              <Container sx={{
                minWidth: 300,
                width: "95%",
                maxWidth: 900,
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.5rem",
              }}>

                <div style={{ width: 100, height: 1, background: "#51787D" }} />
                <Typography fontSize={18} textAlign={"center"} color={"#51787D"}>
                  {t("services")}
                </Typography>
                <Typography fontSize={24} textAlign={"center"} color={"#51787D"}>
                  {selectedLanguage === "pt" ? "Conheça as Construções LSF" : "Get to know the LSF buildings"}
                </Typography>
                <Typography sx={{ textAlign: "justify", color: "#545454" }}>
                  {selectedLanguage === "pt" ? "Acreditamos que as construções em LSF sejam o que há de mais eficaz e inteligente da atualidade, a começar pela rapidez de construção, segurança estrutural, conforto térmico e acústico, além de ser uma construção seca, limpa e ecológica. Nossas construções permitem uma infinidade de acabamentos, podendo atender tanto a uma demanda mais económica quanto exigente e luxuosa." : "We believe that LSF constructions are the most effective and intelligent option today, starting with the speed of construction, structural safety, thermal and acoustic comfort, as well as being a dry, clean, and ecological construction. Our constructions allow for a multitude of finishes, catering to both more economical and demanding, luxurious demands."}
                </Typography>
                <div className="BoxButton" style={{ margin: "auto", display: "flex", alignSelf: "center", justifySelf: "center" }}>
                  <a
                    style={{
                      display: "flex",
                      minWidth: "300px",
                      alignSelf: "center",
                      height: "50px",
                      padding: "8px 16px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "8px",
                      flexShrink: "0px",
                      borderRadius: "12px",
                      border: "none",
                      transition: "1s ease",
                      textDecoration: "none",
                      background:
                        "var(--token-colors-status-interaction-elements, #51787d)",
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor =
                        "var(--slot-colors-brand-colors-base-slot-1, #84A8A9)";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor =
                        "var(--token-colors-status-interaction-elements, #51787d)";
                    }}
                    href="/blog/Projects_And_Constructions_In_LSF"
                  >
                    <Text_Regular_14 value={selectedLanguage === "pt" ? "Conheça nossos projetos e obras" : "Get to know our projects and works"} isSelected={true}  />
                  </a>
                </div>
                <Container sx={{
                  minWidth: 300,
                  width: "95%",
                  maxWidth: 900,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  alignSelf: "center",
                  justifyContent: "center",
                  justifySelf: "center",
                  gap: "0.5rem",
                }}>
                  {/* <Typography fontSize={24} textAlign={"center"} color={"#51787D"}>
                  {selectedLanguage === "pt" ? "Projetos Comerciais e de Franquias" : "Commercial and franchise projects"}
                </Typography> */}
                  <span style={{ width: "90%", alignSelf: "center" }}>
                    <Calculator type={
                      "LSF"
                    } />
                  </span>
                </Container>
              </Container>
            </div>
          )
      }
    </>
  );
}
