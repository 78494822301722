import React from "react";

const BTNFacebook = () => {
	return (
		<a
			href="https://www.facebook.com/bohocasas"
			style={{ paddingBottom: "30px" }}
		>
			<svg
				width="56"
				height="56"
				viewBox="0 0 56 56"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle opacity="0.16" cx="28" cy="28" r="28" fill="white" />
				<path
					d="M32.209 28.5719H29.1959V37.5712H25.1783V28.5719H21.8839V24.8757H25.1783V22.0232C25.1783 18.8092 27.1067 17.0013 30.0395 17.0013C31.4457 17.0013 32.9322 17.2825 32.9322 17.2825V20.4564H31.285C29.678 20.4564 29.1959 21.4206 29.1959 22.4652V24.8757H32.7715L32.209 28.5719Z"
					fill="white"
				/>
			</svg>
		</a>
	);
};

export default BTNFacebook;
