import GhostImg from "./ghost-img 1.svg";
import Shadow from "./Ellipse 1.svg";
import styles from "./GhostHead.module.css";
export default function GhostHead() {
  return (
    <div className={styles.ghostHead}>
      <img src={GhostImg} />
      <img src={Shadow} />
    </div>
  );
}
