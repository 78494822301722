import React from "react";
import { useLanguage } from "../../../utils/translations/LanguageContext";
import Flicking from "@egjs/react-flicking";
import "./BoxWithIconsResidential.css";
import checkIsMobile from "../../../utils/checkIsMobile";

export default function BoxWithIconsResidential() {
    const { selectedLanguage } = useLanguage() || { selectedLanguage: "pt" };
    const iconDescriptions = selectedLanguage === "pt"
        ? [
            { number: "01", description: "Informações do Terreno (construção raiz)", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/1.png" },
            { number: "02", description: "Levantamento Arquitetônico do Imóvel (para remodelação)", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/2.png" },
            { number: "03", description: "Reunião de Briefing", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/3.png" },
            { number: "04", description: "Projeto de Arquitetura Preliminar", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/4.png" },
            { number: "05", description: "Aprovação do Cliente", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/5.png" },
            { number: "06", description: "Projeto de Arquitetura para Licenciamento", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/6.png" },
            { number: "07", description: "Aprovação do Projeto de Arquitetura na Câmara", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/7.png" },
            { number: "08", description: "Projetos de Especialidades", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/8.png" },
            { number: "09", description: "Aprovação dos Projetos de Especialidades na Câmara", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/9.png" },
            { number: "10", description: "Projetos Executivos para Obras", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/10.png" },
        ]
        : [
            { number: "01", description: "Land Information (root construction)", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/1.png" },
            { number: "02", description: "Architectural Survey of the Property (for remodeling)", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/2.png" },
            { number: "03", description: "Briefing Meeting", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/3.png" },
            { number: "04", description: "Preliminary Architectural Design", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/4.png" },
            { number: "05", description: "Client Approval", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/5.png" },
            { number: "06", description: "Architecture Design for Licensing", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/6.png" },
            { number: "07", description: "Approval of Architecture Design at the Chamber", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/7.png" },
            { number: "08", description: "Specialty Projects", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/8.png" },
            { number: "09", description: "Approval of Specialty Projects at the Chamber", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/9.png" },
            { number: "10", description: "Executive Projects for Construction", iconComponent: "https://bohocasasstorage.blob.core.windows.net/boho-casas/10.png" },
        ];

    return (
        <>
            {
                !checkIsMobile() ? (
                    <Flicking circular={true} defaultIndex={0} autoResize align="prev">
                        {iconDescriptions.map((item, index) => (
                            <div className="Step" key={index}>
                                <div className="StepNumber">{item.number}</div>
                                <img src={item.iconComponent} alt={`Step ${item.number} Icon`} className="icons" />
                                <div className="StepDescription">{item.description}</div>
                            </div>
                        ))}
                    </Flicking>
                ) : (
                    <Flicking circular={true} autoResize>
                        {iconDescriptions.map((item, index) => (
                            <div className="Step" key={index}>
                                <div className="StepNumber">{item.number}</div>
                                <img src={item.iconComponent} alt={`Step ${item.number} Icon`} className="icons" />
                                <div className="StepDescription">{item.description}</div>
                            </div>
                        ))}
                    </Flicking>
                )
            }
        </>
    );
};
