import React from "react";
import InfiniteScrollComponent from "../../molecules/Slider_Projects";
import styled from "styled-components";
import styles from "./Section_Remodeling.module.css";
import { PageHead } from "./../../atoms/PageHead/index";
import AccessAreaMenuBig from "../../atoms/AccessAreaMenuBig";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../utils/translations/LanguageContext";
import checkIsMobile from "../../../utils/checkIsMobile";
import Header_Description_Type_Project from "../../molecules/HeaderDescriptionTypeProject";
export default function Section_Remodeling() {
  const { selectedLanguage } = useLanguage();
  const { t } = useTranslation();
  const [projects] = React.useState([
    {
      _id: "5f9d5f1b9d6f6e0017f7e3a1",
      name:
        selectedLanguage === "pt"
          ? "Oakberry – Loja Paço de Arcos"
          : "Oakberry - Paço de Arcos Store",
      description:
        selectedLanguage === "pt"
          ? "Obras de remodelação comercial, acompanhamento da montagem da carpintaria e colocação das mobílias."
          : "Commercial remodeling works, monitoring of the carpentry assembly and placement of the furniture.",
      images: [
        "https://bohocasasstorage.blob.core.windows.net/boho-casas/oakberry.jpg",
      ],
    },
    {
      _id: "5f9d5f1b9d6f6e0017f7e3a2",
      name: selectedLanguage === "pt" ? "Casa Restelo" : "House Restelo",
      description:
        selectedLanguage === "pt"
          ? "Obras de remodelação residencial, incluindo recuperação de piso de madeira existente e aplicação de papéis de parede."
          : "Residential remodeling works, including existing wooden floor recovery and wallpapers application.",
      images: [
        "https://bohocasasstorage.blob.core.windows.net/boho-casas/arco.jpg",
      ],
    },
    {
      _id: "5f9d5f1b9d6f6e0017f7e3a3",
      name: selectedLanguage === "pt" ? "Banheiros lumiar" : "Lumiar Bathrooms",
      description:
        selectedLanguage === "pt"
          ? "Obras de remodelação de duas casas de banho de um apartamento em Lumiar."
          : "Remodeling works of two bathrooms of an apartment in Lumiar.",
      images: [
        "https://bohocasasstorage.blob.core.windows.net/boho-casas/lumiar.jpg",
      ],
    },
  ]);

  return (
    <>
      {!checkIsMobile() ? (
        <div className={styles.ContainerSection} id="Remodeling">
          <div className={styles.Pagehead}>
            <div>
              <PageHead
                text={t("WHAT WE'VE ALREADY DONE")}
                text1={t("Remodeling")}
                
              ></PageHead>
            </div>
            <div>
              <AccessAreaMenuBig
                className="access-area-menu-big-instance"
                frameClassName="design-component-instance-node"
                text= {selectedLanguage === "pt" ? "Ver obras" : "See works"}
                goTo={"Remodeling"}
              />
            </div>
          </div>
          <InfiniteScrollComponent
            listOfProjects={projects}
            backgroundColor={"#F2F2F2"}
          />
        </div>
      ) : (
        <div className={styles.ContainerSectionMobile} id="Remodeling">
          <Header_Description_Type_Project
            title={t("WHAT WE'VE ALREADY DONE")}
            subtitle={t("Remodeling")}
          />
          <InfiniteScrollComponent listOfProjects={projects} />
          <div>
            <AccessAreaMenuBig
              className="access-area-menu-big-instance"
              frameClassName="design-component-instance-node"
              text={selectedLanguage === "pt" ? "Ver obras" : "See works"}
              goTo={"Remodeling"}
            />
          </div>
          <br></br>
          <br></br>
        </div>
      )}
    </>
    /* <RemodelingSection id="Remodeling">
      <div className={styles.Pagehead}>
        <div>
          <PageHead text={t("WHAT WE'VE ALREADY DONE")} text1={t('textTitleCard4')}
            styles={{
              textalign: "left", display: "flex", flexJustify: "flex-start", alignItems: 'flex-start'
            }}>
          </PageHead>
        </div>
        <div>
          <AccessAreaMenuBig
            className="access-area-menu-big-instance"
            frameClassName="design-component-instance-node"
            text={t("See Projects")}
          />
        </div>
      </div>
      <InfiniteScrollComponent
        listOfProjects={projects}
        backgroundColor={"#F2F2F2"}
      />
    </RemodelingSection> */
  );
}
