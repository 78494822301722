import React, { useEffect, useState } from "react";
import Header from "../../Header";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./SectionHeroDetailsProjects.css";
import { PageHead } from "../../../atoms/PageHead";
import { Link } from "react-router-dom";
import { ProjectService } from "boho-casas-client-module/client-web";
import { useLanguage } from "../../../../utils/translations/LanguageContext";
import SliderModelWebProjects from '../../../molecules/SliderModelWebProjects'
import { Box, Container, Divider, Typography } from "@mui/material";
export default function SectionHeroDetailsProjects({ setTags }) {
  const { selectedLanguage, setSelectedLanguage } = useLanguage();
  const { t } = useTranslation();
  const [projects, setProjects] = useState([]);
  const { id } = useParams();
  const scrollToContact = () => {
    const contactSection = document.getElementById("contactSection");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    };
  };

  useEffect(() => {
    ProjectService.returnAllProject().then((response) => {
      var filteredData = response.data.filter((item) => item._id.$oid === id);
      setProjects(filteredData[0]);
      console.log("filtrageCompleta agora temos outro problema", projects);
    });
  }, [id]);
  setTags(projects.tags);
  const date = new Date(projects.created_project);
  const formattedDate = date.toLocaleDateString("pt-PT", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  return (
    <Box maxWidth={"100vw"}
      sx={{
        width: "100vw",
      }}>
      <div className="ContainerSectionHeroDetails">
        <Header />
        <Box maxWidth={"100vw"} sx={{
          display: "flex",
          width: "100vw",
          alignSelf: "center",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <Box style={{ marginTop: 20 }}>
            <Typography fontSize={24} color="white" fontFamily={"Maven Pro"}>
              {selectedLanguage === "pt" ? projects.title_pt : projects.title_en}
            </Typography>
          </Box>
          <Box maxWidth={"100vw"} pt={"0rem"} sx={{
            display: "flex",
            width: "100vw",
            alignSelf: "center",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}>
            <SliderModelWebProjects />
          </Box>
        </Box>
      </div >
      <Box width={"100vw"} display={"flex"}
        pb={"1rem"}
        sx={{
          display: "flex",
          marginTop: "3rem",
          flexDirection: "row",
          maxWidth: "100vw",
          backgroundColor: "white",
          borderRadius: 5,
          overflowX: "hidden",
          overflowY: "scroll",
          justifyContent: "center"
        }}>
        <Divider variant="middle" sx={{ backgroundColor: "51787d" }} />

        <Box
          pl={"2rem"}
          width={"45vw"}
          sx={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "45vw",
            textAlign: "left",
          }}>
          <Typography fontSize={20} color="#51787d" fontFamily={"Maven Pro"} textAlign={"left"}>
            {selectedLanguage === "pt"
              ? "Time Envolvido"
              : "Team involved"}
          </Typography>
          <Typography fontSize={18} color="#545454" fontFamily={"Maven Pro"} textAlign={"left"}>
            {projects.team_member || "Default Time"}
          </Typography>
          {/* <Typography fontSize={20} color="#51787d" fontFamily={"Maven Pro"} textAlign={"left"}>
            {selectedLanguage === "pt" ? "Cliente" : "Client"}
          </Typography> */}
          {/* <Typography fontSize={18} color="#545454" fontFamily={"Maven Pro"} textAlign={"left"}>
            {projects.client_name || "Default Client"}
          </Typography> */}
          {/* <Typography fontSize={20} color="#51787d" fontFamily={"Maven Pro"} textAlign={"left"}>
            {selectedLanguage === "pt"
              ? "Data do Projeto"
              : "Project Date"}
          </Typography> */}
          {/* <Typography fontSize={18} color="#545454" fontFamily={"Maven Pro"} textAlign={"left"}>
            {formattedDate || "Default Date"}
          </Typography> */}
          <Typography fontSize={20} color="#51787d" fontFamily={"Maven Pro"} textAlign={"left"}>
            {selectedLanguage === "pt" ? "Localização" : "Location"}
          </Typography>
          <Typography fontSize={18} color="#545454" fontFamily={"Maven Pro"} textAlign={"left"}>
            {projects.location || "Default Location"}
          </Typography>
        </Box>
        <Box
          pl={"2rem"}
          width={"45vw"}
          sx={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "45vw",
          }}>
          <Typography fontSize={20} color={"#51787d"} fontFamily={"Maven Pro"}>
            {t("Project Details")}
          </Typography>
          <Typography fontSize={18} color="#545454" fontFamily={"Maven Pro"} textAlign={"left"}>
            {selectedLanguage === "pt"
              ? projects.description_pt
              : projects.description_en}
          </Typography>
          <div className="ContactUs">
            <p className="labelForContactUs"
              style={{ marginTop: "2rem" }}>
              {t("Would you like something similar?")}
            </p>

            <Link
              href="/"
              className="ButtonEnterContact"
              onClick={scrollToContact}
              style={{ marginTop: "2rem" }}
            >
              {t("GET IN TOUCH")}
            </Link>
          </div>
        </Box>

      </Box>
    </Box >
  );
}
