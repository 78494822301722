import React, { useEffect, useState, useRef } from "react";
import Aside_Bar_Social_Media from "../../molecules/Aside_Bar_Social_Media";
import { useTranslation } from "react-i18next"; // Import the useTranslation hook
import RegularH1 from "../../atoms/TextStyleRegular/RegularH1";
import styles from "./Slider.module.css";
import checkIsMobile from "../../../utils/checkIsMobile";
import { useLanguage } from "../../../utils/translations/LanguageContext";
import PlayIcon from "./../../../assets/player-play-filled.svg";
import PauseIcon from "./../../../assets/player-stop-filled.svg";
const Slider = () => {
  const { t } = useTranslation();
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [langText, setLangText] = useState(t("welcomeText"));
  const { selectedLanguage } = useLanguage();

  useEffect(() => { }, []);

  const handleScrollToNextSection = () => {
    window.scrollTo({
      top: document.getElementById("second-section").offsetTop,
      behavior: "smooth",
    });
  };
  const handlePlayVideo = () => {
    document.getElementById("video-intro").play();
    setIsVideoPlaying(true);
  };
  const handleStopVideo = () => {
    if (isVideoPlaying) {
      document.getElementById("video-intro").pause();
      document.getElementById("video-intro").load();
      setIsVideoPlaying(false);
    }
  };
  const videoRef = useRef(null);

  const handleVideoEnd = () => {
    setIsVideoPlaying(false);
    document.getElementById("video-intro").load();
    document.getElementById("video-intro").pause();
  };


  const videoPlayStyle = {
    padding: 0,
    marginTop: 0,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    objectFit: "cover",
    backgroundSize: "cover",
    backgroundPosition: "center",
    zIndex: -1,
  };

  const videoPauseStyle = {
    padding: 0,
    marginTop: 0,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    objectFit: "contain",
    backgroundSize: "contain",
    backgroundPosition: "center",
    zIndex: -1,
    border: "0px",
  };

  const divVideo = {
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    position: "absolute",
  };



  return (
    <>
      {!checkIsMobile() ? (
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={divVideo}>
            <video
              id="video-intro"
              ref={videoRef}
              style={!isVideoPlaying ? videoPauseStyle : videoPlayStyle}
              playsInline
              poster="/images/Poster.png"
              src="https://bohocasasstorage.blob.core.windows.net/boho-casas/BOHO_OPENING_INSTAGRAM.mp4"
              onEnded={handleVideoEnd}
              onLoadedData={() => {
                console.log("Video loaded");
              }}
              onLoadStart={() => {
                console.log("Video load start");
              }}
            ></video>
          </div>
          <div
            style={{
              marginTop: "35px",
              width: "1090px",
              marginLeft: "22px",
              flex: 2,
              display: "flex",
              opacity: isVideoPlaying ? 0 : 1,
              zIndex: 1,
              alignItems: "center",
              display: "flex",
            }}
          >
            <div style={{ width: "60%", display: "flex", flexDirection: "column" }}>
              <RegularH1
                value={selectedLanguage === "pt" ? "Construindo histórias, moldando espaços. Seu Studio de arquitetura e construções em Portugal." : "Building stories, shaping spaces. Your Studio of Architecture and Constructions in Portugal."}
                isSelected={true}
                textColor={"var(--token-colors-text-plain-text-dark, #FFF)"}
                fontSize={"32px"}
              />
            </div>
          </div>
          <div
            style={{
              marginLeft: "22px",
              flex: 1,
              display: "flex",
              alignItems: "center",
              zIndex: 1,
            }}
          >
            <div
              onClick={isVideoPlaying ? handleStopVideo : handlePlayVideo}
              style={{
                cursor: "pointer",
                zIndex: 1,
                display: isVideoPlaying ? "flex" : "none",

              }}
              className={styles.playvideo}
            >
              <img
                src={PauseIcon}
                alt="Pause Icon"
                width={30}
                height={30}
              />
            </div>
            <div
              onClick={isVideoPlaying ? handleStopVideo : handlePlayVideo}
              style={{
                cursor: "pointer",
                zIndex: 1,
                display: isVideoPlaying ? "none" : "flex",

              }}
              className={styles.playvideo}
            >
              <img
                src={PlayIcon}
                alt="Play Icon"
                width={30}
                height={30}
              />
            </div>
            <span
              style={{
                color: "white",
                marginLeft: "20px",
                display: isVideoPlaying ? "none" : "block",
              }}
            >
              {t("textPlayVideoIntroBoho")}
            </span>
          </div>

          <div
            style={{
              marginLeft: "22px",
              flex: 1,
              display: "flex",
              display: "flex",
              alignItems: "center",
              zIndex: 1,
            }}
          ></div>
          <div
            style={{
              margin: "auto",
              display: "flex",
              cursor: "pointer",
              width: "100px",
              justifyContent: "center",
              display: "flex",
              zIndex: 1,
              position: "absolute",
              bottom: 80,
              right: "0px",
              left: "10px",
            }}
            onClick={handleScrollToNextSection}
          >
            <div style={{ display: isVideoPlaying ? "none" : "block", position: "absolute", top: 15}}>
              <img src="https://bohocasasstorage.blob.core.windows.net/boho-casas/scroll.png" height={48} />
            </div>
          </div>
          <div style={{ display: isVideoPlaying ? "none" : "block" }}>
            <Aside_Bar_Social_Media />
          </div>
        </div>
      ) : (
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={divVideo}>
            <video
              id="video-intro"
              style={!isVideoPlaying ? videoPauseStyle : videoPlayStyle}
              playsInline
              poster="/images/HomeMobile.png"
              src="https://bohocasasstorage.blob.core.windows.net/boho-casas/BOHO_OPENING_INSTAGRAM.mp4"
              onEnded={handleVideoEnd}
              onLoadedData={() => {
                console.log("Video loaded");
              }}
              onLoadStart={() => {
                console.log("Video load start");
              }}
            ></video>
          </div>
          <div
            style={{
              marginTop: "4rem",
              width: "100vw",
              height: "30vw",
              flex: 2,
              opacity: isVideoPlaying ? 0 : 1,
              zIndex: 1,
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100vw",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",

              }}
            >
              <div style={{}} className={styles.sliderMobile}>
                <RegularH1
                  value={selectedLanguage === "pt" ? "Construindo histórias, moldando espaços. Seu Studio de arquitetura e construções em Portugal." : "Building stories, shaping spaces. Your Studio of Architecture and Constructions in Portugal."}
                  isSelected={true}
                  textColor={"var(--token-colors-text-plain-text-dark, #FFF)"}
                  fontSize={"24px"}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              margin: "3rem",
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              zIndex: 1,
              opacity: isVideoPlaying ? 1 : 1,
            }}
          >
            <div
              style={{
                marginLeft: "22px",
                flex: 1,
                display: "flex",
                alignItems: "center",
                zIndex: 1,
              }}
            >
              <div
                onClick={isVideoPlaying ? handleStopVideo : handlePlayVideo}
                style={{
                  cursor: "pointer",
                  zIndex: 1,
                  display: isVideoPlaying ? "flex" : "none",

                }}
                className={styles.playvideo}
              >
                <img
                  src={PauseIcon}
                  alt="Pause Icon"
                  width={30}
                  height={30}
                />
              </div>
              <div
                onClick={isVideoPlaying ? handleStopVideo : handlePlayVideo}
                style={{
                  cursor: "pointer",
                  zIndex: 1,
                  display: isVideoPlaying ? "none" : "flex",

                }}
                className={styles.playvideo}
              >
                <img
                  src={PlayIcon}
                  alt="Play Icon"
                  width={30}
                  height={30}
                />
              </div>
              <span
                style={{
                  color: "white",
                  marginLeft: "20px",
                  display: isVideoPlaying ? "none" : "flex",
                }}
              >
                {t("textPlayVideoIntroBoho")}
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Slider;
