import React from 'react'
import styles from './TextSemiBold32.module.css'

export default function TextSemiBold32({
    value,
    isSelected,
    textColor,
    fontSize
}) {
    return (
        <>
            <p
                className={styles.text32}
                style={{
                    opacity: isSelected ? 1 : .5,
                    color: textColor ? textColor : 'white',
                    fontSize: fontSize ? fontSize : "32px"
                }}>
                {value}
            </p>
        </>

    )
}