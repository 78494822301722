import React from "react";

const BoldH6Styles = {
	color: "var(--token-colors-text-plain-text-light, #545454)",
	fontFamily: "Maven Pro",
	fontSize: "20px",
	fontStyle: "normal",
	fontWeight: "700",
	lineHeight: "28px",
};

/**
 * Renders a bold H6 component.
 *
 * @param {object} props - The properties passed to the component.
 * @param {string} props.value - The value to be displayed in the H6.
 * @param {boolean} props.isSelected - Indicates whether the H6 is selected.
 * @param {string} props.textColor - The color of the H6 text.
 * @param {string} props.fontSize - The font size of the H6 text.
 * @returns {JSX.Element} - The rendered bold H6 component.
 */
export default function BoldH6({ value, isSelected, textColor, fontSize }) {
	const textColorStyle = textColor || BoldH6Styles.color;
	return (
		<>
			<p
				style={{
					...BoldH6Styles,
					opacity: isSelected ? 1 : 0.5,
					color: textColorStyle,
					fontSize: fontSize || BoldH6Styles.fontSize,
				}}
			>
				{value}
			</p>
		</>
	);
}