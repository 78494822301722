import React, { useEffect, useState } from 'react';
import { Avatar, List, Typography, Space, Divider, Button, Popover } from 'antd';
import { ContactService } from 'boho-casas-client-module/client-web';
import { Box } from '@material-ui/core';

const LeaderBoard = () => {
    const [list, setList] = useState([]);

    useEffect(() => {
        ContactService.returnAllContact()
            .then((response) => {
                setList(response.data);
                console.log(response.data);
            })
            .catch((error) => {
                console.error(error);
            });

    }, []);

    const handleDeleteContact = (contactId) => {
        const handleConfirmDelete = () => {
            ContactService.deleteContact(contactId)
                .then((response) => {
                    ContactService.returnAllContact()
                        .then((response) => {
                            setList(response.data);
                            console.log(response.data);
                        })
                        .catch((error) => {
                            console.error(error);
                        });

                })
                .catch((error) => {
                    console.error(error);
                });
        };

        const confirmPopover = (
            <div style={{ display: "flex", alignItems: 'center', justifyContent: "center", gap: 20 }}>
                <p>Tem certeza de que deseja excluir este contato?</p>
                <Button danger onClick={handleConfirmDelete}>Sim</Button>
            </div>
        );

        return (
            <Popover content={confirmPopover}>
                <Button danger>Deletar</Button>
            </Popover>
        );
    };

    return (
        <List
            className="demo-loadmore-list"
            itemLayout="horizontal"
            dataSource={list}
            style={{
                width: '100vw',
                height: 'auto',
                maxHeight: 'auto',
                maxWidth: '98vw',
                margin: '0 auto',

            }}
            renderItem={(item) => (
                <Box style={{
                    backgroundColor: "#f4f4f4",
                    alignItems: 'center',
                    display: "flex",
                    flexDirection: 'column',
                    justifyContent: "center",
                }}>
                    <Box
                        style={{
                            alignItems: 'flex-start',
                            display: "flex",
                            flexDirection: 'column',
                            justifyContent: "center",
                            margin: "auto",
                            width: "60vw"
                        }}
                    >
                        <Typography style={{
                            fontSize: 24
                        }}>
                            {item.name}
                        </Typography>
                        <Typography
                            style={{
                                fontSize: 14
                            }}
                        >
                            Email: {item.email} <br />
                            Telefone :  {item.phone}
                        </Typography>
                    </Box>
                    <Box
                        style={{
                            width: "60vw",
                            alignItems: 'center',
                            display: "flex",
                            justifyContent: "space-between",
                        }}>
                        <Box style={{
                            paddingTop: 20,
                            alignItems: 'flex-start',
                        }}>
                            <Typography
                                style={{
                                    fontSize: 18
                                }}>
                                Mensagem de contato:
                            </Typography>
                            <Typography
                                style={{
                                    fontSize: 14
                                }}>{item.message}
                            </Typography>
                        </Box>
                        <List.Item actions={[handleDeleteContact(item._id.$oid)]} style={
                            {
                                alignSelf: 'flex-end',
                                justifySelf: "flex-end"
                            }
                        } />
                    </Box>
                    <Divider />
                </Box>
            )}
        />
    );
};

export default LeaderBoard;