import React from "react";

const SemiBoldLabelStyles = {
	color: "var(--token-colors-text-plain-text-dark, #FFF)",
	fontFamily: "Maven Pro",
	fontSize: "14px",
	fontStyle: "normal",
	fontWeight: "600",
	lineWeight: "22px" 
};

/**
 * Renders a Semi bold Label component.
 *
 * @param {object} props - The properties passed to the component.
 * @param {string} props.value - The value to be displayed in the Label.
 * @param {boolean} props.isSelected - Indicates whether the Label is selected.
 * @param {string} props.textColor - The color of the Label text.
 * @returns {JSX.Element} - The rendered Semi Bold Label component.
 */
export default function SemiBoldLabel({ value, isSelected, textColor }) {
	const textColorStyle = textColor || SemiBoldLabelStyles.color;

	return (
		<>
			<p
				style={{
					...SemiBoldLabelStyles,
					opacity: isSelected ? 1 : 0.5,
					color: textColorStyle,
				}}
			>
				{value}
			</p>
		</>
	);
}
