import React from 'react'
import DetailsProjectsPageMobileTemplate from '../templates/DetailsProjectsPageMobileTemplate'

const DetailsProjectsPageMobile = () => {
    return (
        <div>
            <DetailsProjectsPageMobileTemplate />
        </div>
    )
}

export default DetailsProjectsPageMobile
