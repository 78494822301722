import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Navbar.module.css";
import { Link, useLocation } from "react-router-dom";
import checkIsMobile from "../../../utils/checkIsMobile";
import { Button, ConfigProvider, Tooltip } from "antd"; // Import Tooltip component
import Drawer from "@mui/material/Drawer";
import menuImg from "./menu_linear.svg";
import closeX from "./ic_linear_close-circle.svg";
import ArrowRight from "./ic_linear_arrow-right.svg";
import LanguageToggle from "../../../utils/translations/LanguageToggle";
import Logo from "../../atoms/Logo";
import { useNavigate } from "react-router-dom";
import { withStyles } from "@mui/styles";

const CustomTooltip = withStyles((theme) => ({
  arrow: {
    color: "white !important",
  },
  tooltip: {
    borderTop: "solid 3px white",
    backgroundColor: "#51787D !important",
    color: "white !important",
    fontSize: "14px !important",
    fontFamily: "Maven Pro !important",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px !important",
    width: "300px", // Adjust the width as needed
    padding: "16px", // Add padding for spacing
    textAlign: "left",
  },
}))(Tooltip);

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const { t } = useTranslation();
  const location = useLocation();
  const scrollToContact = () => {
    const contactSection = document.getElementById("contactSection");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToNavbar = (e) => {
    const contactSectionId = e.currentTarget.id;
    const contactSection = document.getElementById(contactSectionId);
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
      setOpen(false);
    }
  };



  const navigate = useNavigate();

  // State to track the active link
  const [activeLink, setActiveLink] = useState("");
  React.useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const scrollToAboutUs = () => {
    setOpen(false);
    console.log("Scrolling to About Us");
    const aboutUsSection = document.getElementById("about");
    if (aboutUsSection) {
      aboutUsSection.scrollIntoView({ behavior: "smooth" });
    } else {
      console.error("About Us section not found");
    }
    navigate('/#about');
  };

  return (
    <>
      {!checkIsMobile() ? (
        <nav className={styles.navbar}>
          <ul className={styles.navlist}>
            {/* Home Link */}
            <li className={activeLink === "/" ? styles.activeLink : ""}>
              <Link
                className={styles.link}
                to="/"
                onClick={() => setActiveLink("/")}
              >
                {t("home")}
              </Link>
            </li>
            {/* Services Link */}
            <li className={activeLink === "/services" ? styles.activeLink : ""}>
              <Link
                className={styles.link}
                to="/services"
                onClick={() => setActiveLink("/services")}
              >
                {t("services")}
              </Link>
            </li>
            {/* Projects and Works Link with Tooltip */}
            <li className={styles.link}>
              <div>
                <CustomTooltip
                  placement="bottom-start"
                  title={
                    <div>
                      <Link
                        className={styles.linkTooltip}
                        to="/projects-and-works/residential"
                        color="inherit"
                        underline="hover"
                      >
                        {t("Residential Projects")}
                      </Link>
                      <br />
                      <br />
                      <Link
                        className={styles.linkTooltip}
                        to="/projects-and-works/commercial"
                        color="inherit"
                        underline="hover"
                      >
                        {t("Commercial Projects")}
                      </Link>
                      <br />
                      <br />
                      <Link
                        className={styles.linkTooltip}
                        to="/projects-and-works/lsf"
                        color="inherit"
                        underline="hover"
                      >
                        {t("LSF Projects")}
                      </Link>
                      <br />
                      <br />
                      <Link
                        className={styles.linkTooltip}
                        to="/projects-and-works/remodeling"
                        color="inherit"
                        underline="hover"
                      >
                        {t("Remodeling")}
                      </Link>
                      <br />
                    </div>
                  }
                  arrow
                >
                  <span
                    className={
                      activeLink.startsWith("/projects-and-works")
                        ? styles.activeLink
                        : ""
                    }
                  >
                    <Link to="/projects-and-works" className={styles.link}>
                      {t("Projects and Works").toUpperCase()}
                    </Link>
                  </span>
                </CustomTooltip>
              </div>
            </li>
            {/* About Us Link */}
            <li className={activeLink === "/about-us" ? styles.activeLink : ""}>
              <a
                className={styles.link}
                href="#about"
                onClick={scrollToAboutUs}
              >
                {t("aboutUs")}
              </a>
            </li>
            {/* Contact Link */}
            <li className={activeLink === "/contact" ? styles.activeLink : ""}>
              <a
                className={styles.link}
                href="#contactSection"
                onClick={scrollToContact}
              >
                {t("contact")}
              </a>
            </li>
          </ul>
        </nav>
      ) : (
        <div className={styles.containerButtonMobile}>
          <Button
            type="primary"
            onClick={showDrawer}
            className={styles.buttonMobile}
            style={{ backgroundColor: "transparent" }}
          >
            <img src={menuImg} className={styles.imgLogoMobile} />
          </Button>

          <Drawer open={open} anchor="right">
            <div className={styles.navbarMobile}>
              <div className={styles.headerNavbarMobile}>
                <Logo />
                <button
                  onClick={() => {
                    onClose();
                  }}
                >
                  <img src={closeX} />
                </button>
              </div>
              <div className={styles.contentNavbarMobile}>
                <ul className={styles.navlistMobile}>
                  {/* Home Link */}
                  <li className={styles.linkMobile}>
                    <Link to="/" onClick={() => setActiveLink("#home")}>
                      {t("NavBarHome")}
                      <img src={ArrowRight} />
                    </Link>
                  </li>
                  {/* Services Link */}
                  <li className={styles.linkMobile}>
                    <Link
                      to="/services"
                      onClick={() => setActiveLink("#services")}
                    >
                      {t("NavBarServices")}
                      <img src={ArrowRight} />
                    </Link>
                  </li>
                  {/* Projects Link */}
                  <li className={styles.linkMobile}>
                    <div className={styles.dropdownMobileContainer}>
                      <div
                        className={styles.dropdownMobile}
                        onClick={toggleDropdown}
                      >
                        {t("NavBarProjects")}
                        <img
                          src={ArrowRight}
                          className={isOpen ? "" : styles.arrowRotate}
                        />
                      </div>

                      <div
                        className={`${isOpen
                          ? styles.dropdownMobileItems
                          : styles.dropdownMobileItems +
                          ` ` +
                          styles.dropdownMobileItemsActive
                          } `}
                      >
                        <a
                          id="ResidentialsArchiteture"
                          href="/#ResidentialsArchiteture"
                          color="inherit"
                          onClick={scrollToNavbar}
                        >
                          {t("textTitleCard1")}
                        </a>
                        <br></br>
                        <a
                          href="/#ResidentialsInterior"
                          id="ResidentialsInterior"
                          color="inherit"
                          onClick={scrollToNavbar}
                        >
                          {t("textTitleCard5")}
                        </a>
                        <br></br>
                        <a
                          href="/#Commercial_Projects_And_Franchises"
                          id="Commercial_Projects_And_Franchises"
                          color="inherit"
                          onClick={scrollToNavbar}
                        >
                          {t("Commercial Projects")}
                        </a>
                        <br></br>
                        <a
                          href="/#Projects_And_Constructions_In_LSF"
                          onClick={scrollToNavbar}
                          id="Projects_And_Constructions_In_LSF"
                          color="inherit"
                        >
                          {t("LSF Projects")}
                        </a>
                        <br></br>
                        <a
                          href="/#Remodeling"
                          id="Remodeling"
                          onClick={scrollToNavbar}
                          color="inherit"
                        >
                          {t("Remodeling")}
                        </a>
                      </div>
                    </div>
                  </li>
                  {/* About Us Link */}
                  <li className={styles.linkMobile}>
                    <a
                      color="inherit"
                      href="/#AboutUs"
                      onClick={scrollToAboutUs}
                    >
                      {t("aboutUs")}
                      <img src={ArrowRight} />
                    </a>
                  </li>
                </ul>
              </div>
              <span
                style={{
                  marginTop: "3rem",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <svg
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  width="220"
                  height="56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {/* Facebook Start */}
                  <a href="https://www.facebook.com/bohocasas">
                    <circle
                      opacity="0.16"
                      cx="28"
                      cy="28"
                      r="28"
                      fill="white"
                    />
                    <path
                      d="M32.209 28.5719H29.1959V37.5712H25.1783V28.5719H21.8839V24.8757H25.1783V22.0232C25.1783 18.8092 27.1067 17.0013 30.0395 17.0013C31.4457 17.0013 32.9322 17.2825 32.9322 17.2825V20.4564H31.285C29.678 20.4564 29.1959 21.4206 29.1959 22.4652V24.8757H32.7715L32.209 28.5719Z"
                      fill="white"
                    />
                  </a>
                  {/* Facebook End */}
                  {/* Instagram Start */}
                  <Link to={"https://www.instagram.com/bohocasas"}>
                    <circle
                      opacity="0.16"
                      cx="188"
                      cy="28"
                      r="28"
                      fill="white"
                    />
                    <path
                      d="M188.141 22.666C190.672 22.666 192.762 24.7552 192.762 27.2863C192.762 29.8575 190.672 31.9065 188.141 31.9065C185.57 31.9065 183.521 29.8575 183.521 27.2863C183.521 24.7552 185.57 22.666 188.141 22.666ZM188.141 30.2994C189.789 30.2994 191.114 28.9736 191.114 27.2863C191.114 25.639 189.789 24.3132 188.141 24.3132C186.454 24.3132 185.128 25.639 185.128 27.2863C185.128 28.9736 186.494 30.2994 188.141 30.2994ZM194.007 22.5053C194.007 23.108 193.525 23.5901 192.922 23.5901C192.32 23.5901 191.837 23.108 191.837 22.5053C191.837 21.9027 192.32 21.4206 192.922 21.4206C193.525 21.4206 194.007 21.9027 194.007 22.5053ZM197.06 23.5901C197.141 25.0766 197.141 29.5361 197.06 31.0226C196.98 32.4689 196.659 33.7144 195.614 34.7991C194.569 35.8437 193.284 36.1651 191.837 36.2454C190.351 36.3258 185.891 36.3258 184.405 36.2454C182.959 36.1651 181.713 35.8437 180.628 34.7991C179.584 33.7144 179.262 32.4689 179.182 31.0226C179.102 29.5361 179.102 25.0766 179.182 23.5901C179.262 22.1438 179.584 20.8581 180.628 19.8136C181.713 18.769 182.959 18.4476 184.405 18.3672C185.891 18.2869 190.351 18.2869 191.837 18.3672C193.284 18.4476 194.569 18.769 195.614 19.8136C196.659 20.8581 196.98 22.1438 197.06 23.5901ZM195.132 32.5895C195.614 31.4244 195.493 28.6121 195.493 27.2863C195.493 26.0006 195.614 23.1883 195.132 21.9831C194.81 21.2197 194.208 20.5769 193.444 20.2957C192.239 19.8136 189.427 19.9341 188.141 19.9341C186.815 19.9341 184.003 19.8136 182.838 20.2957C182.035 20.6171 181.432 21.2197 181.111 21.9831C180.628 23.1883 180.749 26.0006 180.749 27.2863C180.749 28.6121 180.628 31.4244 181.111 32.5895C181.432 33.393 182.035 33.9956 182.838 34.317C184.003 34.7991 186.815 34.6786 188.141 34.6786C189.427 34.6786 192.239 34.7991 193.444 34.317C194.208 33.9956 194.851 33.393 195.132 32.5895Z"
                      fill="white"
                    />
                  </Link>
                  {/* Instagram End */}
                </svg>
              </span>
              <div
                style={{
                  margin: "1rem",
                  alignSelf: "start",
                }}
              >
                <LanguageToggle />
              </div>
              <div
                style={{
                  background: "#51787D",
                  width: "100vw",
                  height: "100vh",
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "2rem",
                }}
              >
                <span
                  style={{
                    color: "white",
                    fontSize: "16px",
                    margin: "auto",
                    fontWeight: "700",
                  }}
                >
                  <div>
                    <span>{t("Copyright")}</span>
                  </div>
                </span>
              </div>
            </div>
          </Drawer>
        </div>
      )}
    </>
  );
};

export default Navbar;
