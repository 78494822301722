import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import { ProjectService } from "boho-casas-client-module/client-web";
import { useParams } from "react-router-dom";
import "./SliderModelWebProjects.css";
import { AutoPlay } from "@egjs/flicking-plugins";

function SliderModelWebProjects() {
    const { t } = useTranslation();
    const plugins = [new AutoPlay({ duration: 2000, direction: "NEXT", stopOnHover: false })];
    const [projects, setProjects] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        ProjectService.returnAllProject().then((response) => {
            var filteredData = response.data.filter(item => item._id.$oid === id);
            setProjects(filteredData[0]);
        });
    }, []);

    const images = projects.images || [];

    return (
        <div className="slider-container" style={{position: "relative"}}>
            <Flicking
                align="center"
                autoResize
                style={{ width: "100%", height: "80vh", margin: "auto", display: "flex" , paddingTop: "2rem"}}
                circular={true}
                interruptable={true}
                plugins={plugins}
            >
                {images.map((item, index) => (
                    <img
                        src={item}
                        alt="Imagem"
                        key={index}
                        style={{ width: "75%", height: "100%", objectFit: "cover", paddingLeft: "2rem"}}
                    />
                ))}
            </Flicking>
        </div>
    );
}

export default SliderModelWebProjects;
