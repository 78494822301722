import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Navbar.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Tooltip, ConfigProvider } from "antd";

const NavBarWeb = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const scrollToContact = () => {
    const contactSection = document.getElementById("contactSection");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToNavbar = (e) => {
    const contactSectionId = e.currentTarget.id;
    const contactSection = document.getElementById(contactSectionId);
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToAboutUs = () => {
    const contactSection = document.getElementById("AboutUs");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  // State to track the active link
  const [activeLink, setActiveLink] = useState("");

  // Atualiza a classe ativa no momento em que a localização muda
  React.useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  /**
   * Renders the navigation bar with links.
   *
   * @returns {JSX.Element} The rendered navigation bar.
   */
  return (
    <>
      <nav className={styles.NavBarWeb}>
        <ul className={styles.navlist}>
          {/* Home Link */}
          <li className={activeLink === "/" ? styles.activeLink : ""}>
            <Link
              className={styles.link}
              to="/"
              onClick={() => setActiveLink("/")}
            >
              {t("home")}
            </Link>
          </li>
          {/* Services Link */}
          <li className={activeLink === "/services" ? styles.activeLink : ""}>
            <Link
              className={styles.link}
              to="/services"
              onClick={() => setActiveLink("/services")}
            >
              {t("services")}
            </Link>
          </li>
          {/* Projects and Works Link with Tooltip */}
          <li className={styles.link}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",

              }}
            >
              <ConfigProvider
                theme={{
                  token: {
                    colorBgSpotlight: "#51787d",
                    colorLinkHover: "white",

                  },
                }}
              >
                <Tooltip
                  align="center"
                  placement="bottom"
                  arrow
                  overlayInnerStyle={{
                    minWidth: 300
                  }}
                  showArrowPointAtLeft
                  arrowContent={true}
                  title={
                    <div
                      style={{
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                        paddingTop: "20px",
                      }}
                    >
                      <a
                        className={styles.linkTooltip}
                        id="ResidentialsArchiteture"
                        href="/#ResidentialsArchiteture"
                        color="inherit"
                        onClick={scrollToNavbar}
                      >
                        {t("textTitleCard1")}
                      </a>
                      <br />
                      <br />
                      <a
                        className={styles.linkTooltip}
                        href="/#ResidentialsInterior"
                        id="ResidentialsInterior"
                        color="inherit"
                        onClick={scrollToNavbar}
                      >
                        {t("textTitleCard5")}
                      </a>
                      <br />
                      <br />
                      <a
                        className={styles.linkTooltip}
                        href="/#Commercial_Projects_And_Franchises"
                        id="Commercial_Projects_And_Franchises"
                        color="inherit"
                        onClick={scrollToNavbar}
                        style={{ cursor: "pointer" }}
                      >
                        {t("Commercial Projects")}
                      </a>
                      <br />
                      <br />
                      <a
                        className={styles.linkTooltip}
                        href="/#Projects_And_Constructions_In_LSF"
                        onClick={scrollToNavbar}
                        id="Projects_And_Constructions_In_LSF"
                        color="inherit"
                      >
                        {t("LSF Projects")}
                      </a>
                      <br />
                      <br />
                      <a
                        className={styles.linkTooltip}
                        href="/#Remodeling"
                        id="Remodeling"
                        onClick={scrollToNavbar}
                        color="inherit"
                      >
                        {t("Remodeling")}
                      </a>
                      <br />
                    </div>
                  }
                >
                  <Link
                    to="/"
                    className={styles.link}
                  >
                    {t("Projects and Works").toUpperCase()}
                  </Link>
                </Tooltip>
              </ConfigProvider>
            </div>
          </li>
          {/* About Us Link */}
          <li className={activeLink === "/about-us" ? styles.activeLink : ""}>
            <a
              className={styles.link}
              href="/#AboutUs"
              onClick={scrollToAboutUs}
            >
              {t("aboutUs")}
            </a>
          </li>
          {/* Contact Link */}
          <li className={activeLink === "/contact" ? styles.activeLink : ""}>
            <a
              className={styles.link}
              href="#contactSection"
              onClick={scrollToContact}
            >
              {t("contact")}
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default NavBarWeb;
