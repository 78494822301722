import Spline from "@splinetool/react-spline";
import { useEffect } from "react";

export default function LogoBoho({ setLoading }) {
  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000);
  }, [])
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <Spline
        scene="https://prod.spline.design/wwsZK8gnLQAmbQXc/scene.splinecode"
        onLoad={(laod) => {
          setLoading(laod.disposed)
        }}
      />
    </div>
  );
}
