import React from 'react'
import styles from './Text_Regular_32.module.css'

export default function Text_Regular_32({
    value,
    isSelected,
    textColor,
    textAlign,
    FontSize,
}) {
    return (
        <>
            <p
                className={styles.text32}
                style={{
                    opacity: isSelected ? 1 : .5,
                    color: textColor ? textColor : 'white',
                    textAlign: textAlign ? textAlign:"center",
                    FontSize: FontSize ? FontSize: 20
                }}>
                {value}
            </p>
        </>

    )
}