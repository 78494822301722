import React from 'react'
import ProjectsLSFPageMobileTemplate from '../templates/ProjectsLSFPageMobileTemplate'


export default function ProjectsLSFPageMobile() {
    return (
        <div>
            <ProjectsLSFPageMobileTemplate />
        </div>
    )
}
