import React from "react";
import HeroSectionServices from "../../components/organisms/HeroSectionServices";
import SectionContactUs from "../../components/organisms/SectionContactUs";
import GoogleMap from "../../components/organisms/MapContainer";
import SectionDifferentialsCommercialProjectsAndFranchises from "../../components/organisms/SectionDifferentialsCommercialProjectsAndFranchises";
import SectionDifferentialsRemodeling from "../../components/organisms/SectionDifferentialsRemodeling";
import FooterWeb from "../../components/organisms/FooterWeb/FooterWeb";
import ProjectsResidentialArchitectureSpecialties from "../../components/organisms/ProjectsResidentialArchitectureSpecialties/";
import ProjectsResidentialInterior from "../../components/organisms/ProjectsResidentialInterior";
import SectionDifferentialsLSF from "../../components/organisms/SectionDifferentialsLSF";
import Section_Contact_Us from "../../components/organisms/SectionContactUs";

export default function ServicesTemplate() {
	return (
		<div style={{ overflow: "hidden" }}>
			<HeroSectionServices />
			<ProjectsResidentialArchitectureSpecialties />
			<ProjectsResidentialInterior />
			<SectionDifferentialsCommercialProjectsAndFranchises />
			<SectionDifferentialsLSF />
			<SectionDifferentialsRemodeling />
			<Section_Contact_Us />
			<div style={{ height: "627px", width: "100vw" }}>
				<GoogleMap />
			</div>
			<FooterWeb />
		</div>
	);
}
